import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Corporatebox from "./Corporatebox";
import Corporateinfos from "./Corporateinfos";
import CorporateEthics from "./CorporateEthics";

function Corporateinfobanner() {
  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner companydetail">
            <Fade up delay={500}>
              <h2 className="oswald">Company Details</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text govdirectors companydetail neww">
            <Corporatebox />
          </div>

          <div className="col-sm-12 heads text">
            <Corporateinfos />
          </div>

          <div className="col-sm-12 heads text ethichs">
            <CorporateEthics />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Corporateinfobanner;
