import { Form, Input, Radio } from 'antd'
import React from 'react'

const EVerifyMarineForm = ({onChangeMarine, valueMarine}) => {
    return (
        <div className="row">
            <div className="col-sm-12 labelSSS abcd">
                <Radio.Group onChange={onChangeMarine} value={valueMarine}>
                    <Radio value='policy'>Policy</Radio>
                    <Radio value='certificate'>Certificate</Radio>
                    <Radio value='coverNote'>Cover Note</Radio>
                </Radio.Group>
            </div>
            <div className="col-sm-6 labelSSS" >
                <Form.Item
                    name='Policy'
                    label="Policy #"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter Policy Number!"
                        }
                    ]}
                >
                    <Input placeholder='Policy #' />
                </Form.Item>

            </div>
            <div className="col-sm-6 labelSSS">
                <Form.Item
                    name='Invoice'
                    label="Invoice #"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter Invoice Number!"
                        }
                    ]}
                >
                    <Input placeholder='Invoice #' />
                </Form.Item>
            </div>

        </div>
    )
}

export default EVerifyMarineForm