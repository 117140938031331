import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Encahsed from './Encahsed';

function Encahsedbanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>
                    <div className='col-sm-12 heads govbanner encash'>
                        <Fade up delay={500}><h2 className="oswald">Un-encashed Benefits/Cheque</h2></Fade>
                    </div>
                    <div className='col-sm-12 heads text mymaxheight'>
                        <Encahsed/>
                    </div>                
                </div>
            </div>
        </Fragment>
    )
}

export default Encahsedbanner