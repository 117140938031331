import React, { useState } from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { branches } from "../../Data/Data";


function ContactMap() {

  return (
    <Fragment>
      <div className="row noPad">
        <div className="col-sm-12 heads text govdirectors">
          <h2 style={{textAlign: "center"}}>Head Office</h2> 
        </div>
      </div>

      <div className="row branchLocation noPad headOfficeee">
          <div className="col-sm-12">
            <p>
              
            <a
              href="https://www.google.com/maps/place/6th+Floor,+Business+Centre,+Plot+No.+19-1-A,+Block+-6,+P.E.C.H.S.,+Shahrah-e-Faisal,+Karachi,+Pakistan"
              target="_blank"
              rel="noopener noreferrer"
            >6th Floor, Business Centre, Plot No. 19-1-A, Block -6, P.E.C.H.S., Shahrah-e-Faisal, Karachi, Pakistan.</a>
            </p>
            <p>
              UAN: <a href="tel:+92-21-111-875-111">+92-21-111-875-111</a> | 
              PABX: <a href="tel:+92-21-34373171-80">+92-21-34373171-80</a>

            </p>
            <p>Email: <a href="mailto:info@salaaamtakaful.com">info@salaaamtakaful.com</a></p>
            {/* <p>Website: <a href="https://www.salaamtakaful.com" target="_blank" rel="noopener noreferrer">https://www.salaamtakaful.com</a></p> */}
          </div>
      </div>

      <div className="row noPad">
        <div className="col-sm-12 heads text ">
          <h2 style={{textAlign: "center"}}>Customer Care Centers</h2> 
        </div>
      </div>

      <div className="row branchLocation noPad">
        {branches.map((branch, index) => (
          <div key={index} className={branch.city === "Head Office" ? "col-sm-12" : "col-sm-6"}>
            <h3>{branch.city}</h3>
            <a
              href={branch.contact.mapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {branch.address}
            </a>
            <p>
              {branch.contact.UAN && <>UAN: <a href={`tel:${branch.contact.UAN}`}>{branch.contact.UAN}</a> | </>}
              {branch.contact.PABX && <>PABX: <a href={`tel:${branch.contact.PABX}`}>{branch.contact.PABX}</a></>}
              {branch.contact.tel && branch.contact.tel.map((tel, idx) => (
                <span key={idx}>Tel: <a href={`tel:${tel}`}>{tel}</a>{idx < branch.contact.tel.length - 1 ? ", " : ""}</span>
              ))}
            </p>
            {branch.contact.fax && (
              <p>Fax: <a href={`tel:${branch.contact.fax}`}>{branch.contact.fax}</a></p>
            )}
            {branch.contact.email && (
              <p>Email: <a href={`mailto:${branch.contact.email}`}>{branch.contact.email}</a></p>
            )}
            {branch.contact.website && (
              <p>Website: <a href={branch.contact.website} target="_blank" rel="noopener noreferrer">{branch.contact.website}</a></p>
            )}
          </div>
        ))}
      </div>
      <div className="sub complainforms maaapppppp">
        <div className="row">
          <div className="col-sm-12">
            <Fade up delay={300}>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7057642.057073738!2d63.7079444670803!3d30.262324755098565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38db52d2f8fd751f%3A0x46b7a1f7e614925c!2sPakistan!5e0!3m2!1sen!2s!4v1692633023279!5m2!1sen!2s"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
              <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/Map.jpg`} />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactMap;
