import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";


function WhyUsIndeminty() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>
      <Fade up delay={500}>
        <div className='row claimprocedure'>
          <div className='col-sm-12'>
            <Fade left delay={700}><h2>Claim Procedure</h2></Fade>
            <ul>
              <Fade up delay={800}><li><img src={Check} alt='Check' /> Lawyers</li></Fade>
              <Fade up delay={900}> <li><img src={Check} alt='Check' />   Architects</li></Fade>
              <Fade up delay={1000}> <li><img src={Check} alt='Check' /> Engineers</li></Fade>
              <Fade up delay={1100}><li><img src={Check} alt='Check' />  Doctors</li></Fade>
              <Fade up delay={1200}><li><img src={Check} alt='Check' /> Surveyors </li></Fade>
              <Fade up delay={1300}>  <li><img src={Check} alt='Check' /> Consultants</li></Fade>
              <Fade up delay={1500}>  <li><img src={Check} alt='Check' /> Accountants</li></Fade>
              <Fade up delay={1800}>  <li><img src={Check} alt='Check' /> Information Technology / Software Professionals / Consultants / Firms</li></Fade>
            </ul>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default WhyUsIndeminty