import React from "react";
import { Fragment } from "react";


function ReTakaful() {
  const Retakafuls = `${process.env.REACT_APP_URL_ASSETS}Assets/img/retakaful.png`
  const Checkwhite = `${process.env.REACT_APP_URL_ASSETS}Assets/img/Vector.png`
  return (
    <Fragment>
      <div className="container-fluid shares retak retakful">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 ">
              <h2 data-aos="fade-right" data-aos-duration="4000">
                Our Re-Takaful Operators
              </h2>
              <ul data-aos="fade-right" data-aos-duration="4000">
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" /> Labuan Re - A- A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Malaysian Re - A- A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Singapore Re - A A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Oman Re - BBB- S&P
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Saudi Re - A- S&P
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Kenya Re - B A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Tunis Re - B A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Misr Insurance - B++ A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Arab Re - B A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  Hannover Re - A+ A.M Best
                </li>
                <li data-aos="fade-right" data-aos-duration="4000">
                  <img src={Checkwhite} alt="asd" />
                  PRCL - AA+ JCR VIS
                </li>
              </ul>
            </div>

            <div className="col-sm-6 imageshare">
              <img
                src={Retakafuls}
                alt="shareholding"
                data-aos="fade-up"
                data-aos-duration="4000"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ReTakaful;
