import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function SalaamFireTab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamMarine.jpg`} />
                        <h4>Salaam Marine Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamMarineCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>
                                    Cargo interests are exposed to numerous financial risks in the course of their business of imports, exports as well as movement of goods within 
                                    the country. The transportation of cargo is invariably carried in conjunction with coverage as no one can afford to take the risk of leaving their 
                                    cargo uncovered. Our Salaam Marine Takaful cover protects your goods against the various risks involved during transit, for all types of cargo 
                                    transported by the various modes of conveyance such as sea, air, land and parcel post from warehouse to warehouse.
                                </p>
                            </div>

                            <div className='col-sm-4 fireCoverage'>
                                <h3>Voyage Basis</h3>
                                <p>This policy is valid for a single voyage or transit. The policy is issued before the voyage starts. The coverage will cease immediately upon completion of the voyage.</p>
                            </div>

                            <div className='col-sm-4 fireCoverage'>
                                <h3>Annual Basis</h3>
                                <p>
                                    In case of frequent shipments, it will be cumbersome, if not altogether difficult, to need issuance of a Salaam Marine Takaful policy every time. 
                                    To solve this problem, a single policy is issued to cover all the shipments during the year. Each shipment affected during the policy period is 
                                    endorsed therein by means of a declaration of shipment. The policy is normally issued for a 12-month’ period. The coverage of the policy is 
                                    based on the type of cargo. We provide Takaful coverage for all kinds of commodities including, but not limited to, steel products, bulk oil 
                                    cargoes, containerized products, etc. This policy covers various types of cargo transported by sea, air as well as by land conveyance. 
                                    The extent of cover varies from a limited TLO to an all-risk comprehensive cover in accordance with the internationally recognized ‘Institute 
                                    Cargo Clause ‘A’, ‘B’ or ‘C’.
                                </p>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default SalaamFireTab