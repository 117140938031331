import React, { Fragment } from "react";
import HospitalBanner from "../../Components/Hospitalform/HospitalBanner";
import { Helmet } from "react-helmet";

function HospitalForm() {
  const metaData = {
    title: 'Panel Hospitals | List of Partner Hospitals ',
    description: 'View our list of panel hospitals where you can access medical services with Salaam Takaful coverage. Our network includes reputable hospitals offering quality care'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <HospitalBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default HospitalForm;