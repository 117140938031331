import React, { Fragment } from "react";
import DisBanner from "../../Components/Discount/DisBanner";
import { Helmet } from "react-helmet";

function Discount() {
  const metaData = {
    title: 'Discount Center | Exclusive Discounts and Offers ',
    description: 'Check out our Discount Center for exclusive offers and discounts on Salaam Takaful products and services. Take advantage of special promotions to get the best value'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <DisBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Discount;
