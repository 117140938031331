import React, { Fragment } from 'react';
import { Link, useLocation } from "react-router-dom";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import 'swiper/css/pagination';
import { corporates } from "../../Data/Data";

function CorProdNav() {
  const activeLink = useLocation().pathname;

  const activeIndex = corporates.findIndex(data => data.link === activeLink);

  return (
    <Fragment>
      <div className="container productnav">
        <div className="row">
          <Swiper
            slidesPerView={5} // Display only one slide on mobile
            spaceBetween={30}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            initialSlide={activeIndex} // Set initial slide to the active tab
            breakpoints={{ "@0.00": { slidesPerView: 1, spaceBetween: 10, }, "@0.75": { slidesPerView: 2, spaceBetween: 30, }, "@1.00": { slidesPerView: 5, spaceBetween: 30, }, "@1.50": { slidesPerView: 5, spaceBetween: 30, }, }}
            className="mySwiper"
          >
         
            {corporates.map((data, index) => (
              <SwiperSlide key={data.key}>
                <div className="col-sm-2">
                  <div>
                    <Flip top delay={800}>
                      <Link
                        to={data.link}
                        className={
                          index === activeIndex ? "Active" : ""
                        }
                      >
                        {data.name}
                      </Link>
                    </Flip>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
}

export default CorProdNav;