import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postHomeFormTwoSlice = createSlice({
  name: "postHomeFormTwo",
  initialState,
  reducers: {
    postHomeFormTwoStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHomeFormTwoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postHomeTwoDataSuccess(state, action) {
      state.postData = action.payload;
    },
    postHomeFormTwoFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHomeFormTwoStart,
  postHomeFormTwoSuccess,
  postHomeFormTwoFailure,
  postHomeTwoDataSuccess,
  clearError,
  clearMessage,
} = postHomeFormTwoSlice.actions;

export default postHomeFormTwoSlice.reducer;
