import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMotorBillingInfoStart,
  getMotorBillingInfoSuccess,
  getMotorBillingInfoFailure,
} from "../reducers/getMotorBillingInfoReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getMotorBillingInfo = createAsyncThunk(
  "getMotorBillingInfo",
  async ({ Unique_ID }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMotorBillingInfoStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Motor/GetMotorBillingInfo?Unique_ID=${Unique_ID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getMotorBillingInfoSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getMotorBillingInfoFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
