import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postFormTwoStart,
  postFormTwoSuccess,
  postFormTwoFailure,
} from "../reducers/postFormTwoReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const postFormTwo = createAsyncThunk(
  "postFormTwo",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postFormTwoStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Motor/SaveMotorPolicySecondPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postFormTwoSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postFormTwoFailure(error.response.data.Message));

      throw error;
    }
  }
);
