import React, { Fragment } from "react";
import Corporateinfobanner from "../Components/CorporateInformation/Corporateinfobanner";
import { Helmet } from "react-helmet";

function CorporateInformation() {
  const metaData = {
    title: 'Company Details | Salaam Takaful Overview',
    description: 'Find detailed information about Salaam Takaful'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Corporateinfobanner />
        </div>
      </div>
    </Fragment>
  );
}

export default CorporateInformation;
