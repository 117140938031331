import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getHealthBillingInfoStart,
  getHealthBillingInfoSuccess,
  getHealthBillingInfoFailure,
} from "../reducers/getHealthBillingInfoReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getHealthBillingInfo = createAsyncThunk(
  "getHealthBillingInfo",
  async ({ Unique_ID }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getHealthBillingInfoStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Health/GetBillingInfo?Unique_ID=${Unique_ID}&PaymentType=COD`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(
        getHealthBillingInfoSuccess(responseData?.data?.result)
      );

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getHealthBillingInfoFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
