import React, { Fragment } from 'react'
import SalamBankers from '../../Components/FinancialProductList/SalamBanker'
import { Helmet } from 'react-helmet'

function SalamBanker() {
    const metaData = {
        title: 'Salaam Bankers Blanket Takaful | Islamic Insurance for Financial Institutions',
        description: 'Protect your financial institution with Salaam Bankers Blanket Takaful. Our Shariah-compliant insurance covers a wide range of risks, including theft, fraud, and employee dishonesty'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamBankers />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamBanker