import React from 'react'
import { Fragment } from 'react'

function Complaintext() {
    return (
        <Fragment>
            <div className='sub shariahcom'>

                <p data-aos="fade-right" data-aos-duration="4000">Salaam Takaful Limited is fully committed to providing a High Class experience to its Participants.
                    However, we need your support to further improve our services. If you come across any problem or query, have a complaint or suggestion; please inform us through any of the following contact channels:
                </p>


            </div>
        </Fragment>
    )
}

export default Complaintext