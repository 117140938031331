import React, { Fragment } from 'react'
import SalamLivestocks from '../../Components/IndividualProductList/SalamLivestock'
import { Helmet } from 'react-helmet'

function SalamLivestock() {
    const metaData = {
        title: 'Salaam Livestock Takaful | Islamic Insurance for Livestock Protection',
        description: 'Secure your livestock with Salaam Livestock Takaful. Our Shariah-compliant insurance provides coverage for health issues, accidents, and other risks, supporting the sustainability of your farming business'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamLivestocks />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamLivestock