import {React, Fragment} from 'react'
import Buynow from '../Components/Buynow/Buynow'

function BuyNow() {
  return (
    <Fragment>

    
    <div className='container-fluid faderight'>
      <div className='row'>
          <Buynow/> 
      </div>
      </div>
  
</Fragment>
  )
}

export default BuyNow