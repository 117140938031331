import { Button, Form, Input, Radio } from 'antd'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

const ClaimIntimationForm = () => {
    const [value, setValue] = useState('PersonalAccident');
    const [valueMarine, setValueMarine] = useState('policy');
    const [form] = Form.useForm();
    const onFinish = (values) => {
        if (value == 'marine') {
            values.valueMarine = valueMarine;
        }
        console.log(values);
        toast.success("Form Submitted Succesfully!");
        form.resetFields();
    }

    const onChangeMarine = (e) => {
        console.log('radio checked', e.target.value);
        setValueMarine(e.target.value);
    };

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    return (
        <div className="container-fluid motorFomrOne">
            <div className="row formTakafulll"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/TravelFormOne.jpg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="col-sm-8 mForm"
                    style={{
                        backgroundColor: "rgb(18 133 98 / 69%)",
                    }}
                >
                    <div className="centreWidthFOrm">
                        <h3 className="cnhbsh3">Claim Intimation</h3>

                        <div className="row">
                            <div className="col-sm-12 labelSSS abcd">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value='PersonalAccident'>Personal Accident</Radio>
                                    <Radio value='Home'>Home</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        {value == 'PersonalAccident' && (
                            <h5 className="cnhbsh5">Personal Accident</h5>
                        )}
                        {value == 'Home' && (
                            <h5 className="cnhbsh5">House Hold</h5>
                        )}
                        <Form onFinish={onFinish} layout='vertical' autoComplete='off' form={form}>
                            <div className="row">
                                

                                <div className="col-sm-6 labelSSS">
                                    <Form.Item
                                        name='Policy'
                                        label="Policy #"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Policy Number!"
                                            }
                                        ]}
                                    >
                                        <Input placeholder='Policy #' />
                                    </Form.Item>

                                </div>
                                <div className="col-sm-6 labelSSS">
                                    <Form.Item
                                        name="CNIC"
                                        label='CNIC Number'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                                                message:
                                                    "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                                            },
                                        ]}
                                        normalize={(value) => {
                                            if (!value) {
                                                return value;
                                            }

                                            const onlyNums = value.replace(/[^\d]/g, "");
                                            if (onlyNums.length <= 5) {
                                                return onlyNums.slice(0, 5);
                                            }
                                            if (onlyNums.length <= 12) {
                                                return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                                            }
                                            return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                                                5,
                                                12
                                            )}-${onlyNums.slice(12, 13)}`;
                                        }}
                                    >
                                        <Input placeholder="CNIC #" />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ background: "#1C75BC" }}
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="col-sm-4 mForm"></div>
            </div>
        </div>
    )
}

export default ClaimIntimationForm