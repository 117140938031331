import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function ComputerTabs() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row singleprod">
            <Fade down delay={500}>
              <div className="col-sm-3 imgeboxes">
                <img
                  src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/ComputerCrimeTakaful.jpg`}
                />
                <h4>Computer Crime Takaful</h4>
              </div>
            </Fade>

            <Fade up delay={500}>
              <div
                className="col-sm-12 mylist"
                style={{
                  backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/ComputerCrimeTakafulCover.jpg`})`,
                }}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <p>
                      <b>
                        This policy provides coverage for banks against computer
                        related crimes which include
                      </b>{" "}
                      <br /> Fraudulently Altered Electronic Data, Fraudulently
                      Altered Electronic Instructions & Fraudulent Electronic
                      Transfers
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fragment>
    );
}

export default ComputerTabs