import { Button, Form, Radio } from 'antd'
import React, { useState } from 'react'
import EVerifyMotorForm from './EVerifyMotorForm'
import EVerifyHealthForm from './EVerifyHealthForm'
import EVerifyTravelForm from './EVerifyTravelForm'
import EVerifyMarineForm from './EVerifyMarineForm'
import EVerifyFireForm from './EVerifyFireForm'
import { toast } from 'react-toastify'

const EVerifyForm = () => {
    const [value, setValue] = useState('motor');
    const [valueMarine, setValueMarine] = useState('policy');
    const [form] = Form.useForm();
    const onFinish = (values) => {
        if (value == 'marine') {
            values.valueMarine = valueMarine;
        }
        console.log(values);
        toast.success("Form Submitted Succesfully!");
        form.resetFields();
    }

    const onChangeMarine = (e) => {
        console.log('radio checked', e.target.value);
        setValueMarine(e.target.value);
    };

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    return (
        <div className="container-fluid motorFomrOne">
            <div className="row formTakafulll"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/TravelFormOne.jpg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="col-sm-8 mForm"
                    style={{
                        backgroundColor: "rgb(18 133 98 / 69%)",
                    }}
                >
                    <div className="centreWidthFOrm">
                        <h3 className="cnhbsh3">Please Verify Your Policy</h3>
                        <Form onFinish={onFinish} layout='vertical' autoComplete='off' form={form}>
                            <div className="row">
                                <div className="col-sm-12 labelSSS abcd">
                                    <Radio.Group onChange={onChange} value={value}>
                                        <Radio value='motor'>Motor</Radio>
                                        <Radio value='health'>Health</Radio>
                                        <Radio value='travel'>Travel</Radio>
                                        <Radio value='accident'>Accident</Radio>
                                        <Radio value='marine'>Marine</Radio>
                                        <Radio value='miscellenous'>Miscellenous</Radio>
                                        <Radio value='fire'>Fire</Radio>
                                        <Radio value='jobLoss'>Job Loss</Radio>
                                    </Radio.Group>
                                </div>

                                <div className="col-sm-12">

                                    {value == 'motor' && (
                                        <EVerifyMotorForm />
                                    )}

                                    {value == 'health' && (
                                        <EVerifyHealthForm />
                                    )}

                                    {value == 'marine' && (
                                        <EVerifyMarineForm valueMarine={valueMarine} onChangeMarine={onChangeMarine} />
                                    )}

                                    {(value == 'miscellenous' || value == 'fire') && <EVerifyFireForm />}

                                    {(value == 'travel' || value == 'accident' || value == 'jobLoss') && <EVerifyTravelForm />}

                                </div>

                                <div className="col-sm-12">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ background: "#1C75BC" }}
                                        >
                                            Verify
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="col-sm-4 mForm"></div>
            </div>
        </div>
    )
}

export default EVerifyForm