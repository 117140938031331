import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postHealthTwoSlice = createSlice({
  name: "postHealthTwo",
  initialState,
  reducers: {
    postHealthTwoStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHealthTwoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
      console.log(action.payload, "<=====action.payload");
    },
    postHealthTwoFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHealthTwoStart,
  postHealthTwoSuccess,
  postHealthTwoFailure,
  clearError,
  clearMessage,
} = postHealthTwoSlice.actions;

export default postHealthTwoSlice.reducer;
