import React, { Fragment } from 'react'
import SalamMotors from '../../Components/IndividualProductList/SalamMotor'
import { Helmet } from 'react-helmet'

function SalamMotor() {
    const metaData = {
        title: 'Salaam Motor Takaful | Comprehensive Islamic Car Insurance Solutions',
        description: 'Protect your vehicle with Salaam Motor Takaful. Enjoy comprehensive coverage for your car against accidents, theft, and damage with Shariah-compliant insurance solutions.'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight motors'>
                <div className='row arrticleAndBlogs'>
                    <SalamMotors />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamMotor