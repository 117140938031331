import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getTravelProductSlice = createSlice({
  name: "getTravelProduct",
  initialState,
  reducers: {
    getTravelProductStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getTravelProductSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getTravelProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getTravelProductStart,
  getTravelProductSuccess,
  getTravelProductFailure,
  clearError,
  clearMessage,
} = getTravelProductSlice.actions;

export default getTravelProductSlice.reducer;
