import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/navigation";

function Productmenus() {
  const Motor = `${process.env.REACT_APP_URL_ASSETS}Assets/img/motortakaful.png`
  const Home = `${process.env.REACT_APP_URL_ASSETS}Assets/img/hometakaful.png`
  const Health = `${process.env.REACT_APP_URL_ASSETS}Assets/img/healthtakaful.png`
  return (
    <Fragment>
      <div className="container productmenu homemenu">
        <div className="row">
          <div className="col-sm-12 explore">
            <h2>
              <Flip top delay={800}>
                Diversified Range of
              </Flip>{" "}
              <Flip top delay={1300}>
                {" "}
                Product Offering{" "}
              </Flip>
            </h2>
          </div>

          <div className="col-sm-9 productslides ">
            <Fade right delay={500}>
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                navigation={{ clickable: true }}
                modules={[Navigation]}
                breakpoints={{
                  "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                  "@0.75": { slidesPerView: 2, spaceBetween: 30 },
                  "@1.00": { slidesPerView: 4, spaceBetween: 30 },
                  "@1.50": { slidesPerView: 4, spaceBetween: 30 },
                }}
                className="mySwiper homePageProductSwiper"
              >
                <SwiperSlide>
                  <Link
                    to="/individualproduct/salammotor"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/motortakaful.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>Salaam Motor Takaful</h3>
                        <p className="excerpt">
                          Provides Shariah-compliant coverage for vehicles,
                          protecting against risks like accidents and theft.
                        </p>
                        {/* <p className="categories">3 categories</p> */}
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to="/individualproduct/salamhome"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/icon/SalaamHomeTakafulIcon.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>Salaam Home Takaful</h3>
                        <p className="excerpt">
                          Delivers Shariah-compliant protection for residential
                          properties, safeguarding against damage and loss due
                          to various risks.
                        </p>
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/individualproduct/salamsehat"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/icon/SalaamSehatTakafulIcon.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>Salaam Sehat Takaful</h3>
                        <p className="excerpt">
                          Offers comprehensive Shariah-compliant health
                          insurance for individuals and families, covering
                          medical expenses and treatments.
                        </p>
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/individualproduct/salamtravel"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/icon/SalaamTravelTakafulIcon.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>Salaam Travel Takaful</h3>
                        <p className="excerpt">
                          Provides Shariah-compliant insurance for travellers,
                          covering emergencies, trip cancellations, and other
                          travel-related risks.
                        </p>
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/corporateproduct/salamcrop"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/icon/SalaamCropTakafulIcon.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>Salaam Crop Takaful</h3>
                        <p className="excerpt">
                          Offers Shariah-compliant coverage for agricultural
                          risks, protecting crops from damage due to natural
                          disasters and other weather-based perils.
                        </p>
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/product"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="productsslides">
                      <Flip top delay={500}>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_URL_ASSETS}Assets/icon/ViewAllIcon.png`}
                          alt="asd"
                        />
                      </Flip>
                      <Fade up delay={500}>
                        <h3>View All</h3>
                        <p className="excerpt">
                          Explore our full range of Shariah-compliant Takaful
                          products designed to meet diverse Islamic insurance
                          needs.
                        </p>
                      </Fade>
                    </div>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </Fade>
          </div>

          <div className="col-sm-5 view">
            <Flip top delay={800}>
              {" "}
              <Link
                to="/product"
                className="buy "
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                View All Products
              </Link>
            </Flip>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Productmenus;
