import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCalculateTrackerStart,
  getCalculateTrackerSuccess,
  getCalculateTrackerFailure,
} from "../reducers/getCalculateTrackerReducer.js";
import axios from "axios";
import Cookies from "js-cookie";

export const getCalculateTracker = createAsyncThunk(
  "getCalculateTracker",
  async ({ carMake, calYear, calValue, trackerRequired }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCalculateTrackerStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Motor/CalculateTracker?Make_Code=${carMake}&Cal_Year=${calYear}&Cal_Value=${calValue}&Tracker=${trackerRequired}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getCalculateTrackerSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=======responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getCalculateTrackerFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
