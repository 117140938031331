import React from 'react'
import { Fragment } from 'react'
import AnnualBanner from '../Components/Annual/AnnualBanner'



function Annual() {
    return (
        <Fragment>
            <div className='container-fluid faderight '>
                <div className='row'>
                    <AnnualBanner />
                </div>
            </div>

        </Fragment>
    )
}

export default Annual