import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import WhistleForm from './WhistleForm';

function Whistlebanner() {
  return (
    <Fragment>
      <div className='container tabss motor whistle' >
        <div className='row'>

          <div className='col-sm-12 heads govbanner blowing'>
            <Fade up delay={500}><h2 className="oswald">Whistle Blowing Form</h2></Fade>
          </div>

          <div className='col-sm-12 heads text mymaxheight complainbox whistle'>
            <WhistleForm />
          </div>

        </div>
      </div>
    </Fragment>
  )
}

export default Whistlebanner