import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import WhychosePersoonalaccident from "../WhychoseUs/WhychosePersoonalaccident";
import Productsnav from "./Productsnav";
import Accidenttab from "./Accidenttab";
import PAForms from "./PAForms/PAForms";

function SalamPersonal() {
  return (
    <Fragment>
      <Productsnav />
      <div className="container tabs motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner personalacc">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Personal Accident Takaful</h2>
              <p className="cemter">
                Did you know thousands of accidents occur every year in
                Pakistan?
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Accidenttab />
          </div>
          <div className="col-sm-12 motorForm">
            <PAForms />
          </div>

          <div className="col-sm-12 heads text">
            <WhychosePersoonalaccident />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamPersonal;
