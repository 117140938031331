import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Accidenttab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/accident.jpg`} />
                        <h4>Accident Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/accidentCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>
                                With Salaam Personal Accident Takaful, you can secure your future with our 24-hour worldwide personal accident coverage. Our comprehensive plan provides compensation for accidental death, permanent and/or temporary disability—whether total or partial—arising from an accident.
                                <br /><br />
                                Additionally, you can extend your coverage to include medical expenses resulting from such accidents. Safeguard yourself and your loved ones with Salaam Personal Accident Takaful, offering reliable protection when you need it most.
                                </p>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Coverages</h3>
                                <ul className='LiveStockList'>
                                    <li>Accidental Death</li>
                                    <li>Permanent Disability</li>
                                    <li>Temporary Disability (Total or Partial)</li>
                                    <li>Medical Expenses (Extended Cover)</li>
                                    <li>Burial & Repatriation (Extended Cover)</li>
                                </ul>
                            </div>

                            <div className='col-sm-4'>
                                <h3></h3>
                                <ul>
                                <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default Accidenttab