import React, { Fragment } from "react";
import Salamtravel from "../../Components/IndividualProductList/Salamtravel";
import { Helmet } from "react-helmet";

function SalamTravel() {
  const metaData = {
    title: 'Salaam Travel Takaful | Islamic Travel Insurance for Peace of Mind',
    description: 'Stay protected during your travels with Salaam Travel Takaful. Our Shariah-compliant travel insurance offers coverage for trip cancellations, medical emergencies, and more'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <Salamtravel />
        </div>
      </div>
    </Fragment>
  );
}

export default SalamTravel;
