import React, { Fragment, useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Jobs } from '../../Data/Data';
import Fade from 'react-reveal/Fade';
import Careerjobsdetail from './Careerjobsdetail';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

function Careerjobs() {
  const [data, setData] = useState(Jobs.length > 0 ? Jobs[0].key : null);

  const handleTabChange = (key) => {
    if (key) setData(key);
  };
  
  const findActiveIndex = () => {
    return Jobs.findIndex((job) => job.key === data);
  };

  const [newState, setNewState] = useState(1);
  
  const handleClicks = (id) => {
    setNewState(parseInt(id));
    console.log('Neww', id)
  }

  // console.log(Jobs[newState].key)
  console.log(newState)
  return (
    <Fragment>
      <div className='sub shariahtabs carerjobs'>
        <Fade up delay={500}>
          {/* <Swiper
            slidesPerView={1} 
            spaceBetween={10}
            // pagination={{ clickable: true }}
            navigation={{ clickable: true }}
            modules={[ Navigation]}
            onSlideChange={(swiper) => handleTabChange(Jobs[swiper.activeIndex].key)}
            initialSlide={findActiveIndex()}
            breakpoints={{
              "@0.00": { slidesPerView: 1, spaceBetween: 10 },
              "@0.75": { slidesPerView: 2, spaceBetween: 30 },
              "@1.00": { slidesPerView: 5, spaceBetween: 30 },
              "@1.50": { slidesPerView: 5, spaceBetween: 30 },
            }}
            className="mySwiper slidesbyvcan"
          >
            {Jobs.map((datas, index) => (
              <SwiperSlide key={datas.key} className={`${datas.key == newState ? 'active' : ''}`}>
                <div className='naavBox hassans ' onClick={() => handleClicks(datas.key)}>
                <p>{datas.title}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {newState == Jobs[newState - 1]?.key ? 
            <Fade up delay={500}>
              <p className='noJOb'>No Jobs Available Yet</p>
            </Fade> : <></>
          } */}
          <Fade up delay={500}>
              <p className='noJOb'>No Jobs Available Yet</p>
            </Fade>
        </Fade>
      </div>
    </Fragment>
  );
}

export default Careerjobs;
