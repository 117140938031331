import React, { Fragment } from 'react'

const ClaimIntimationBanner = () => {
    return (
        <Fragment>
            <div className="container faqbanner homemenu">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>Claim Intimation</h2>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ClaimIntimationBanner
