import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postContactFormStart,
  postContactFormSuccess,
  postContactFormFailure,
} from "../reducers/postContactFormReducer";
import axios from "axios";

export const postContactForm = createAsyncThunk(
  "postContactForm",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postContactFormStart());
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/BlowingForm/PostContactUs`,
        values
      );

      const responseData = await response;

      thunkAPI.dispatch(postContactFormSuccess(values));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postContactFormFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postContactFormFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postContactFormFailure(error.response.data.status));
      }

      throw error;
    }
  }
);
