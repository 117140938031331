import React, { Fragment, useEffect } from "react";

import LifeAtSalaamText from "./LifeAtSalaamText";
import LifeAtSalaamAmenities from "./LifeAtSalaamAmenities";
import LifeAtSalaamGrowth from "./LifeAtSalaamGrowth";
import LifeAtSalaamLearning from "./LifeAtSalaamLearning";
import SalaamAtThumbnail from "./SalaamAtThumbnail";


function LifeAtSalaamBanner() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner1">
          {/* <Fade up delay={500}> */}
                <h2 className="oswald">Life @ Salaam</h2>
              {/* </Fade> */}
          </div>
          <div className="col-sm-12 careerText">
            <LifeAtSalaamText />
          </div>
          <div className="col-sm-12">
            <LifeAtSalaamAmenities />
          </div>{" "}
          <div className="col-sm-12">
            <LifeAtSalaamGrowth />
          </div>
          <div className="col-sm-12">
            <LifeAtSalaamLearning />
          </div>
          <div className="col-sm-12 atThumbnail">
            <SalaamAtThumbnail />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LifeAtSalaamBanner;
