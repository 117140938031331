import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { News } from "../../Data/Data";
import Fade from "react-reveal/Fade";
import RecentAwards from "./AnnualAwards";

function convertToSlug(text) {
  return text.trim().toLowerCase().replace(/\s+/g, "-");
}

function SingleAnnualAwards() {
  const { Key } = useParams();
  console.log(Key);

  const newsItem = News.find((data) => data.Key.toString() === Key);


  return (
    <Fragment>
      <div className="container-fluid faderight">
        <div className="row">
          <div className="container governancemain motor">
            <div className="row">
              <div className="col-sm-12 heads govbanner awards">
                <Fade up delay={500}>
                  <h2 className="oswald">Awards & recognisation</h2>
                </Fade>
              </div>
            </div>

            <div className="row singleblog arrticleAndBlogs">
              <div className="col-sm-9 singlenews">
                {newsItem && (
                  <div className="container-fluid news" key={newsItem.heading}>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 heads">
                          <Fade left delay={500}>
                            <img src={newsItem.feature} alt="img" />
                          </Fade>
                          <Fade left delay={500}>
                            <h2 className="oswald">{newsItem.heading}</h2>
                          </Fade>
                          {newsItem?.date ?
                          <Fade left delay={500}>
                            <p className="date" ><b>Date</b>: {newsItem?.date}</p>
                          </Fade>
                            : <></>
                          }
                        </div>
                        <div className="col-sm-12 heads text newsheight">
                          <Fade left delay={1000}>
                            <p>{newsItem.content}</p>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-3">
                <RecentAwards />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleAnnualAwards;
