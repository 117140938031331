import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getBlowingReportSlice = createSlice({
  name: "getBlowingReport",
  initialState,
  reducers: {
    getBlowingReportStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getBlowingReportSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getBlowingReportFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getBlowingReportStart,
  getBlowingReportSuccess,
  getBlowingReportFailure,
  clearError,
  clearMessage,
} = getBlowingReportSlice.actions;

export default getBlowingReportSlice.reducer;
