import React from 'react'
import { Fragment } from 'react'
import AnnouncementBanner from '../Components/Announcement/AnnouncementBanner'
import { Helmet } from 'react-helmet'

function Announcement() {
  const metaData = {
    title: 'Articles And Blogs | Insightful Articles and Blog Posts',
    description: 'Explore our articles and blogs for insightful information and updates related to Salaam Takaful. Our content covers a range of topics including industry trends, tips, and company insights'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight '>
        <div className='row'>
          <AnnouncementBanner/>
        </div>
      </div>

    </Fragment> 
  )
}

export default Announcement