import React, { Fragment } from 'react'
import { Accordion } from 'react-bootstrap';
import imaage from '../../Assets/waklaFee.jpg'
import { Fade } from 'react-reveal';

const FAQDescription = () => {
    const tableData = [
        {
            id: "1",
            problem: "It is a Risk Transfer mechanism whereby risk is transferred from the policy holder (the Insured) to the Insurance Company (the Insurer) in consideration of 'insurance premium' paid by the Insured.",
            answer: "It is based on mutuality; hence the risk is not transferred but shared by the participants who form a common pool. The Company acts only as the manager of the pool (Takaful Operator)."
        },
        {
            id: "2",
            problem: "It contains the element of uncertainty i.e. 'gharrar' which is forbidden in Islam. There is an uncertainty as to when any loss would occur and how much compensation would be payable.",
            answer: "The element of 'uncertainty' i.e. 'gharrar' is brought down to acceptable levels under Shariah by making contributions as 'Conditional Donations' (tabarru) for a good cause i.e. to mitigate the loss suffered by any one of the participants."
        },
        {
            id: "3",
            problem: "It contains an element of gambling i.e. 'maisir' in that the insured pays an amount (premium) in the expectation of gain (compensation/payment against claim). If the anticipated loss (claim) does not occur, the insured loses the amount paid as premium. If the loss does occur, the insurer loses a far larger amount than collected as premium and the insured gains by the same.",
            answer: "The participant pays the contribution (tabarru) in the spirit of Ne'ea (purity) and brotherhood; hence it obviates the element of 'maisir' while at the same time without losing the benefit of Takaful in the same way as conventional insurance."
        },
        {
            id: "4",
            problem: "Funds are mostly invested in fixed interest bearing instruments like bonds, TFCs, securities, etc. Hence these contain the element of 'riba' (usury) which is forbidden in Islam.",
            answer: "Funds are only invested in non-interest bearing, i.e. riba-free instruments."
        },
        {
            id: "5",
            problem: "Surplus or profit belongs to the Shareholders. The insured is covered during the policy period but is not entitled to any return at the end of such period.",
            answer: "Surplus belongs to the participants and is accordingly returned to them (in proportion to their respective shares of contributions) at the end of the accounting period."
        }
    ];

    const shariahQuestion = [
        {
            id: "1",
            question: "Wakala Fee & Mudarib Share",
            answer: ""
        },
        {
            id: "2",
            question: "Is Risk Protection (Takaful) against Tawakkul (total dependence upon Allah (SWT))?",
            answer: "No human actions change the Will of Allah (SWT) for our destiny. Whether a person has Takaful or not has no effect on future events. However, we are instructed to take precautions and then fully trust and depend upon Almighty Allah (SWT): In a Hadith narrated by Anas bin Malik, one day Prophet Muhammad (PBUH) noticed a Bedouin leaving his camel without tying it. He (PBUH) asked the Bedouin, “Why don’t you tie down your camel”? The Bedouin answered, “I put my trust in Allah (SWT)”. The Prophet (PBUH) then said, “Tie your camel first, then put your trust in Allah (SWT)”. [As quoted in Sunan At-Tirmidhi, 1981.]"
        },
        {
            id: "3",
            question: "Is all Risk Protection (insurance) Haram (prohibited)?",
            answer: "The Fiqh Council of World Muslim League (1398/1978) resolution and The Fiqh Council of Organization of Islamic Conference (1405/1985) in Jeddah resolved that conventional insurance as presently practiced is Haram, and that cooperative insurance (Takaful) is permissible and fully consistent with Shariah principles. Hence, conventional insurance is prohibited for Muslims (because it contains the elements of Riba, Al Maisir, and Al Gharar). By contrast, Takaful provides risk protection in accordance with Shariah based on the principles of Ta’awun (mutual assistance), brotherhood, piety and ethical operations."
        },
        {
            id: "4",
            question: "How is uncertainty (gharar) eliminated from Takaful contract?",
            answer: "Uncertainty can never be eliminated; it remains in the Takaful Contract as well. But, since the Takaful contract comes under Tabarruaat, the uncertainty (gharar) is considered to be within tolerable limits under Shariah. Insurance, being a contract of exchange (muawadat), contains “excessive gharar” and is termed as fasid."
        },
        {
            id: "5",
            question: "All Insurance is a form of Gambling or Wagering, which is forbidden in Islam",
            answer: "Risk or uncertainty can be divided into: Pure Risk and Speculative Risk. Pure Risk involves the possibility of loss or no loss. For example, damage to property due to fire. Pure Risks are the subject of insurance risk protection and Takaful. On the other hand, Speculative Risk involves the possibility of loss, no loss or gain. For example, venturing into a new business, or gambling on horse race. Speculative Risks that include a potential Gain or Profit cannot be insured. Takaful schemes use the principle of indemnification to compensate for the loss that occurs to a Takaful Participant. Takaful insures only Pure Risks and the claims are only payable in the event of Loss to cover repairs, damage, replacement of property, or an agreed fixed amount"
        },
        {
            id: "6",
            question: "Is Takaful a modern day’s concept?",
            answer: "Takaful concept has very old origins, the “word” Takaful is a modern-day invention There are several examples in pre-Islamic history whereby families, tribes or related members throughout the Arabian Peninsula pooled their resources as a means to help the needy on a voluntary and gratuitous basis. Their practices were validated by the Prophet Muhammad (PBUH) and incorporated into the institutions of early Islamic state in Arabia around 650 C.E. So, the origin of Takaful dates back to the first Islamic community in Medina. Early precursors were developed in response to perils and risks associated with long-distance trade via caravans or sea voyage and included:",
            list: [
                {
                    point: "Hilf (confederation)"
                },
                {
                    point: "Daman khatar al tarik (surety)"
                },
                {
                    point: "Al – diyah and Al – aqila (wergild or blood money to rescue an accused involved in accidental killing)."
                },
                {
                    point: "Fidyah (ransom of prisoners of war)"
                },
                {
                    point: "Dawania – Mutual indemnification amongst officers working in the same department during the rule of the 2nd Caliph Umar Ibn Al Khattab."
                }
            ]
        },
        {
            id: "7",
            question: "Are there any references in the Quran and Hadith in support of this Takaful concept?",
            answer: "Although Takaful has very old origins, the word Takaful is a modern-day invention. You can find references regarding risk management and mutuality in Quran and Hadith: Takaful, the way it is transacted today, is based on the secondary source of Islamic Law Ijtihad."
        },
        {
            id: "8",
            question: "Is Takaful an outcome of Ijtihad?",
            answer: "Ijtihad, literally means ‘striving’ or the exercise of effort to extract the rules of law from its source; “And to those who exert we show our path” (Quran 29: 69) It is used when there is no clear text or context, by Muslims who are qualified to exercise it. That is why the concept can be questioned and is in fact evolving through debate for refinement as evident by the emergence of different models."
        },
        {
            id: "9",
            question: "How many Takaful models are there?",
            answer: "Alhamdulillah, in Islam there is room for diversity within certain prescribed parameters. Over the centuries, several Takaful Models have evolved which are approved by the Islamic scholars. While they all share the same fundamental goal of cooperative risk sharing, these models differ slightly in legal structure and organizational operations. Takaful Models are usually described by the Islamic contracts used; namely, Hibbah, or 100% Tabarru' [Sudan], or Al Mudarabah [Bahrain/Malaysia], or Al Wakalah [Saudi Arabia], or Wakala/Waqf [Pakistan]."
        },
        {
            id: "10",
            question: "What Takaful Model is followed in Pakistan?",
            answer: "According to the Takaful Rules-2005, in Pakistan a Takaful product shall be based on the principle of Wakala or Mudaraba or both. Therefore, Takaful companies in Pakistan follow a refined hybrid model named “Wakala – Waqf” model. It is a WAKALA model in which the fund is made a separate legal entity by virtue of it being a waqf. The relationship of the participants and the operator is directly with the WAQF fund. The operator is the ‘Wakeel’ of the fund and the participants pay contribution to the WAQF fund by way of Tabarru (contribution)."
        },
        {
            id: "11",
            question: "How is Takaful companies’ investment income Riba-free?",
            answer: "Unlike insurance companies, whose investment income may contain Riba, Takaful companies invest funds in Property, Islamic Banks, Shariah compliant Stocks and other Shariah approved securities like Sukuk bonds etc."
        },
        {
            id: "12",
            question: "Is Takaful just a change of label?",
            answer: "Although the end result is the same since both insurance and Takaful aim to provide compensation against possible losses, yet the crucial difference lies in the way that each does this. The notion “ends justify means” does not hold when it comes to Islam where both the ends as well as the means must be in order. Chicken can either be slaughtered or given an electric shock; both achieve the same end, a dead chicken. However, the former way makes the meat Halal for eating whereas the later renders it Haram."
        },
        {
            id: "13",
            question: "What is meant by Surplus Sharing?",
            answer: "The Takaful Operator acts only as the Wakeel of the Waqf Fund. If, at the end of the year, there is surplus in the Fund (i.e. after adding all its income and deducting all the outgo), such surplus will be distributed amongst the participants proportionately after considering any claim benefits already availed."
        },
        {
            id: "14",
            question: "Is Takaful for Muslims only?",
            answer: "No, takaful is for all religious as Islam is a universal religion open to all. Likewise, Takaful is open to all whether Muslims or non-Muslims. In Malaysia and Sri Lanka, even non-Muslims are opting for Takaful due to its commercial viability, greater transparency and ethical operations."
        }
    ]

    const productQuestion = [
        {
            id: "1",
            question: "Do Takaful contributions entail a higher rate than the conventional insurance premium?",
            answer: "No, Takaful companies are as competitive as their conventional insurance counterparts. Opting for Takaful will not make you pay the Cost of Being Muslim."
        },
        {
            id: "2",
            question: "All Insurance seeks to maximize profits which take benefits away from Policyholders.",
            answer: "Most conventional insurance companies are stock companies that seek to maximize profits. Since the interests of shareholders conflict with those of the policyholders, these insurers can boost profits for shareholders by raising prices, denying claims, etc. Takaful operators, by contrast, are mutual or cooperative entities. The goal of Takaful is community well-being and self-sustaining operations, not high profits. Under the Takaful Mudarabah Model, surplus (or profits) is shared fairly and equitably between the shareholders and the policyholders (i.e. the ‘Participants’) Under the Takaful Wakalah Model, surplus is returned entirely to the Participants."
        },
        {
            id: "3",
            question: "Can Salaam Takaful Limited cover theft of my car?",
            answer: "Yes, Takaful companies offer the same variety of products offered by any insurance company, whether it is Fire, Marine, Motor etc. In addition, we have the expertise and experience to deliver tailor-made specific solutions for the benefit and convenience of our clients. The only exceptions are those risks that are not in conformity with the Shariah, e.g. breweries, casinos etc."
        },
        {
            id: "4",
            question: "How will I get a claim from a Salaam Takaful Limited?",
            answer: "All procedures, including claims, are the same as in conventional insurance companies. The difference lies in the nature of the contract, not in the procedures. For update on claim, please review the claim procedure of each product in the product section."
        },
        {
            id: "5",
            question: "What advantages does Salaam Takaful Limited offer?",
            answer: "",
            list: [
                {
                    point: "Wide product range."
                },
                {
                    point: "Competitive rates."
                },
                {
                    point: "Fast hassle-free claim process"
                },
                {
                    point: "Excellent client service."
                },
                {
                    point: "The unique option of “Surplus Sharing”."
                },
                {
                    point: "Above all, a way to help other Muslim brothers in need a deed whose reward has been promised by ALLAH."
                }
            ]
        }
    ]

    const renderAnswerContent = (item) => {
        if (!item?.answer) {
            return <img src={imaage} alt="Placeholder" />; // Display an image when the answer is empty
        } else if (item?.list) {
            return (
                <>
                    {item?.answer}
                    <ul>
                        {item.list.map((listItem, index) => (
                            <li key={index}>{listItem.point}</li>
                        ))}
                    </ul>
                </>
            );
        } else {
            return item?.answer; // Display the answer if it's not empty and no list exists
        }
    };

    const renderAnswerContentTwo = (item) => {
        if (item?.list) {
            return (
                <>
                    <ul>
                        {item.list.map((listItem, index) => (
                            <li key={index}>{listItem.point}</li>
                        ))}
                    </ul>
                </>
            );
        } else {
            return item?.answer; // Display the answer if it's not empty and no list exists
        }
    };
    return (
        <Fragment>
            <div className="container-fluid faqDesc">
                <div className="row first">
                    <div className="col-sm-12 DESSS">
                        <h2>What is Takaful?</h2>
                        <p>
                            <span>The word Takaful is derived from the Arabic verb Kafala, which means to guarantee; to help; to take care of one’s needs. <br /></span>
                            Takaful is a system of Islamic insurance based on the principle of Ta’awun (mutual assistance) and Tabarru (voluntary contribution), where risk is shared collectively by a group of participants, who by paying contributions to a common fund, agree to jointly guarantee themselves against loss or damage to any one of them as defined in the pact. Takaful is operated based on shared responsibility, brotherhood, solidarity and mutual cooperation.
                        </p>
                    </div>

                    <div className="col-sm-12">
                        <h3>Difference between Conventional Insurance & Takaful?</h3>
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-5 headd">CONVENTIONAL INSURANCE</div>
                            <div className="col-sm-6 headd">TAKAFUL</div>
                        </div>
                        {
                            tableData.map((item, i) => (
                                <Fade delay={200 * parseInt(item?.id)} top key={item?.id}>
                                    <div className="row loop">
                                        <div className="col-sm-1 frwdArr">{'\u2192'}</div>
                                        <div className="col-sm-5">{item?.problem}</div>
                                        <div className="col-sm-6">{item?.answer}</div>
                                    </div>
                                </Fade>
                            ))
                        }
                    </div>

                    <div className="col-sm-12">
                        <h3>Questions related to Shariah/Takaful</h3>

                        <Accordion defaultActiveKey="1">
                            {shariahQuestion.map((item) => (
                                <Fade delay={200 * parseInt(item?.id)} top key={item?.id}>
                                    <Accordion.Item eventKey={item?.id}>
                                        <Accordion.Header>{item?.question}</Accordion.Header>
                                        <Accordion.Body>{renderAnswerContent(item)}</Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                            ))}
                        </Accordion>
                    </div>

                    <div className="col-sm-12">
                        <h3>Questions related to Product</h3>

                        <Accordion defaultActiveKey="1">
                            {productQuestion.map((item) => (
                                <Fade delay={200 * parseInt(item?.id)} top key={item?.id}>
                                    <Accordion.Item eventKey={item?.id}>
                                        <Accordion.Header>{item?.question}</Accordion.Header>
                                        <Accordion.Body>{renderAnswerContentTwo(item)}</Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FAQDescription
