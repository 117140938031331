import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

function Capitals() {
  return (
    <Fragment>
      <div className="container-fluid shares">
        <div className="row">
          <div className="col-sm-5 imageshare">
            <img
              src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/shareholder.png`}
              alt="shareholding"
              data-aos="fade-up"
              data-aos-duration="4000"
            />
          </div>

          <div className="col-sm-7 ">
            <h2 data-aos="fade-right" data-aos-duration="4000">
              Paid up Capital:
            </h2>
            <p data-aos="fade-right" data-aos-duration="4000">
              Salaam Takaful Limited was incorporated in June, 2006 with its
              registered head-office in Karachi. The authorized capital of the
              Company is Rs. 1.5 billion. The issued, subscribed and paid-up capital of the company as of June 30th 2024 is Rs.1.78 billion.
            </p>

            <h2 data-aos="fade-right" data-aos-duration="4000">
              Shareholders
            </h2>
            <ul data-aos="fade-right" data-aos-duration="4000">
              <li>
                <b>Mr. Salim Habib Godil</b>
              </li>
              <li>
                <b>Mr. Rizwan Hussain</b>
              </li>
              <li>
                <b>Mr. Shahzad Salim Godil</b>
              </li>
              <li>
                <b>Syed Salman Hussain</b>
              </li>
              <li>
                <b>House Building Finance Corporation</b>
              </li>
              <li>
                <b>Al Baraka Bank (Pakistan) Limited</b>
              </li>
              <li>
                <b>Mal Al-Khaleej Investment LLC (UAE)</b>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-sm-12 shareholding">
        <h2 data-aos="fade-right" data-aos-duration="4000">
          Salaam Takaful Shareholding Pattern
        </h2>
        <p data-aos="fade-right" data-aos-duration="4000">
          {" "}
          <Link
            to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/PatternofShareholding.pdf`}
            target="_blank"
          >
            View Here
          </Link>{" "}
          Date: 31 Dec, 2021.
        </p>
      </div>
    </Fragment>
  );
}

export default Capitals;
