import React, { Fragment } from 'react'

import CorporateBanner from '../Components/CorporateProduct/CorporateProductBanner'
import CorporateMenu from '../Components/CorporateProduct/CorporateMenu'


function CorporateProduct() {

    return (

        <Fragment>
            <div className='container faderight about'>
                <div className='row'>
                    <CorporateBanner />

                </div>
                <CorporateMenu />
            </div>

        </Fragment>

    )
}

export default CorporateProduct