import React, { useState } from "react";
import { Fragment } from "react";
import { Button, Form, Input, message } from "antd";
import Fade from "react-reveal/Fade";
import { useDispatch } from "react-redux";
import { postContactForm } from "../../Redux/actions/postContactForm";
import { toast } from "react-toastify";

function Contactform() {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  // const Contact = `${process.env.REACT_APP_URL_ASSETS}Assets/img/contactimg.png`;

  const onFinish = async (values) => {
    setSubmitting(true);
    await dispatch(postContactForm(values));
    // message.success("Form submitted successfully.");
    form.resetFields();
    setSubmitting(false);
    toast.success("One of our representative will get in touch with you soon")
  };

  return (
    <Fragment>
      <div className="sub complainforms container">
        <div className="row">
          <div className="col-sm-6">
            <Fade up delay={300}>
              {" "}
              {/* <img src={Contact} alt="phone" /> */}
            </Fade>
          </div>

          <div className="col-sm-6 formstyle contactFormstyle">
            <h3>Let’s Talk</h3>
            <Form
              form={form}
              name="nest-messages"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Fade down delay={500}>
                {" "}
                <Form.Item 
                  name="name" 
                  rules={[
                    { 
                      required: true,
                      message: "Please enter Name!" 
                    }
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="EmailAddress"
                  rules={[
                    { 
                      type: "email",
                      message: "Email is not valid!" 
                    },
                    {
                      required: true,
                      message: "Email is required!"
                    }
                  ]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              <Fade down delay={900}>
                <Form.Item
                  name="MobileNo"
                  rules={[
                    {
                      required: true,
                      pattern: /^03\d{2}-\d{7}$/,
                      message:
                        "Contact number must be in this format 03XX-XXXXXXX",
                    },
                  ]}
                  normalize={(value) => {
                    if (!value) {
                      return value;
                    }
                    const onlyNums = value.replace(/[^\d]/g, "");
                    if (onlyNums.length <= 4) {
                      return onlyNums;
                    } else if (onlyNums.length <= 11) {
                      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                    }
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }}
                >
                  <Input placeholder="Contact 03XX-XXXXXXX" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item 
                  name="Details"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a message!"
                    }
                  ]}
                >
                  <Input.TextArea
                    placeholder="Message"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>

              <Fade down delay={1300}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
              </Fade>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Contactform;
