import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorColorSlice = createSlice({
  name: "getMotorColor",
  initialState,
  reducers: {
    getMotorColorStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorColorSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorColorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorColorStart,
  getMotorColorSuccess,
  getMotorColorFailure,
  clearError,
  clearMessage,
} = getMotorColorSlice.actions;

export default getMotorColorSlice.reducer;
