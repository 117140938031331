import { Form, Input } from 'antd'
import React from 'react'

const EVerifyFireForm = () => {
    return (
        <div className="row">
            <div className="col-sm-6 labelSSS">
                <Form.Item
                    name='Policy'
                    label="Policy #"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter Policy Number!"
                        }
                    ]}
                >
                    <Input placeholder='Policy #' />
                </Form.Item>

            </div>

        </div>
    )
}

export default EVerifyFireForm