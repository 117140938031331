import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function ProfessionalTabs() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row singleprod">
            <Fade down delay={500}>
              <div className="col-sm-3 imgeboxes">
                <img
                  src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/ProfessionalIndeminityTakaful.jpg`}
                />
                <h4>Salaam Professional Indeminity Takaful</h4>
              </div>
            </Fade>

            <Fade up delay={500}>
              <div
                className="col-sm-12 mylist"
                style={{
                  backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/ProfessionalIndeminityTakafulCover.jpg`})`,
                }}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <p>
                      <b>
                        This policy provides cover against legal liability
                        caused by Errors and Omissions{" "}
                      </b>
                      <br />
                      Includes the participant’s business predecessors, or any
                      person employed by the participant.
                    </p>
                  </div>

                  <div className="col-sm-4 fireCoverage">
                    <h3>Coverages</h3>
                    <p>
                      Covers against debits established due to unauthorized use
                      of plastic cards.
                    </p>
                    <ul className="LiveStockList">
                      <li>Lawyers</li>
                      <li>Architects</li>
                      <li>Engineers </li>
                      <li>Doctors </li>
                      <li>Surveyors</li>
                      <li>Consultants</li>
                      <li>Accountants</li>
                      <li>
                        Information Technology / Software Professionals /
                        Consultants / Firms
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fragment>
    );
}

export default ProfessionalTabs