import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHomeFormFiveStart,
  postHomeFormFiveSuccess,
  postHomeTwoDataSuccess,
  postHomeFormFiveFailure,
} from "../reducers/postHomeFormFiveReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postHomeFormFive = createAsyncThunk(
  "postHomeFormFive",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHomeFormFiveStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Home/SaveHomeForthPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postHomeFormFiveSuccess(responseData?.data?.result));
      thunkAPI.dispatch(postHomeFormFiveSuccess(values));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postHomeFormFiveFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postHomeFormFiveFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postHomeFormFiveFailure(error.response.data.status));
      }
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
