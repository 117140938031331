import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postTravelSixStart,
  postTravelSixSuccess,
  postTravelSixFailure,
} from "../reducers/postTravelSixReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postTravelSix = createAsyncThunk(
  "postTravelSix",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTravelSixStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Travel/SaveTravelSixthPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postTravelSixSuccess(responseData?.data?.result));
      
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTravelSixFailure(error.response.data.status));
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
