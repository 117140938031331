import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postMotorCommercialStart,
  postMotorCommercialSuccess,
  postMotorCommercialFailure,
} from "../reducers/postMotorCommercialReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postMotorCommercial = createAsyncThunk(
  "postMotorCommercial",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postMotorCommercialStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Motor/SaveCommercialVehicle`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postMotorCommercialSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        postMotorCommercialFailure(error.response.data.Message)
      );

      if (error.response.data.Message) {
        thunkAPI.dispatch(
          postMotorCommercialFailure(error.response.data.Message)
        );
      } else {
        thunkAPI.dispatch(
          postMotorCommercialFailure(error.response.data.status)
        );
      }
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");
        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
