import { Button, Form, Input, message, Select } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import { getMotorProduct } from "../../../Redux/actions/getMotorProduct";
import { postMotorOne } from "../../../Redux/actions/postMotorOne";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postMotorOneReducer";
import { clearMessage as formTwoClearerror } from "../../../Redux/reducers/postFormTwoReducer";
import { clearMessage as formThreeClearerror } from "../../../Redux/reducers/postFormThreeReducer";
import { clearMessage as formCommClearerror } from "../../../Redux/reducers/postMotorCommercialReducer";
import FormTwo from "./FormTwo";
import { postFormTwo } from "../../../Redux/actions/postFormTwo";
import FormThree from "./FormThree";
import { postFormThree } from "../../../Redux/actions/postFormThree";
import Cookies from "js-cookie";
import { postMotorFour } from "../../../Redux/actions/postMotorFour";
import MotorFormOne from "./MotorFormOne";
import FormFive from "./FormFour";
import FormFour from "./FormFour";
import ComercialForm from "./ComercialForm";
import { postMotorCommercial } from "../../../Redux/actions/postMotorCommercial";

const FormOne = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Use form instance for resetting

  const [currentStep, setCurrentStep] = useState(1);
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const formOneData = useSelector((state) => state.postMotorOne.data);

  const { postError } = useSelector((state) => state.postMotorOne);
  const formTwoError = useSelector((state) => state.postFormTwo.postError);
  const formThreeError = useSelector((state) => state.postFormThree.postError);
  const formCommError = useSelector(
    (state) => state.postMotorCommercial.postError
  );

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    console.log("Step 1 values:", values);

    const resultAction = await dispatch(postMotorOne({ values }));
    if (postMotorOne.fulfilled.match(resultAction)) {
      handleNext();
      // toast.success("Form Submit Successfully");
      form.resetFields();
    }
  };

  const onFinishStep2 = async (values) => {
    console.log("Step 1 values:", values);
    values.Unique_ID = formOneData[0];
    const resultAction = await dispatch(postFormTwo({ values }));
    if (postFormTwo.fulfilled.match(resultAction)) {
      handleNext();
    }
  };
  const onFinishStepCommercial = async (values) => {
    console.log("Step 1 values:", values);
    values.CProductID = productId;
    const resultAction = await dispatch(postMotorCommercial({ values }));
    if (postMotorCommercial.fulfilled.match(resultAction)) {
      setCurrentStep(1);
      toast.success("Form Submit Successfully");
    }
  };
  const onFinishFailedCommercial = async (errorInfo) => {
    toast.error(errorInfo);
  };

  const onFinishStep3 = async (values) => {
    console.log("Step 1 values:", values);
    values.Unique_ID = formOneData[0];
    if (values.CNIC_Expiry_Date) {
      values.CNIC_Expiry_Date = values.CNIC_Expiry_Date.format("DD-MMM-YYYY");
    }

    if (values.DOB) {
      values.DOB = values.DOB.format("DD-MMM-YYYY");
    }

    const resultAction = await dispatch(postFormThree({ values }));
    if (postFormThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep4 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0];
    values.Day = values.Day.format("DD-MMM-YYYY");
    const resultAction = await dispatch(postMotorFour({ values }));

    if (postMotorFour.fulfilled.match(resultAction)) {
      toast.success("Form Submitted Successfully");
      setCurrentStep(1);
      Cookies.remove("token");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const token = Cookies.get("token");

  useEffect(() => {
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (postError) {
      toast.error(postError);
      dispatch(clearMessage());
    }
    if (formTwoError) {
      toast.error(formTwoError);
      dispatch(formTwoClearerror());
    }
    if (formThreeError) {
      toast.error(formThreeError);
      dispatch(formThreeClearerror());
    }
    if (formCommError) {
      toast.error(formCommError);
      dispatch(formCommClearerror());
    }
  }, [postError, formTwoError, formThreeError, formCommError]);

  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div className="row formTakafulll">
          {/* Form 1 */}

          {currentStep === 1 && (
            <MotorFormOne
              setProductId={setProductId}
              productId={productId}
              product={product}
              setProduct={setProduct}
              onFinishStep1={onFinishStep1}
              onFinishFailed={onFinishFailed}
              form={form}
            />
          )}

          {/* Form 2 */}

          {product == "Commercial vehicle" ? (
            <>
              {currentStep === 2 && (
                <>
                  <ComercialForm
                    onFinishStepCommercial={onFinishStepCommercial}
                    onFinishFailedCommercial={onFinishFailedCommercial}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {currentStep === 2 && (
                <>
                  <FormTwo
                    onFinishStep2={onFinishStep2}
                    onFinishFailedTwo={onFinishFailedTwo}
                  />
                </>
              )}

              {/* Form 3 */}

              {currentStep === 3 && (
                <>
                  <FormThree
                    onFinishStep3={onFinishStep3}
                    onFinishFailedThree={onFinishFailedThree}
                  />
                </>
              )}

              {currentStep === 4 && (
                <>
                  <FormFour
                    formOneData={formOneData}
                    onFinishStep4={onFinishStep4}
                    onFinishFailedFour={onFinishFailedFour}
                  />
                </>
                // <div
                //   className="col-sm-8 mForm"
                //   style={{
                //     backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/blueCar.png)`,
                //     backgroundSize: "cover",
                //     backgroundRepeat: "no-repeat",
                //   }}
                // >
                //   <div className="centreWidthFOrm">
                //     <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
                //     <h5 className="cnhbsh5">Payment</h5>

                //     <div className="paymentImages">
                //       <div className="creditMaster">
                //         <img src="https://banner2.cleanpng.com/20180627/sku/kisspng-debit-card-visa-credit-card-mastercard-payment-haircut-tool-5b33bbb449b7d0.886199351530117044302.jpg" />
                //       </div>
                //       <div className="creditMaster">
                //         <img src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-logok-15.png" />
                //       </div>
                //     </div>
                //     <Form
                //       name="sehatFormSeven"
                //       onFinish={onFinishStep4}
                //       onFinishFailed={onFinishFailedFour}
                //       autoComplete="off"
                //       layout="vertical"
                //     >
                //       <div className="row multiFormPayment">
                //         <div className="col-sm-6">
                //           <Form.Item
                //             label="Card Holder Name"
                //             name="cardHolderName"
                //             rules={[
                //               {
                //                 required: true,
                //                 message: "Please enter card holder name",
                //               },
                //             ]}
                //           >
                //             <Input placeholder="Name" />
                //           </Form.Item>
                //         </div>
                //         <div className="col-sm-6">
                //           <Form.Item
                //             label="Card Number"
                //             name="cardNumber"
                //             rules={[
                //               {
                //                 required: true,
                //                 message: "Please enter card number",
                //               },
                //               {
                //                 pattern: /^[0-9]{0,16}$/,
                //                 message:
                //                   "Please enter a valid card number (maximum 16 digits)",
                //               },
                //             ]}
                //           >
                //             <Input placeholder="Card Number" />
                //           </Form.Item>
                //         </div>

                //         <div className="col-sm-6">
                //           <Form.Item
                //             label="CVV"
                //             name="cardCVV"
                //             rules={[
                //               {
                //                 required: true,
                //                 message: "Please enter CVV",
                //               },
                //               {
                //                 pattern: /^[0-9]{0,4}$/,
                //                 message:
                //                   "Please enter a valid CVV (maximum 4 digits)",
                //               },
                //             ]}
                //           >
                //             <Input placeholder="Example: 4567" />
                //           </Form.Item>
                //         </div>

                //         <div className="col-sm-6">
                //           <Form.Item
                //             label="Expiration Date"
                //             name="expirationDate"
                //             rules={[
                //               {
                //                 required: true,
                //                 message: "Please enter card expiry date (MM/YY)",
                //               },
                //               {
                //                 pattern: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                //                 message:
                //                   "Please enter a valid expiry date in MM/YY format",
                //               },
                //             ]}
                //           >
                //             <Input placeholder="MM/YY" />
                //           </Form.Item>
                //         </div>
                //       </div>

                //       <Form.Item>
                //         <Button
                //           type="primary"
                //           htmlType="submit"
                //           style={{ background: "#0FB583", marginTop: "30px" }}
                //         >
                //           Pay Now
                //         </Button>
                //       </Form.Item>
                //     </Form>
                //   </div>
                // </div>
              )}
            </>
          )}

          <div className="col-sm-4 mForm">
            <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormOne;
