import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getTravelTotalContributionSlice = createSlice({
  name: "getTravelTotalContribution",
  initialState,
  reducers: {
    getTravelTotalContributionStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getTravelTotalContributionSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getTravelTotalContributionFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getTravelTotalContributionStart,
  getTravelTotalContributionSuccess,
  getTravelTotalContributionFailure,
  clearError,
  clearMessage,
} = getTravelTotalContributionSlice.actions;

export default getTravelTotalContributionSlice.reducer;
