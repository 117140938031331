import React, { Fragment } from "react";
import IndividualBanner from "../Components/IndividualProducts/IndividualBanner";
import IndividualMenu from "../Components/IndividualProducts/IndividualMenu";

function IndividualProduct() {
  return (
    <Fragment>
      <div className=" container faderight prodd">
        <div className="row">
          <IndividualBanner />
        </div>
        <IndividualMenu />
      </div>
    </Fragment>
  );
}

export default IndividualProduct;
