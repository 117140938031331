import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postMotorOneSlice = createSlice({
  name: "postMotorOne",
  initialState,
  reducers: {
    postMotorOneStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postMotorOneSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postMotorOneFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postMotorOneStart,
  postMotorOneSuccess,
  postMotorOneFailure,
  clearError,
  clearMessage,
} = postMotorOneSlice.actions;

export default postMotorOneSlice.reducer;
