import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTravelTotalContributionStart,
  getTravelTotalContributionSuccess,
  getTravelTotalContributionFailure,
} from "../reducers/getTravelTotalContributionReducer.js";
import axios from "axios";
import Cookies from "js-cookie";

export const getTravelTotalContribution = createAsyncThunk(
  "getTravelTotalContribution",
  async ({ productId, planId, tenure, dOB, Type }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getTravelTotalContributionStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Travel/GettravelTotalContribution?Product_ID=${productId}&Plan_ID=${planId}&NoOfDays=${tenure}&DOB=${dOB}&Type=${Type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(
        getTravelTotalContributionSuccess(responseData?.data?.result)
      );

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getTravelTotalContributionFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
