import React from 'react'
import { Fragment } from 'react'
import Fade from 'react-reveal/Fade';

function Corporateinfos() {
    return (
        <Fragment>
            <div className='container boxes companyDetails'>
                <div className='row ArticlesAndBlogs'>

                    <Fade down delay={500}>
                        <div className='col-sm-6'>
                            <div className='audit'>
                                <h3 data-aos="fade-right" data-aos-duration="4000">Name of Statutory Auditor</h3>
                                {/* <p data-aos="fade-right" data-aos-duration="4000">KPMG Taseer Hadi & Co. Chartered Accountants, Sheikh Sultan Trust Building No.2 Beaumont Road, Shahrah-e-Faisal, Karachi - 75530, Pakistan Phones: (0092-21) 3568 5847 Fax: (0092-21) 3568 5095 Web: www.home.kpmg.com</p> */}
                                <p data-aos="fade-right" data-aos-duration="4000">
                                    KPMG Taseer Hadi & Co. Chartered Accountants, Sheikh Sultan Trust Building No.2 Beaumont Road, Shahrah-e-Faisal, Karachi - 75530, Pakistan 
                                    Phones: <a href='tel:+922135685847'>(0092-21) 3568 5847 </a> 
                                    Fax: <a href='tel:+922135685095'>(0092-21) 3568 5095 </a> 
                                    Web: <a href='https://kpmg.com' target='_blank'>www.kpmg.com</a>
                                </p>
                            </div>

                           
                        </div>
                    </Fade>

                    <Fade down delay={700}>
                        <div className='col-sm-6'>
                            <div className='audit'>
                                <h3 data-aos="fade-left" data-aos-duration="4000">Legal Advisor</h3>
                                <p data-aos="fade-left" data-aos-duration="4000">AHM & Co Address: 415, Eden Heights, Jail Road, Lahore | Email: <a href='mailto:info@ahmco.pk'>info@ahmco.pk</a> | Phone: <a href='tel:04235786749'>042-35786749</a> | Website: <a href='http://ahmco.pk/' target='_blank'>http://ahmco.pk/</a></p>
                            </div>
                        </div>
                    </Fade>

                    <Fade>
                        <div className="col-sm-6">
                            <div className='audit'>
                                <h3 data-aos="fade-right" data-aos-duration="4000">Membership</h3>
                                <p data-aos="fade-right" data-aos-duration="4000">Insurance Association of Pakistan (IAP) & Karachi Chamber of Commerce and Industries (KCCI)</p>
                            </div>

                            
                        </div>
                    </Fade>
                    
                    <Fade>
                        <div className="col-sm-6">
                            <div className='audit'>
                                <h3 data-aos="fade-left" data-aos-duration="4000">Regulator of the Company</h3>
                                <p data-aos="fade-left" data-aos-duration="4000">Securities & Exchange Commission of Pakistan Insurance Division, State Life Building-2, 4Th Floor, Wallance Road, Karachi, Pakistan Web link: <a href='http://www.secp.gov.pk' target='_blank'>http://www.secp.gov.pk</a></p>
                            </div>

                          
                        </div>
                    </Fade>

                    <Fade>
                        <div className="col-sm-6">
                        <div className='audit'>
                                <h3 data-aos="fade-right" data-aos-duration="4000">Corporate Advisor</h3>
                                <p data-aos="fade-right" data-aos-duration="4000">RS Corporate Advisory: First Floor, Plot No.62, Central Commercial Area (CCA), Block-T, Phase-2 DHA, Lahore Cantt.</p>
                            </div>
                        </div>
                    </Fade>

                    <Fade>
                        <div className="col-sm-6">
                        <div className='audit'>
                                <h3 data-aos="fade-left" data-aos-duration="4000">Share Registrar</h3>
                                <p data-aos="fade-left" data-aos-duration="4000">Central Depository Company of Pakistan Limited,CDC House.99-B,Block-B, S.M.C.H.S,Main Shahra-e-Faisal. Karachi. | Tel UAN: <a href='tel:+9221111111500'>(+92 21) 111 111 500 </a> | Email: <a href='mailto:info@cdcpak.com'>info@cdcpak.com</a></p>
                            </div>
                        </div>
                    </Fade>

                </div>
            </div>
        </Fragment>
    )
}

export default Corporateinfos