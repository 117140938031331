import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postComplainFormStart,
  postComplainFormSuccess,
  postComplainFormFailure,
} from "../reducers/postComplainFormReducer";
import axios from "axios";

export const postComplainForm = createAsyncThunk(
  "postComplainForm",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postComplainFormStart());
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/BlowingForm/PostComplainBoxForm`,
        values
      );

      const responseData = await response;

      thunkAPI.dispatch(postComplainFormSuccess(values));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postComplainFormFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postComplainFormFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postComplainFormFailure(error.response.data.status));
      }

      throw error;
    }
  }
);
