import { Form } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { postHomeOne } from "../../../Redux/actions/postHomeOne";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postHomeOneReducer";
import { clearMessage as clearTwoMessage } from "../../../Redux/reducers/postHomeFormTwoReducer";
import { clearMessage as clearThreeMessage } from "../../../Redux/reducers/postHomeFormThreeReducer";
import { clearMessage as clearFourMessage } from "../../../Redux/reducers/postHomeFormFourReducer";
import { clearMessage as clearFiveMessage } from "../../../Redux/reducers/postHomeFormFiveReducer";
import HomeFormTwo from "./HomeFormTwo";
import { postHomeFormTwo } from "../../../Redux/actions/postHomeFormTwo";
import { postHomeFormFive } from "../../../Redux/actions/postHomeFormFive";
import HomeFormThree from "./HomeFormThree";
import { postHomeFormThree } from "../../../Redux/actions/postHomeFormThree";
import HomeFormFour from "./HomeFormFour";
import { postHomeFormFour } from "../../../Redux/actions/postHomeFormFour";
import HomeFormFive from "./HomeFormFive";
import PAFormOne from "./PAFormOne";

const PAForms = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [selectedHMIDs, setSelectedHMIDs] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const [currentStep, setCurrentStep] = useState(1);
  const [Type, setType] = useState();
  const [product_id, setProduct_id] = useState(4);
  const [DOB, setDOB] = useState("12-Jun-1984");

  const [beneficiaries, setBeneficiaries] = useState([
    { Name: "", CNIC: "", DOB: "", Gender: "", Relation: "" },
  ]);
  const [healthBeneficiary, setHealthBeneficiary] = useState([
    {
      NameFamily: "",
      DescribeMedCond: "",
      treatmentDate: "",
      DoctorAttending: "",
    },
  ]);

  const formOneData = useSelector((state) => state.postHomeOne.data);
  const formthreeData = useSelector((state) => state.postHomeFormThree.data);
  const formOneError = useSelector((state) => state.postHomeOne.postError);
  const formTwoError = useSelector((state) => state.postHomeFormTwo.postError);
  const formThreeError = useSelector(
    (state) => state.postHomeFormThree.postError
  );

  const formFourError = useSelector(
    (state) => state.postHomeFormFour.postError
  );
  const formFiveError = useSelector(
    (state) => state.postHomeFormFive.postError
  );
  const formOnePostedData = useSelector(
    (state) => state.postHealthOne.postData
  );

  const handleAddHealthBeneficiary = () => {
    setHealthBeneficiary([
      ...healthBeneficiary,
      {
        NameFamily: "",
        DescribeMedCond: "",
        treatmentDate: "",
        DoctorAttending: "",
      },
    ]);
  };
  const handleRemoveHealthBeneficiary = (indexToRemove) => {
    const updatedHealthBeneficiaries = healthBeneficiary.filter(
      (_, index) => index !== indexToRemove
    );
    setHealthBeneficiary(updatedHealthBeneficiaries);
  };

  const handleAddBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      { Name: "", CNIC: "", DOB: "", Gender: "", Relation: "" },
    ]);
  };

  const handleRemoveBeneficiary = (indexToRemove) => {
    const updatedBeneficiaries = beneficiaries.filter(
      (_, index) => index !== indexToRemove
    );
    setBeneficiaries(updatedBeneficiaries);
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    console.log("Step 1 values:", values);
    const resultAction = await dispatch(postHomeOne({ values }));
    if (postHomeOne.fulfilled.match(resultAction)) {
      form.resetFields();
      handleNext();
    }
  };

  const onFinishStep2 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;

    const resultAction = await dispatch(postHomeFormTwo({ values }));
    if (postHomeFormTwo.fulfilled.match(resultAction)) {
      form.resetFields();
      handleNext();
    }
  };

  const onFinishStep3 = async (values) => {
    console.log(values);
    const resultAction = await dispatch(postHomeFormThree({ values }));
    if (postHomeFormThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep4 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;
    values.PlanID = formthreeData && formthreeData?.PlainID;
    values.ProductID = "1";

    const resultAction = await dispatch(postHomeFormFour({ values }));
    if (postHomeFormFour.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep5 = async (values) => {
    console.log("Values:", values);

    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;

    const resultAction = await dispatch(postHomeFormFive({ values }));
    if (postHomeFormFive.fulfilled.match(resultAction)) {
      toast.success("Form Submitted Successfully");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFive = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (formOneError) {
      toast.error(formOneError);
      dispatch(clearMessage());
    }
    if (formTwoError) {
      toast.error(formTwoError);
      dispatch(clearTwoMessage());
    }
    if (formThreeError) {
      toast.error(formThreeError);
      dispatch(clearThreeMessage());
    }
    if (formFourError) {
      toast.error(formFourError);
      dispatch(clearFourMessage());
    }
    if (formFiveError) {
      toast.error(formFiveError);
      dispatch(clearFiveMessage());
    }
  }, [
    formOneError,
    formTwoError,
    formThreeError,
    formFourError,
    formFiveError,
  ]);

  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div
          className="row formTakafulll"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/SehatFormOne.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Form 1 */}

          {currentStep === 1 && (
            <PAFormOne
              onFinishStep1={onFinishStep1}
              onFinishFailed={onFinishFailed}
              form={form}
            />
          )}

          {/* Form 2 */}

          {currentStep === 2 && (
            <HomeFormTwo
              onFinishFailedTwo={onFinishFailedTwo}
              onFinishStep2={onFinishStep2}
            />
          )}

          {/* Form 3 */}

          {currentStep === 3 && (
            <HomeFormThree
              onFinishFailedThree={onFinishFailedThree}
              onFinishStep3={onFinishStep3}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          )}

          {/* Form 4 */}

          {currentStep === 4 && (
            <HomeFormFour
              formOnePostedData={formOnePostedData}
              onFinishFailedFour={onFinishFailedFour}
              onFinishStep4={onFinishStep4}
              selectedHMIDs={selectedHMIDs}
              setSelectedHMIDs={setSelectedHMIDs}
            />
          )}

          {/* Form 5 */}

          {currentStep === 5 && (
            <>
              <HomeFormFive
                onFinishStep5={onFinishStep5}
                onFinishFailedFive={onFinishFailedFive}
                DOB={DOB}
                product_id={product_id}
              />
            </>
          )}

          <div className="col-sm-4 mForm">
            {/* <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PAForms;
