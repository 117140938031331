import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getHealthOccupationSlice = createSlice({
  name: "getHealthOccupation",
  initialState,
  reducers: {
    getHealthOccupationStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getHealthOccupationSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getHealthOccupationFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getHealthOccupationStart,
  getHealthOccupationSuccess,
  getHealthOccupationFailure,
  clearError,
  clearMessage,
} = getHealthOccupationSlice.actions;

export default getHealthOccupationSlice.reducer;
