import React, { Fragment } from "react";
import Banner from "../../Components/Claimform/Banner";
import { Helmet } from "react-helmet";

function Claimforms() {
  const metaData = {
    title: 'Claim Forms | Islamic Insurance Claim Forms',
    description: 'Access our Islamic insurance claim forms for Salaam Takaful. Easily submit your claims for processing and get the support you need for timely resolution of your insurance issues'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Banner />
        </div>
      </div>
    </Fragment>
  );
}

export default Claimforms;