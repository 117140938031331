import React, { Fragment } from 'react'
import SalamComputers from '../../Components/FinancialProductList/SalamComputer'
import { Helmet } from 'react-helmet'

function SalamComputer() {
    const metaData = {
        title: 'Salaam Computer Crime Takaful | Islamic Cyber Crime Insurance',
        description: 'Protect your business from cyber threats with Salaam Computer Crime Takaful. Our Shariah-compliant insurance covers losses from hacking, data breaches, and other cyber crimes'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight '>
                <div className='row arrticleAndBlogs'>
                    <SalamComputers />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamComputer