import { Button, Form, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NewForm = () => {
    const [selectedCategory, setSelectedCategory] = useState();
    const [productOptions, setProductOptions] = useState([]);
    const navigate = useNavigate();
    const [selectedLink, setSelectedLink] = useState(null);

    const productData = [
        {
            key: 1,
            name: 'Individual Product',
            products: [
                { key: "1", name: 'Salaam Motor Takaful', link: "/individualproduct/salammotor" },
                { key: "2", name: 'Salaam Sehat Takaful', link: "/individualproduct/salamsehat" },
                { key: "3", name: 'Salaam Travel Takaful', link: "/individualproduct/salamtravel" },
                { key: "4", name: 'Salaam Home Takaful', link: "/individualproduct/salamhome" },
                { key: "5", name: 'Salaam Personal Accident Takaful', link: "/individualproduct/salampersonal" },
            ]
        },
        {
            key: 2,
            name: 'Corporate Product',
            products: [
                { key: "1", name: 'Salaam Group Health Takaful', link: "/corporateproduct/salam-group-health" },
                { key: "2", name: 'Salaam Crop Takaful', link: "/corporateproduct/salamcrop" },
                { key: "3", name: 'Salaam Livestock Takaful', link: "/corporateproduct/salamlivestock" },
                { key: "4", name: 'Salaam Fire Takaful', link: "/corporateproduct/salam-fire" },
                { key: "5", name: 'Salaam Marine Takaful', link: "/corporateproduct/salam-marine" },
                { key: "6", name: 'Salaam Engineering Takaful', link: "/corporateproduct/salam-engineering" },
                { key: "7", name: 'Salaam Miscellaneous Takaful', link: "/corporateproduct/salam-miscellaneous" },
            ]
        },
        {
            key: 3,
            name: 'Financial Product',
            products: [
                { key: "1", name: 'Salaam Bankers Blanket Takaful', link: "/financialproduct/salaam-banker" },
                { key: "2", name: 'Salaam Plastic Card Takaful', link: "/financialproduct/salaam-plasticcard" },
                { key: "3", name: 'Salaam Safe Deposit Box Takaful Coverage', link: "/financialproduct/salaam-deposit" },
                { key: "4", name: 'Salaam Professional Indemnity Takaful', link: "/financialproduct/salaam-professional" },
                { key: "5", name: 'Salaam Computer Crime Takaful', link: "/financialproduct/salaam-computer" },
                { key: "6", name: 'Salaam Cyber Risk Takaful', link: "/financialproduct/salaam-cyber" },
            ]
        }
    ];

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        const selectedProduct = productData.find((product) => product.key === Number(value));
        setProductOptions(selectedProduct ? selectedProduct.products : []);
        // setSelectedLink(''); 
    };

    const handleProductChange = (value) => {
        setSelectedLink(value);
    };

    const handleSubmit = () => {
        if (selectedLink) {
            navigate(selectedLink);
        }
    };

    return (
        <div className='Buynowform'>
            <Form layout="vertical">
                <div className="row form">
                    <div className="col-sm-12">
                        <h4 className="oswald">Products</h4>
                    </div>
                    <div className="col-sm-12">
                        <Form.Item>
                            <Select
                                placeholder='Select Category'
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                            >
                                {productData.map((category) => (
                                    <Select.Option key={category.key} value={String(category.key)}>
                                        {category.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    {productOptions && (
                        <div className="col-sm-12">
                            <Form.Item>
                                <Select 
                                    placeholder='Select Product' 
                                    onChange={handleProductChange} 
                                    value={selectedLink}
                                >
                                    {productOptions?.map((product) => (
                                        <Select.Option key={product.link} value={product.link}>
                                            {product.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )}

                    {/* {selectedLink && ( */}
                        <div className="col-sm-12">
                            <Button
                                className="buttons"
                                onClick={handleSubmit}
                                disabled={selectedLink ? false : true}
                            >
                                Next
                            </Button>
                        </div>
                    {/* )} */}
                </div>
            </Form>
        </div>
    );
};

export default NewForm;
