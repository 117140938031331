import React, { Fragment } from "react";
import ProductVideo from "../Components/Product.jsx/ProductVideo";
import { Helmet } from "react-helmet";
import ProductFinancials from "../Components/Product.jsx/ProductFinancials";

function ProductFinancial() {
    const metaData = {
        title: 'Islamic Insurance Products | Salaam Takaful Solutions',
        description: 'Explore a wide range of Islamic insurance products for individuals and corporations, including motor, health, marine, and financial takaful solutions.'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className="container-fluid faderight">
                <div className="row">
                    <ProductVideo />
                    <ProductFinancials />
                </div>
            </div>
        </Fragment>
    );
}

export default ProductFinancial;
