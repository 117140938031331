import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Shariahtext from './Shariahtext';
import Tabss from './Tabs';

function Shariahbanner() {
    return (
        <Fragment>
            <div className='col-sm-12 heads govbanner shariah'>
                <Fade up delay={500}><h2 className="oswald">Shariah Compliance</h2></Fade>
            </div>
            <div className='container tabss motor newShariaah' >
                <div className='row arrticleAndBlogs'>
                    <div className='col-sm-12 heads text mymaxheight shaaariaah'>
                        <Shariahtext />
                    </div>
                    <div className='col-sm-12 heads text mymaxheight'>
                        <Tabss />
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default Shariahbanner