import React, { Fragment } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Forplanned } from "../../Data/Data"
import { ForEmergency } from "../../Data/Data"
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';

function GropAccordian() {
    return (
        <Fragment>
            <Accordion>

                <Fade left delay={700}><Accordion.Item eventKey="0">
                    <Accordion.Header> Comprehensive Dread Disease Expense Benefit </Accordion.Header>
                    <Accordion.Body>
                        Recovering from a chronic disease has a significant financial and emotional impact on any family. Our Comprehensive Dread Disease Benefit help cover the costs associated with a member’s illness.
                    </Accordion.Body>
                </Accordion.Item></Fade>

                <Fade left delay={900}><Accordion.Item eventKey="1">
                    <Accordion.Header>Comprehensive Hospitalization Expense Benefit</Accordion.Header>
                    <Accordion.Body>
                        Comprehensive Hospitalization cover refers to the coverage against any treatment where you need hospitalization for a minimum of one (1) night, for specialized medical attention and care, before, during and after the treatment/surgery.
                    </Accordion.Body>
                </Accordion.Item></Fade>

                <Fade left delay={1100}><Accordion.Item eventKey="2">
                    <Accordion.Header>Maternity Expense Benefit</Accordion.Header>
                    <Accordion.Body>
                        Maternity coverage (optional) is one of the essential health benefits. Salaam Takaful Limited provides cover for pregnancy, labor, delivery, and newborn baby care at an affordable contribution.
                    </Accordion.Body>
                </Accordion.Item></Fade>

                <Fade left delay={1300}><Accordion.Item eventKey="3">
                    <Accordion.Header>Out-patient Expense Benefit</Accordion.Header>
                    <Accordion.Body>
                        Salaam Takaful Limited provides (optional) Out-patient medical benefit that covers consultations and treatments provided by a specialist or medical practitioner when an overnight stay in hospital is not necessary.
                    </Accordion.Body>
                </Accordion.Item></Fade>

                <Fade left delay={1500}><Accordion.Item eventKey="4">
                    <Accordion.Header>Standard Inclusions</Accordion.Header>
                    <Accordion.Body>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Daily Room & Board charges</td>
                                    <td><b>	Day Care Surgical Procedures</b></td>
                                </tr>

                                <tr>
                                    <td>Operation theatre and ICU/CCU charges</td>
                                    <td><b>Ventilators and Allied Services</b></td>
                                </tr>

                                <tr>
                                    <td>Physician, Surgeon and Anaesthetist’s fee</td>
                                    <td><b>Biopsy charges</b></td>
                                </tr>

                                <tr>
                                    <td>Diagnostic tests during hospitalization</td>
                                    <td><b>Surgical supplies</b></td>
                                </tr>

                                <tr>
                                    <td>Kidney Dialysis and organ Transplant</td>
                                    <td><b>	Accidental Emergency ER treatment</b></td>
                                </tr>

                                <tr>
                                    <td>Prescribed Medicines used during hospitalization</td>
                                    <td><b>Ambulance charges for emergencies</b></td>
                                </tr>

                                <tr>
                                    <td>Blood & Oxygen Supplies</td>
                                    <td><b>	24 hours pain management (injectables)</b></td>
                                </tr>

                                <tr>
                                    <td>Specialized Investigations (MRI, C.T. Scan, Thallium Scan, Endoscopy, Angioplasty, etc.)</td>
                                    <td><b>Pre & Post Hospitalization expenses (30 Days)</b></td>
                                </tr>

                                <tr>
                                    <td>Normal Delivery/C-Section/Multiple Deliveries/Complicated Delivery</td>
                                    <td><b>Post Natal (30 days)</b></td>
                                </tr>

                                <tr>
                                    <td>Prescribed Medicines used during hospitalization</td>
                                    <td><b>	NICU charges</b></td>
                                </tr>

                                <tr>
                                    <td>Pre Natal (9 months)*</td>
                                    <td><b>New Born Babies Vaccinations</b></td>
                                </tr>

                                <tr>
                                    <td>Well Baby charges</td>
                                    <td><b>Mother Stay during the Baby’s Hospitalization</b></td>
                                </tr>

                                <tr>
                                    <td>Conservative treatments</td>
                                    <td><b>	Circumcission</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </Accordion.Body>
                </Accordion.Item></Fade>


            </Accordion>

            <div className='col-sm-12 grophowtoclaim'>
                <Fade left delay={700}><h2 className='claimss'>How To Claim</h2></Fade>
            </div>
            <div className='col-sm-12 subheading'>
                <Fade left delay={700}><h2>5 STEPS FOR PLANNED ADMISSIONS</h2></Fade>

                <Fade left delay={900}> <Swiper slidesPerView={4} spaceBetween={30} pagination={{ clickable: true, }} modules={[Pagination]}
                    breakpoints={{ "@0.00": { slidesPerView: 1, spaceBetween: 10, }, "@0.75": { slidesPerView: 2, spaceBetween: 30, }, "@1.00": { slidesPerView: 4, spaceBetween: 30, }, "@1.50": { slidesPerView: 4, spaceBetween: 30, }, }}
                    className="mySwiper">
                    {Forplanned.map((data) => {
                        return (
                            <SwiperSlide key={data.key} >
                                <div className='steps emergency newws planned'>
                                    <h3>{data.heading}</h3>
                                    {data?.link ?
                                        <Link to={data?.link} target='_blank'><p>{data.text}</p></Link> :
                                        <p>{data.text}</p>
                                    }
                                    {/* <p>{data.text}</p> */}
                                </div>
                            </SwiperSlide>

                        )
                    })}

                </Swiper></Fade>
            </div>

            <div className='col-sm-12 padtop subheading'>
                <Fade left delay={1000}><h2 >5 STEPS FOR EMERGENCY ADMISSIONS</h2></Fade>

                <Fade left delay={1200}> <Swiper slidesPerView={4} spaceBetween={30} pagination={{ clickable: true, }} modules={[Pagination]}
                    breakpoints={{ "@0.00": { slidesPerView: 1, spaceBetween: 10, }, "@0.75": { slidesPerView: 2, spaceBetween: 30, }, "@1.00": { slidesPerView: 4, spaceBetween: 30, }, "@1.50": { slidesPerView: 4, spaceBetween: 30, }, }}
                    className="mySwiper">
                    {ForEmergency.map((data) => {
                        return (
                            <SwiperSlide key={data.key} >
                                <div className='steps newws emergency'>
                                    <h3>{data.heading}</h3>
                                    <p>{data.text}</p>
                                </div>
                            </SwiperSlide>

                        )
                    })}

                </Swiper></Fade>
            </div>
        </Fragment>
    )
}

export default GropAccordian