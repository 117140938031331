import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Traveltabs from "./Traveltabs";
import WhychoseTravel from "../WhychoseUs/WhychoseTravel";
import Productsnav from "./Productsnav";
import TravelMultiForm from "./TravelTakaful/TravelMultiForm";

function Salamtravel() {
  return (
    <Fragment>
      <Productsnav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner travels">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Travel Takaful</h2>
              <p className="cemter">
                <b>Planning to spend a quality vacation with your friends and family or be at a conference or a business meeting abroad?</b>
                <br/>Salaam Travel Takaful provides coverage for the following:
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Traveltabs />
          </div>

          <div className="col-sm-12 motorForm">
            <TravelMultiForm />
          </div>

          <div className="col-sm-12 heads text ">
            <WhychoseTravel />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Salamtravel;
