import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Financiallines from './Financiallines';


function Perposalbanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>

                    <div className='col-sm-12 heads govbanner perposal'>
                        <Fade up delay={500}><h2 className="oswald">Proposal Forms</h2></Fade>
                    </div>

                    <div className='col-sm-12 heads text mymaxheight'>
                        <Financiallines/>
                    </div>


                </div>
            </div>
        </Fragment>
    )
}

export default Perposalbanner