import React, { Fragment } from "react";
import Perposalbanner from "../../Components/PerposalForm/Perposalbanner";
import { Helmet } from "react-helmet";

function PerposalForm() {
  const metaData = {
    title: 'Proposal Forms | Islamic Insurance Proposal Forms ',
    description: 'Download and complete our Islamic insurance proposal forms for Salaam Takaful. Our forms make it easy to apply for coverage and get started with our Shariah-compliant insurance solutions'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Perposalbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default PerposalForm;
