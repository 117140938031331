import React, { Fragment } from 'react'
import SalamSafeDeposit from '../../Components/FinancialProductList/SalamSafeDeposit'
import { Helmet } from 'react-helmet'

function SalamDeposit() {
    const metaData = {
        title: 'Salaam Safe Deposit Box Takaful | Islamic Insurance for Secure Deposits',
        description: 'Protect your valuables with Salaam Safe Deposit Box Takaful. Our Shariah-compliant insurance covers loss or damage to items stored in safe deposit boxes'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamSafeDeposit />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamDeposit