import React, { Fragment } from 'react'
import SalamSehets from '../../Components/IndividualProductList/SalamSehat'
import { Helmet } from 'react-helmet'

function SalamSehat() {
    const metaData = {
        title: 'Salaam Sehat Takaful | Islamic Health Insurance Plans',
        description: 'Secure your health with Salaam Sehat Takaful. Our Shariah-compliant health insurance provides extensive coverage for medical expenses, ensuring peace of mind for you and your family'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamSehets />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamSehat