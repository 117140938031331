import React from "react";
import { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
// import { RxCross2 } from "react-icons/rx";
import Fade from "react-reveal/Fade";
import Downloads from "./Downloads";
import { Link, useNavigate } from "react-router-dom";
import Flip from "react-reveal/Flip";

function Sidemenu({ show, setshow }) {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="mobileMenu">
        <div>
          <div className="container menuBar">
            <span onClick={() => setshow(false)}>{/* <RxCross2 /> */}</span>

            <Accordion flush>
              <Fade right delay={500}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>The Company</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/about-us"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          About Us{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/whoweare"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        > Who We Are

                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/company-details"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Company Details
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/governance"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Governance{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/shariah"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Shariah Compliance{" "}
                        </Link>{" "}
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1000}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Products</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/Individual-Product"
                          onClick={() => {
                            const element = document.getElementById("individual");
                            if (element) {
                              element.scrollIntoView({ behavior: "smooth" });
                            }
                          }}
                        >
                          {" "}
                          Individual{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Corporate-Product"
                          onClick={() => {
                            const element = document.getElementById("corporate");
                            if (element) {
                              element.scrollIntoView({ behavior: "smooth" });
                            }
                          }}
                        >
                          Corporates{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Financial-Product"
                          onClick={() => {
                            const element = document.getElementById("financial");
                            if (element) {
                              element.scrollIntoView({ behavior: "smooth" });
                            }
                          }}
                        >
                          Financial Institutions
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1500}>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Media</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        {" "}
                        <Link
                          to="/news-and-announcements"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          News & Announcements{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/articles-and-blogs"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Articles & Blogs{" "}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/awards-&-recognition"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Awards and Recognition{" "}
                        </Link>
                      </li> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={2000}>
                <Accordion.Item eventKey="5">
                  <Accordion.Header> Investor Relations </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/financial-reports"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Financial Reports
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/financial-highlights"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Financial Highlights
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/annual-performance"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Annual Performance Review
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/nogm"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Notice of General Meeting{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/proxy-forms"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Proxy Forms{" "}
                        </Link>{" "}
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={2500}>

                <Link className="sidMenuLink"
                  to="/surplus-distribution"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Surplus Distribution{" "}
                </Link>
              </Fade>

              <Fade right delay={3000}>
                <Link className="sidMenuLink"
                  to="/esg"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Sustainablity
                </Link>
              </Fade>

              <Fade right delay={3500}>

                <Link className="sidMenuLink"
                  to="/life-at-salaam"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Life @ Salaam
                </Link>
              </Fade>

              <Fade right delay={4000}>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Downloads</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/proposal-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Proposal Forms
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/claim-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Claim Forms{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/complaint-resolution-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Complaint Resolution{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/un-encahsed-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Un-encashed Benefits/Cheque
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/policies-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Policies
                        </Link>{" "}
                      </li>
                      {/* <li>
                        {" "}
                        <Link
                          to="/proxy-forms"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Proxy Forms{" "}
                        </Link>{" "}
                      </li> */}
                      <li>
                        <Link
                          to="/hospital-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Panel Hospitals
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/discount-form"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Discount Center{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/marketing-collateral-forms"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Marketing Collateral{" "}
                        </Link>{" "}
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={4500}>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>E-Services</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/e-verify"
                          onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                          }}
                        >
                          {" "}
                          Certificate Verification
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/compain-box"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Complaint Box{" "}
                        </Link>{" "}
                      </li>
                      {/* <li>
                        <Link
                          to="/whistle-blowing"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Whistle Blowing Form{" "}
                        </Link>
                      </li> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>
              <Fade right delay={5000}>
                <Link className="sidMenuLink"
                  to="/contactus"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Contact us{" "}
                </Link>
              </Fade>
            </Accordion>
          </div>
        </div>
        <Flip top delay={3000}>
          <div className="backbtn">
            <button className="back buttons" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </Flip>
      </div>
    </Fragment>
  );
}

export default Sidemenu;
