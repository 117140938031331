import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function Corporatemenu() {
  return (
    <Fragment>
      <div className="row aboutmenu">
        <div className="col-sm-12 button">
          <Fade top delay={1000}>
            <Link
              to="/financialproduct/banker"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <h3
                data-aos="fade-up"
                data-aos-duration="4000"
                data-aos-easing="ease-out-cubic"
              >
                Salaam Bankers Blanket Takaful
              </h3>
            </Link>
          </Fade>

          <Fade right delay={1500}>
            <Link
              to="/financialproduct/plasticcard"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <h3
                data-aos="fade-left"
                data-aos-duration="4000"
                data-aos-easing="ease-out-cubic"
              >
                Salaam Plastic Card Takaful
              </h3>
            </Link>
          </Fade>

          <Fade left delay={2000}>
            <Link
              to="/financialproduct/deposit"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <h3
                data-aos="fade-right"
                data-aos-duration="4000"
                data-aos-easing="ease-out-cubic"
              >
                Salaam Safe Deposit Box Takaful Coverage
              </h3>
            </Link>
          </Fade>

          <Fade left delay={2500}>
            <Link
              to="/financialproduct/professional"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <h3
                data-aos="fade-right"
                data-aos-duration="4000"
                data-aos-easing="ease-out-cubic"
              >
                Salaam Professional Indeminity Takaful
              </h3>
            </Link>
          </Fade>
          <Fade left delay={2500}>
            <Link
              to="/financialproduct/computer"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <h3
                data-aos="fade-right"
                data-aos-duration="4000"
                data-aos-easing="ease-out-cubic"
              >
                Salaam Computer Crime Takaful
              </h3>
            </Link>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default Corporatemenu;
