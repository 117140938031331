import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postWhistleBlowingFormStart,
  postWhistleBlowingFormSuccess,
  postWhistleBlowingFormFailure,
} from "../reducers/postWhistleBlowingFormReducer";
import axios from "axios";

export const postWhistleBlowingForm = createAsyncThunk(
  "postWhistleBlowingForm",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postWhistleBlowingFormStart());
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/BlowingForm/PostWhistleBlowingForm`,
        values
      );

      const responseData = await response;

      thunkAPI.dispatch(postWhistleBlowingFormSuccess(values));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        postWhistleBlowingFormFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
