import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getPAPlanSlice = createSlice({
  name: "getPAPlan",
  initialState,
  reducers: {
    getPAPlanStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getPAPlanSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getPAPlanFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getPAPlanStart,
  getPAPlanSuccess,
  getPAPlanFailure,
  clearError,
  clearMessage,
} = getPAPlanSlice.actions;

export default getPAPlanSlice.reducer;
