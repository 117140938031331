import React, { Fragment } from 'react'

import FinancialBanner from '../Components/FinancialProduct/FinancialProductBanner'
import FinancialMenu from '../Components/FinancialProduct/FinancialProductMenu'


function FinancialProduct() {

    return (

        <Fragment>
            <div className='container faderight about'>
                <div className='row'>
                    <FinancialBanner />

                </div>
                <FinancialMenu />
            </div>

        </Fragment>

    )
}

export default FinancialProduct