import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import { News } from "../../Data/Data";
import { Button } from "antd";

function Newsmenu() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/news-and-announcements');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <Fragment>
      <div className="container homemenu newsevents">
        <div className="row">
          <div className="col-sm-12 explore news">
            <h2>
              <Fade delay={500}>News </Fade>{" "}
              <Flip top delay={800}>
                {" "}
                & Events{" "}
              </Flip>
            </h2>
          </div>

          <div className="col-sm-9  newsNEvenet">
            <Fade right delay={500}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={{ clickable: true }}
                modules={[Navigation]}
                breakpoints={{
                  "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                  "@0.75": { slidesPerView: 2, spaceBetween: 30 },
                  "@1.00": { slidesPerView: 3, spaceBetween: 30 },
                  "@1.50": { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="mySwiper newsSwiipper"
              >
                {News.slice(0, 8).map((data) => {
                  const trimmedContent = data?.content.split(" ").slice(0, 20).join(" ") + (data.content.split(" ").length > 20 ? "..." : "");
                  return (
                    <SwiperSlide>
                      <Link
                        to={`/news-and-announcements/${data.Key}`}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <div className="menusildes" key={data.key}>
                          <Flip top delay={500}>
                            {" "}
                            <img src={data.feature} alt="" />
                          </Flip>
                          <Fade up delay={500}>
                          <p>{trimmedContent}</p>
                          </Fade>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Fade>

            <Fade bottom delay={1000}>
              <Button onClick={handleNavigate} className="ReadMoreNews">View All News & Announcements Here</Button>
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Newsmenu;
