import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import WhyUsIndeminty from "../WhychoseUs/WhyUsIndeminty";
import FinProdNav from "./FinProdNav";
import ProfessionalTabs from "./ProfessionalTabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamProfessional = () => {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner ProfessionalIndeminityTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Professional Indemnity</h2>
              <p className="cemter">
                <b>
                  This policy provides cover against legal liability caused by
                  Errors and Omissions
                </b>
                <br />
                Includes the participant’s business predecessors, or any person
                employed by the participant.
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <ProfessionalTabs />
          </div>
        </div>
      </div>
      <div className="col-sm-12 motorForm">
        <CorporateAndFinancialForm title={"Professional Indeminity"} />
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12 heads text">
            <WhyUsIndeminty />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamProfessional;
