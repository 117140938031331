import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postHealthFourSlice = createSlice({
  name: "postHealthFour",
  initialState,
  reducers: {
    postHealthFourStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHealthFourSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
      console.log(action.payload, "<=====action.payload");
    },
    postHealthFourFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHealthFourStart,
  postHealthFourSuccess,
  postHealthFourFailure,
  clearError,
  clearMessage,
} = postHealthFourSlice.actions;

export default postHealthFourSlice.reducer;
