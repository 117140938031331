import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Hometab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/home.jpg`} />
                        <h4>Home Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/HomeCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>Our Salaam Home Takaful policy is specifically designed to safeguard your home and its valuable contents, (including jewellery and cash), from unexpected incidents.</p>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Coverages</h3>
                                <ul className='LiveStockList'>
                                    <li>Fire or explosion</li>
                                    <li>Earthquake damages</li>
                                    <li>Atmospheric disturbances including hail, hurricanes, storms, floods, and tornadoes</li>
                                    <li>Burglary and breakage from violent and forcible entry</li>
                                    <li>Riot and strikes</li>
                                    <li>Bursting of pipes or overflowing of water tanks</li>
                                    <li>Impact damage </li>
                                    <li>Aircraft damage and other aerial devices</li>
                                    <li>Malicious damage</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default Hometab