import React, { Fragment } from "react";


export default function Loader() {
  const Logo = `${process.env.REACT_APP_URL_ASSETS}Assets/SalaamLogo.gif`
  return (
    <Fragment>
      <div className="container-fluid">
        <div
          className="row preloader "
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div className="imagepre col-sm-12 ">
            <img
              src={Logo}
              data-aos="fade-down"
              data-aos-duration="2000"
              className="zoom-in-zoom-outa"
              alt=""
            />
            {/* <div data-aos="fade-down" data-aos-duration="2000">
              <h2>
                <Flip top delay={500}>
                  Welcome to{" "}
                </Flip>
                <Flip top delay={800}>
                  Salam General Takaful
                </Flip>
              </h2>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
