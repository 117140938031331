import React, { useEffect } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getHomeCity } from "../../../Redux/actions/getHomeCity";
import { getHomeOccupation } from "../../../Redux/actions/getHomeOccupation";

const HomeFormFour = ({ onFinishStep4, onFinishFailedFour }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const formthreeData = useSelector((state) => state.postHomeFormThree.data);
  const homeCityData = useSelector((state) => state.getHomeCity.data);
  const homeOccupationData = useSelector(
    (state) => state.getHomeOccupation.data
  );

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  useEffect(() => {
    dispatch(getHomeCity());
    dispatch(getHomeOccupation());
  }, [dispatch]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5"> Plan Name :{formthreeData?.Coverage}</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep4}
          onFinishFailed={onFinishFailedFour}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-sm-6">
              <Form.Item
                name="CNIC"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message:
                      "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item
                name="CNICEXP"
                rules={[
                  {
                    required: true,
                    message: "CNIC Expiry Date is required!",
                  },
                ]}
              >
                <DatePicker placeholder="CNIC Expiry" />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Gender"
                rules={[
                  {
                    required: true,
                    message: "Please select Gender!",
                  },
                ]}
              >
                <Select placeholder="Gender">
                  {genderData.map((item, i) => (
                    <Select.Option value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Occupation"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Occupation"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {homeOccupationData?.map((item, i) => (
                    <Option key={i} value={item?.Occupation_code}>
                      {item?.Occupation_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="DOB"
                rules={[
                  {
                    required: true,
                    message: "DOB is requied!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="DOB"
                  disabledDate={(current) => current && current > new Date()}
                />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item
                name="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item
                name="Contact"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message:
                      "Please enter a valid contact number in the format 03XX-XXXXXXX using only numbers",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="CITY"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {homeCityData?.map((item, i) => (
                    <Option key={i} value={item?.city_code}>
                      {item?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormFour;
