import { Button, Form } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment"; // or use dayjs if you're using that

import HealthFormOne from "./HealthFormOne";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { postHealthOne } from "../../../Redux/actions/postHealthOne";
import HealthFormTwo from "./HealthFormTwo";
import { postHealthTwo } from "../../../Redux/actions/postHealthTwo";
import HealthFormThree from "./HealthFormThree";
import { postHealthFamilyDetail } from "../../../Redux/actions/postHealthFamilyDetail";
import HealthFormFour from "./HealthFormFour";
import { postHealthThree } from "../../../Redux/actions/postHealthThree";
import HealthFormFive from "./HealthFormFive";
import { postHealthFour } from "../../../Redux/actions/postHealthFour";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postHealthOneReducer";
import { clearMessage as clearTwoMessage } from "../../../Redux/reducers/postHealthTwoReducer";
import { clearMessage as clearThreeMessage } from "../../../Redux/reducers/postHealthThreeReducer";
import { clearMessage as clearFourMessage } from "../../../Redux/reducers/postHealthFourReducer";
import HealthFormSix from "./HealthFormSix";
import { postHealthFive } from "../../../Redux/actions/postHealthFive";

const HealthForms = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedHMIDs, setSelectedHMIDs] = useState([]);
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});
  const [stepFiveData, setStepFiveData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [Type, setType] = useState();
  const [product_id, setProduct_id] = useState(4);
  const [DOB, setDOB] = useState("12-Jun-1984");

  const [beneficiaries, setBeneficiaries] = useState([
    { Name: "", CNIC: "", DOB: "", Gender: "", Relation: "" },
  ]);

  const formOneData = useSelector((state) => state.postHealthOne.data);
  const formOneError = useSelector((state) => state.postHealthOne.postError);
  const formTwoError = useSelector((state) => state.postHealthTwo.postError);
  const formThreeError = useSelector(
    (state) => state.postHealthThree.postError
  );
  const formFourError = useSelector((state) => state.postHealthFour.postError);
  const formFiveError = useSelector((state) => state.postHealthFive.postError);
  const formOnePostedData = useSelector(
    (state) => state.postHealthOne.postData
  );
  const formFamilyDetail = useSelector(
    (state) => state.postHealthFamilyDetail.data
  );

  const handleAddBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      { Name: "", CNIC: "", DOB: "", Gender: "", Relation: "" },
    ]);
  };

  const handleRemoveBeneficiary = (indexToRemove) => {
    const updatedBeneficiaries = beneficiaries.filter(
      (_, index) => index !== indexToRemove
    );
    setBeneficiaries(updatedBeneficiaries);
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    setStepOneData(values); // Save data for Step 1

    setProduct_id(values?.Product_ID);
    const resultAction = await dispatch(postHealthOne({ values }));
    if (postHealthOne.fulfilled.match(resultAction)) {
      // form.resetFields();
      handleNext();

      // toast.success("Form Submitted Successfully");
    }
  };

  const onFinishStep2 = async (values) => {
    setStepTwoData(values);
    setType(values.Type);

    if (values.Type == "I") {
      values.Unique_ID = formOneData[0]?.Unique_id;
      values.Unique_Detail_ID = formOneData[0]?.Unique_Detail_id;
      if (values.CNIC_Expiry_Date) {
        values.CNIC_Expiry_Date = moment(values.CNIC_Expiry_Date).format(
          "DD-MMM-YYYY"
        );
      }

      if (values.DOB) {
        values.DOB = moment(values.DOB).format("DD-MMM-YYYY");
        setDOB(values.DOB);
      }
      const resultAction = await dispatch(postHealthTwo({ values }));
      if (postHealthTwo.fulfilled.match(resultAction)) {
        setCurrentStep(4);
      }
    } else {
      await dispatch(postHealthFamilyDetail({ values }));
      handleNext();
    }
  };

  const onFinishStep3 = async (value) => {
    setStepThreeData(value);
    const familyList = beneficiaries.map((beneficiary, index) => ({
      Name: value[`Name-${index}`],
      CNIC: value[`CNIC-${index}`],
      Gender: value[`Gender-${index}`],
      DOB: value[`DOB-${index}`]?.format("DD-MMM-YYYY"),
      RELATION: value[`Relation-${index}`],
    }));

    const values = {
      CNIC: formFamilyDetail.CNIC,
      DOB: formFamilyDetail.DOB.format("DD-MMM-YYYY"),
      CNIC_Expiry_Date: formFamilyDetail.CNIC_Expiry_Date.format("DD-MMM-YYYY"),
      City: formFamilyDetail.City,
      Address: formFamilyDetail.Address,
      Gender: formFamilyDetail.Gender,
      Occupation: formFamilyDetail.Occupation,
      Type: formFamilyDetail.Type,
      Unique_ID: formOneData[0]?.Unique_id,
      Unique_Detail_ID: formOneData[0]?.Unique_Detail_id,
      FamilyList: familyList,
    };

    const resultAction = await dispatch(postHealthTwo({ values }));
    if (postHealthTwo.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep4 = async (Value) => {
    setStepFourData(Value);
    const values = {
      Unique_ID: formOneData && formOneData[0]?.Unique_id,
      MedicalList: selectedHMIDs,
    };

    const resultAction = await dispatch(postHealthThree({ values }));
    if (postHealthThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep5 = async (values) => {
    setStepFiveData(values);

    values.Unique_ID = formOneData && formOneData[0]?.Unique_id;
    values.Unique_Detail_ID = formOneData[0]?.Unique_Detail_id;

    const resultAction = await dispatch(postHealthFour({ values }));
    if (postHealthFour.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep6 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_id;
    values.Unique_Detail_ID = formOneData[0]?.Unique_Detail_id;

    const resultAction = await dispatch(postHealthFive({ values }));
    if (postHealthFive.fulfilled.match(resultAction)) {
      setCurrentStep(1);
      toast.success("Form Submitted Successfully");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFive = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedSix = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (formOneError) {
      toast.error(formOneError);
      dispatch(clearMessage());
    }
    if (formTwoError) {
      toast.error(formTwoError);
      dispatch(clearTwoMessage());
    }
    if (formThreeError) {
      toast.error(formThreeError);
      dispatch(clearThreeMessage());
    }
    if (formFourError) {
      toast.error(formFourError);
      dispatch(clearFourMessage());
    }
    if (formFiveError) {
      toast.error(formFiveError);
      dispatch(clearFourMessage());
    }
  }, [
    formOneError,
    formTwoError,
    formThreeError,
    formFourError,
    formFiveError,
  ]);
  console.log(stepTwoData, "<==========stepTwoData");
  useEffect(() => {
    if (currentStep === 1 && stepOneData) {
      form.setFieldsValue(stepOneData);
    }
    if (currentStep === 2 && stepTwoData) {
      form.setFieldsValue(stepTwoData);
    }
    if (currentStep === 3 && stepThreeData) {
      form.setFieldsValue(stepThreeData);
    }
    if (currentStep === 4 && stepFourData) {
      form.setFieldsValue(stepFourData);
    }
    if (currentStep === 5 && stepFiveData) {
      form.setFieldsValue(stepFiveData);
    }
  }, [currentStep, form, stepOneData, stepTwoData, stepThreeData]);

  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div
          className="row formTakafulll"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/SehatFormOne.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Form 1 */}

          {currentStep === 1 && (
            <>
              <HealthFormOne
                onFinishStep1={onFinishStep1}
                onFinishFailed={onFinishFailed}
                form={form}
              />
            </>
          )}

          {/* Form 2 */}

          {currentStep === 2 && (
            <HealthFormTwo
              form={form}
              handlePrev={handlePrev}
              onFinishFailedTwo={onFinishFailedTwo}
              onFinishStep2={onFinishStep2}
            />
          )}

          {/* Form 3 */}

          {currentStep === 3 && (
            <HealthFormThree
              handleRemoveBeneficiary={handleRemoveBeneficiary}
              handleAddBeneficiary={handleAddBeneficiary}
              beneficiaries={beneficiaries}
              onFinishFailedThree={onFinishFailedThree}
              onFinishStep3={onFinishStep3}
              handlePrev={handlePrev}
              form={form}
            />
          )}

          {/* Form 4 */}

          {currentStep === 4 && (
            <HealthFormFour
              formOnePostedData={formOnePostedData}
              onFinishFailedFour={onFinishFailedFour}
              onFinishStep4={onFinishStep4}
              selectedHMIDs={selectedHMIDs}
              setSelectedHMIDs={setSelectedHMIDs}
              form={form}
              handlePrev={handlePrev}
            />
          )}

          {/* Form 5 */}

          {currentStep === 5 && (
            <>
              <HealthFormFive
                onFinishStep5={onFinishStep5}
                onFinishFailedFive={onFinishFailedFive}
                DOB={DOB}
                product_id={product_id}
                form={form}
              />
            </>
          )}

          {/* Form 6 */}

          {currentStep === 6 && (
            <>
              <HealthFormSix
                formOneData={formOneData}
                onFinishStep6={onFinishStep6}
                onFinishFailedSix={onFinishFailedSix}
                form={form}
                handlePrev={handlePrev}
              />
            </>
          )}

          <div className="col-sm-4 mForm"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default HealthForms;
