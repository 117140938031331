import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
  postData: {},
};

const postHealthOneSlice = createSlice({
  name: "postHealthOne",
  initialState,
  reducers: {
    postHealthOneStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHealthOneSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postHealthOneDataSuccess(state, action) {
      state.postData = action.payload;
    },
    postHealthOneFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHealthOneStart,
  postHealthOneSuccess,
  postHealthOneDataSuccess,
  postHealthOneFailure,
  clearError,
  clearMessage,
} = postHealthOneSlice.actions;

export default postHealthOneSlice.reducer;
