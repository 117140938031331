import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";

function Homemenu() {
  return (
    <Fragment>
      <div className="container homemenu">
        <div className="row">
          <div className="col-sm-12 explore">
          </div>

          <div className="col-sm-4 side">
            <h3>
              <Flip top delay={500}>
                {" "}
                Largest General Takaful Operator in Pakistan{" "}
              </Flip>
            </h3>
            <br />
            <ul className="newUl">
              <Flip top delay={1200}>
                <li>
                  <img
                    src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`}
                    alt="asd"
                  />
                  First Islamic Insurtech in Pakistan
                </li>
              </Flip>
              <Flip top delay={1400}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`}
                    alt="asd"
                  />
                  Truly ESG driven company in Pakistan
                </li>
              </Flip>
              <Flip top delay={1600}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`}
                    alt="asd"
                  />
                  A++ PACRA rated and ISO 9001:2015 certified
                </li>
              </Flip>
            </ul>
            <br />
            <Fade left delay={1800}>
              <Link to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/SalaamTakafulLimitedCorporateProfile.pdf`} className=" buy" target="_blank">
              Salaam Takaful Corporate Profile
              </Link>
            </Fade>
          </div>

          <div className="col-sm-8 menus">
            <Fade right delay={500}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                breakpoints={{
                  "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                  "@0.75": { slidesPerView: 1, spaceBetween: 30 },
                  "@1.00": { slidesPerView: 3, spaceBetween: 30 },
                  "@1.50": { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Link
                    to="/whoweare"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="menusildes hoomeePage">
                      <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/video/WhoWeAre.gif`} />
                      <h3>Who We Are</h3>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to="/company-details"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="menusildes hoomeePage">
                      <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/video/CompanyDetails.gif`} alt="asd" />
                      <h3>Company Details</h3>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/governance"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="menusildes hoomeePage">
                      <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/video/Governance.gif`} />
                      <h3>Governance</h3>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/shariah"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="menusildes hoomeePage">
                      <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/video/Shahriah.gif`} />
                      <h3>Shariah</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Homemenu;