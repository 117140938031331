import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Articles } from "../../Data/Data";
import Fade from "react-reveal/Fade";
import RecentAnnouncement from "./RecentAnnouncement";
import { Helmet } from "react-helmet";

function SingleAnnouncement() {
  const { Key } = useParams();
  console.log(Key);

  const newsItem = Articles.find((data) => data.Key.toString() === Key);

  const metaData = {
    title: 'Articles And Blogs | Insightful Articles and Blog Posts',
    description: 'Explore our articles and blogs for insightful information and updates related to Salaam Takaful. Our content covers a range of topics including industry trends, tips, and company insights'
  }


  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row">
          <div className="container governancemain motor">
            <div className="row ">
              <div className="col-sm-12 heads govbanner blogs">
                <Fade up delay={500}>
                  <h2 className="oswald">ARTICLES AND BLOGS</h2>
                </Fade>
              </div>
            </div>

            <div className="row singleblog arrticleAndBlogs">
              <div className="col-sm-9 singlenews">
                {newsItem && (
                  <div className="container-fluid news" key={newsItem.heading}>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 heads">
                          <Fade left delay={500}>
                            <img src={newsItem.feature} alt="img" />
                          </Fade>
                          <Fade left delay={500}>
                            <h2 className="oswald">{newsItem.heading}</h2>
                          </Fade>
                          {/* {newsItem?.date ? 
                          <Fade left delay={500}>
                            <p className="date">
                              <b>Date</b>: {newsItem?.date}
                            </p>
                          </Fade>
                          : <></>
                          } */}
                        </div>
                        <div className="col-sm-12 heads text newsheight">
                          <p>{newsItem?.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-3">
                <RecentAnnouncement />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleAnnouncement;
