import React, { Fragment } from "react";
import Govbanner from "../Components/Governance/Govbanner";
import { Helmet } from "react-helmet";

function Governance() {
  const metaData = {
    title: 'Governance | Salaam Takaful Governance Structure',
    description: 'Learn about the board of directors at Salaam Takaful'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Govbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Governance;
