import React, { Fragment, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { EngineeringTakaful } from "../../Data/Data";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
function Engineeringtable() {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0); // Initial active slide index is set to 0

    const handleSlideClick = (swiper) => {
        const activeIndex = swiper.clickedIndex;
        swiper.slideTo(activeIndex);
        setActiveSlideIndex(activeIndex); // Update the active slide index in the state
    };
    return (
        <Fragment>
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills" className="">
                            <Swiper
                                slidesPerView={4}
                                pagination={{ clickable: true }}
                                spaceBetween={20}
                                updateOnWindowResize={true}
                                navigation={{ clickable: true }}
                                modules={[Navigation]}
                                onSwiper={(swiper) => {
                                swiper.on("click", handleSlideClick);
                                }}
                                breakpoints={{
                                    "@0.00": { slidesPerView: 1, spaceBetween: 10 }, // For very small screens
                                    "@0.50": { slidesPerView: 1, spaceBetween: 30 }, // For small screens
                                    "@0.75": { slidesPerView: 2, spaceBetween: 20 }, // For foldable phones and iPads
                                    "@1.00": { slidesPerView: 3, spaceBetween: 30 }, // For medium screens
                                    "@1.50": { slidesPerView: 4, spaceBetween: 40 }, // For larger screens
                                }}
                                className="mySwiper motorSwiiperrr"
                                loop={true}
                            >
                                {EngineeringTakaful.map((item, index) => {
                                return (
                                    <SwiperSlide key={index} className={index === activeSlideIndex ? "swiper-slide-active" : ""}>
                                    <Nav.Item>
                                        <Fade down delay={500}>
                                        <Nav.Link eventKey={item.key}>
                                            <img src={item.image} alt="tabs" />
                                            <h4>{item.mainheading}</h4>
                                        </Nav.Link>
                                        </Fade>
                                    </Nav.Item>
                                    </SwiperSlide>
                                );
                                })}
                            </Swiper>
                        </Nav>
                    </Col>

                    <Col sm={12}>
                        <Tab.Content>
                        {EngineeringTakaful.map((item, index) => {
                            return (
                            <Tab.Pane
                                eventKey={item.key}
                                key={index}
                                className={
                                index === activeSlideIndex ? "tab-pane-active" : ""
                                }
                            >
                                <div
                                className="row tabs"
                                style={{ backgroundImage: `url(${item.coverImage})` }}
                                >
                                <Fade up delay={500}>
                                    <div className="col-sm-4">
                                    <div className="mycontents">
                                        <div
                                        className="row"
                                        data-aos="fade-right"
                                        data-aos-duration="4000"
                                        data-aos-easing="ease-out-cubic"
                                        >
                                        <div className="col-sm-12">
                                            <p
                                            data-aos="fade-right"
                                            data-aos-duration="4000"
                                            data-aos-easing="ease-out-cubic"
                                            >
                                            {item?.content}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </Fade>

                                <Fade up delay={500}>
                                    <div className="col-sm-4">
                                    <div
                                        className="list-detail"
                                        data-aos="fade-right"
                                        data-aos-duration="4000"
                                        data-aos-easing="ease-out-cubic"
                                    >
                                        {item?.heading1 ? (
                                        <h3
                                            data-aos="fade-right"
                                            data-aos-duration="4000"
                                            data-aos-easing="ease-out-cubic"
                                        >
                                            {item?.heading1}
                                        </h3>
                                        ) : (
                                        <></>
                                        )}
                                        <ul>
                                        {item?.Coverages?.map((datas, idx) => (
                                            <Fragment key={idx}>
                                            {datas.li ? <li>{datas.li}</li> : <></>}
                                            </Fragment>
                                        ))}
                                        </ul>
                                    </div>
                                    </div>
                                </Fade>

                                <Fade up delay={500}>
                                    <div className="col-sm-4">
                                    <div
                                        className="list-detail"
                                        data-aos="fade-right"
                                        data-aos-duration="4000"
                                        data-aos-easing="ease-out-cubic"
                                    >
                                        {item?.heading2 ? (
                                        <h3
                                            data-aos="fade-right"
                                            data-aos-duration="4000"
                                            data-aos-easing="ease-out-cubic"
                                        >
                                            {item?.heading2}
                                        </h3>
                                        ) : (
                                        <></>
                                        )}
                                        <ul>
                                        {item?.OptionalBenefits?.map((data, idx) => (
                                            <Fragment key={idx}>
                                            {data.li ? <li>{data.li}</li> : null}{" "}
                                            </Fragment>
                                        ))}
                                        </ul>
                                    </div>
                                    </div>
                                </Fade>

                                {/* <Fade up delay={500}>
                                    <div className="col-sm-4">
                                    <div
                                        className="list-detail"
                                        data-aos="fade-right"
                                        data-aos-duration="4000"
                                        data-aos-easing="ease-out-cubic"
                                    >
                                        {item?.heading3 ? (
                                        <h3
                                            data-aos="fade-right"
                                            data-aos-duration="4000"
                                            data-aos-easing="ease-out-cubic"
                                        >
                                            {item.heading3}
                                        </h3>
                                        ) : (
                                        <></>
                                        )}
                                        <ul>
                                        {item.Condition.map((dataa, idx) => (
                                            <Fragment key={idx}>
                                            {dataa.li ? <li>{dataa.li}</li> : null}{" "}
                                            </Fragment>
                                        ))}
                                        </ul>
                                    </div>
                                    </div>
                                </Fade> */}
                                </div>
                            </Tab.Pane>
                            );
                        })}
                        </Tab.Content>
                    </Col>
                    </Row>
            </Tab.Container>
            {/* <div className='col-sm-12 takafultable'>
                <table data-aos="fade-right" data-aos-duration="4000">
                    <thead>
                        <tr>
                            <th>Products</th>
                            <th>Our solution</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Burglary Takaful</td>
                            <td>This policy provides coverage of loss and/or damage to the participant’s property whilst within the business premises against the risks of Theft/Burglary consequent upon forcible and violent entry. The policy also covers damage to the premises resulting from attempted Theft/Burglary. The policy is however issued only in conjunction with the fire and allied perils policy.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Fidelity Takaful</td>
                            <td>This policy is designed to provide coverage against acts of the risk of misappropriation or embezzlement committed by the permanent and authorized employees in the course of their employment for the participant on permanent payroll.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Plate Glass Takaful</td>
                            <td>Coverage under this policy includes breakage (which does not include damage by scratching) of any glass installed at the premises specified. The policy also covers any writing or ornamentation on glass if specifically described for coverage.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Third party Liability Takaful</td>
                            <td>The policy covers the legal liability of the participant in respect of death &/or bodily injury and/or loss or damage to the third party and/or its property (other than the participant &/or his employees) as a direct result of an accident arising at the participant’s specified premises.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Money Takaful</td>
                            <td>The policy covers loss or destruction of or damage to money whilst in transit between various specified locations. The policy can also be extended to cover money whilst in premises during and after working hours and in residence overnight. In addition, it may also cover loss of or damage to the safe or strong room because of theft or attempted theft or robbery.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Workmen Compensation Takaful</td>
                            <td>This policy is designed to indemnify the participant if at any time during the period of coverage of Takaful, any employee in the Participant’s immediate service shall sustain personal injury by accident or disease arising out of and in the course of his employment by the participant in the business and if the participant shall be liable to pay compensation for such injury.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Product Liability Takaful</td>
                            <td>This policy provides coverage for claims made against the participant by the third party arising out of products manufactured/ sold by the participant.</td>
                        </tr>
                        <tr data-aos="fade-right" data-aos-duration="4000">
                            <td>Salaam Directors’ & Officers Liability</td>
                            <td>This policy provides coverage to directors and officers for claims made against them while serving on a board of directors and/or as an officer for any actual or alleged act or omission, error, misstatement, misleading statement, neglect or breach of duty by the covered person in the discharge of his/her duties. </td>
                        </tr>

                    </tbody>
                </table>
            </div> */}
        </Fragment>
    )
}

export default Engineeringtable