import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postHealthThreeSlice = createSlice({
  name: "postHealthThree",
  initialState,
  reducers: {
    postHealthThreeStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHealthThreeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;

    },
    postHealthThreeFailure(state, action) {
      state.loading = false;
      console.log(action.payload, "<===action.payload");
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHealthThreeStart,
  postHealthThreeSuccess,
  postHealthThreeFailure,
  clearError,
  clearMessage,
} = postHealthThreeSlice.actions;

export default postHealthThreeSlice.reducer;
