import React, { Fragment } from 'react'
import Salammarine from '../../Components/CorporateProductList/Salammarine'
import { Helmet } from 'react-helmet'

function SalamMarine() {
  const metaData = {
    title: 'Salaam Marine Takaful | Islamic Marine Insurance for Safe Shipping',
    description: 'Ensure the safety of your maritime operations with Salaam Marine Takaful. Our Shariah-compliant marine insurance provides protection for cargo, vessels, and liabilities at sea'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight'>
        <div className='row arrticleAndBlogs'>
         <Salammarine/>
        </div>
      </div>
    </Fragment>
  )
}

export default SalamMarine
