import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorMakeSlice = createSlice({
  name: "getMotorMake",
  initialState,
  reducers: {
    getMotorMakeStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorMakeSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorMakeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorMakeStart,
  getMotorMakeSuccess,
  getMotorMakeFailure,
  clearError,
  clearMessage,
} = getMotorMakeSlice.actions;

export default getMotorMakeSlice.reducer;
