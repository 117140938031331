import React, { Fragment } from "react";
import Whobanner from "../Components/Whoweare/Whobanner";
import { Helmet } from "react-helmet";

function Whoweare() {
  const metaData = {
    title: 'Who We Are | About Salaam Takaful',
    description: 'Discover who we are at Salaam Takaful. Learn about our mission, vision, values, and commitment to providing Shariah-compliant insurance solutions.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row">
          <Whobanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Whoweare;
