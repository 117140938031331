import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorCCSlice = createSlice({
  name: "getMotorCC",
  initialState,
  reducers: {
    getMotorCCStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorCCSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorCCFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorCCStart,
  getMotorCCSuccess,
  getMotorCCFailure,
  clearError,
  clearMessage,
} = getMotorCCSlice.actions;

export default getMotorCCSlice.reducer;
