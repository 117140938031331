
import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import ProxyForm from './ProxyForm';


function ProxyBanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>

                <div className='col-sm-12 heads govbanner proxy'>
                        <Fade up delay={500}><h2 className="oswald">Proxy Forms</h2></Fade>
                    </div>

                    <div className='col-sm-12 heads text mymaxheight'>
                       <ProxyForm/>
                    </div>

                
                </div>
            </div>
        </Fragment>
    )
}

export default ProxyBanner