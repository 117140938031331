import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postFormThreeStart,
  postFormThreeSuccess,
  postFormThreeFailure,
} from "../reducers/postFormThreeReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const postFormThree = createAsyncThunk(
  "postFormThree",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postFormThreeStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Motor/SaveMotorPolicyThirdPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postFormThreeSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      if (error.response.data.Message) {
        thunkAPI.dispatch(postFormThreeFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postFormThreeFailure(error.response.data.status));
      }
      throw error;
    }
  }
);
