import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTravelTypeStart,
  getTravelTypeSuccess,
  getTravelTypeFailure,
} from "../reducers/getTravelTypeReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getTravelType = createAsyncThunk(
  "getTravelType",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getTravelTypeStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Travel/GetTypes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getTravelTypeSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getTravelTypeFailure(error.response.data.Message));

      throw error;
    }
  }
);
