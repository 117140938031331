import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Whychosehome() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>
      <div className='row whychosesalaam'>
        <div className='col-sm-12 chose'>
          <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
        </div>
        <div className='col-sm-6 why' data-aos="fade-right" data-aos-duration="4000">
          <h3>Fastest Claim Settlement Process</h3>
          <p>With our highly active team of qualified professionals, your claim is processed in a quick and efficient manner, saving you from any hassle.</p>
        </div>

        <div className='col-sm-6 why' data-aos="fade-left" data-aos-duration="4000">

          <h3>Shariah Compliant Solution</h3>
          <p>Our shariah compliance function is a key element of company’s structure, carrying the responsibility of ensuring that all products and services offered by Salaam Takaful are fully compliant with the principles of shariah law.</p>
        </div>
      </div>


      <Fade up delay={500}>
        <div className='row claimprocedure personalAccident'>
          <div className='col-sm-12'>
            <Fade left delay={700}><h2>Coverages</h2></Fade>
            <ul>
              <Fade up delay={800}><li><img src={Check} alt='Check' /> The risk of fire</li></Fade>
              <Fade up delay={900}> <li><img src={Check} alt='Check' />   Earthquake</li></Fade>
              <Fade up delay={1000}> <li><img src={Check} alt='Check' /> Explosion, lightning, thunderbolt, flood and typhoon</li></Fade>
              <Fade up delay={1100}><li><img src={Check} alt='Check' />  Burglary & breakage</li></Fade>
              <Fade up delay={1200}><li><img src={Check} alt='Check' /> Bursting of pipes </li></Fade>
              <Fade up delay={1300}>  <li><img src={Check} alt='Check' /> Aircraft damage</li></Fade>
              <Fade up delay={1500}>  <li><img src={Check} alt='Check' /> Damage to mirrors etc.</li></Fade>
            </ul>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default Whychosehome

