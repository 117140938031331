import React, { Fragment } from "react";
import NogmBanner from "../../Components/NOGM/NogmBanner";
import { Helmet } from "react-helmet";

function NOGM() {
  const metaData = {
    title: 'Notices of General Meetings | Upcoming General Meetings',
    description: 'Find notices of upcoming general meetings for Salaam Takaful. Stay informed about meeting schedules, agendas, and important decisions affecting our stakeholders'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <NogmBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default NOGM;
