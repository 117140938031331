
import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function WhyChoseLivestock() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>

      <div className='row'>

        <div className='col-sm-12 coverages' data-aos="fade-down" data-aos-duration="4000">



          <Fade up delay={500}>
            <div className='row claimprocedure'>
              <div className='col-sm-6'>
                <Fade left delay={700}><h2>Death Due To</h2></Fade>
                <ul>
                  <Fade up delay={800}><li><img src={Check} alt='Check' /> Fire</li></Fade>
                  <Fade up delay={900}> <li><img src={Check} alt='Check' />   Flood</li></Fade>
                  <Fade up delay={1000}> <li><img src={Check} alt='Check' /> Risk of Calving</li></Fade>
                  <Fade up delay={1100}><li><img src={Check} alt='Check' />  Snake Bite</li></Fade>
                  <Fade up delay={1200}><li><img src={Check} alt='Check' /> Tornado and Cyclone </li></Fade>
                  <Fade up delay={1300}>  <li><img src={Check} alt='Check' /> Lightning and Electrocution</li></Fade>
                  <Fade up delay={1500}>  <li><img src={Check} alt='Check' /> Diseases</li></Fade>
                  <Fade up delay={1500}>  <li><img src={Check} alt='Check' /> Theft</li></Fade>
                  <Fade up delay={1500}>  <li><img src={Check} alt='Check' /> Loss of udders/ Teats due to any disease</li></Fade>
                </ul>
              </div>

              <div className='col-sm-6'>
                <Fade left delay={700}><h2>Value Additions </h2></Fade>
                <ul>
                  <Fade up delay={800}><li><img src={Check} alt='Check' /> Virtual Veterinary Consultation for Livestock</li></Fade>
                  <Fade up delay={900}> <li><img src={Check} alt='Check' />   Virtual Doctor Consultation for Farmers</li></Fade>
                  <Fade up delay={1000}> <li><img src={Check} alt='Check' /> Heat Sensors for Livestock for Early Illness Diagnosis</li></Fade>
                  <Fade up delay={1100}><li><img src={Check} alt='Check' /> Ultrasound Facility for Livestock</li></Fade>
                  <Fade up delay={1200}><li><img src={Check} alt='Check' /> Geotagging </li></Fade>
                </ul>
              </div>
            </div>
          </Fade>


        </div>




      </div>



    </Fragment>
  )
}

export default WhyChoseLivestock