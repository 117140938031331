import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMotorProductStart,
  getMotorProductSuccess,
  getMotorProductFailure,
} from "../reducers/getMotorProductReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getToken } from "./getToken";

export const getMotorProduct = createAsyncThunk(
  "getMotorProduct",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMotorProductStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Motor/GetProduct`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getMotorProductSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getMotorProductFailure(error.response.data.Message));
      toast.error(error.response.data.status);

      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
