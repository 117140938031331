import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorCitySlice = createSlice({
  name: "getMotorCity",
  initialState,
  reducers: {
    getMotorCityStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorCitySuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorCityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorCityStart,
  getMotorCitySuccess,
  getMotorCityFailure,
  clearError,
  clearMessage,
} = getMotorCitySlice.actions;

export default getMotorCitySlice.reducer;
