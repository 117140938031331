import React, { Fragment } from 'react'
import SalamHomes from '../../Components/IndividualProductList/SalamHome'
import { Helmet } from 'react-helmet'

function SalamHome() {
    const metaData = {
        title: 'Salaam Home Takaful | Islamic Home Insurance for Complete Protection',
        description: 'Safeguard your home with Salaam Home Takaful. Enjoy comprehensive coverage for property damage, theft, and natural disasters, all in line with Shariah principles'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight '>
                <div className='row arrticleAndBlogs'>
                    <SalamHomes />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamHome