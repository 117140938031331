import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postFormThreeSlice = createSlice({
  name: "postFormThree",
  initialState,
  reducers: {
    postFormThreeStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postFormThreeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postFormThreeFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postFormThreeStart,
  postFormThreeSuccess,
  postFormThreeFailure,
  clearMessage,
} = postFormThreeSlice.actions;

export default postFormThreeSlice.reducer;
