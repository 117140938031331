import React, { Fragment } from "react";
import ComplainBanner from "../Components/Complainbox/ComplainBanner";
import { Helmet } from "react-helmet";

function Complain() {
  const metaData = {
    title: 'Complaint Box | Submit Your Complaints',
    description: 'Use our complaint box to submit your feedback and complaints regarding Salaam Takaful services. We are committed to addressing your concerns and improving our service quality'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <ComplainBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Complain;