import React, { Fragment } from "react";
import EsgBnanner from "../Components/ESG/EsgBnanner";
import EsgVideo from "../Components/ESG/EsgVideo";
import { Helmet } from "react-helmet";

function SustainabilityESG() {
  const metaData = {
    title: 'Sustainability Initiatives | Salaam Takaful’s Commitment',
    description: "Discover Salaam Takaful's commitment to sustainability through eco-friendly practices, social responsibility, and ethical governance."
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <EsgVideo />
          <EsgBnanner />
        </div>
      </div>
    </Fragment>
  );
}

export default SustainabilityESG;
