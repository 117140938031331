import React from "react";
import { Fragment } from "react";
import Aboutbanner from "../Components/AboutUs/Aboutbanner";
import Homemenu from "../Components/Home/Homemenu";
import { Helmet } from "react-helmet";

function Aboutus() {

  const metaData = {
    title: 'About Salaam Takaful | Leading Islamic Insurance Provider',
    description: 'Learn more about Salaam Takaful, a leading provider of Shariah-compliant insurance solutions. Discover our values, governance, and commitment to Islamic principles.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Aboutbanner />
          <Homemenu />
        </div>
      </div>
    </Fragment>
  );
}

export default Aboutus;