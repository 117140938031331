import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postFormTwoSlice = createSlice({
  name: "postFormTwo",
  initialState,
  reducers: {
    postFormTwoStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postFormTwoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postFormTwoFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postFormTwoStart,
  postFormTwoSuccess,
  postFormTwoFailure,
  clearMessage,
} = postFormTwoSlice.actions;

export default postFormTwoSlice.reducer;
