import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getHomeOwnerSlice = createSlice({
  name: "getHomeOwner",
  initialState,
  reducers: {
    getHomeOwnerStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getHomeOwnerSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getHomeOwnerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getHomeOwnerStart,
  getHomeOwnerSuccess,
  getHomeOwnerFailure,
  clearError,
  clearMessage,
} = getHomeOwnerSlice.actions;

export default getHomeOwnerSlice.reducer;
