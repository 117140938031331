import React from 'react'
import { Fragment } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { Forplanned } from "../../Data/Data"
import { ForEmergency } from "../../Data/Data"
import Fade from "react-reveal/Fade";
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

function Whychosesehat() {
    return (
        <Fragment>
            <div className='row whychosesalaam'>
                <div className='col-sm-12 chose'>
                    <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
                </div>

                <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                    <h3>Salaam Application</h3>
                    <p>To bring more ease and convenience to our participants, we are offering some of the world's best hi-tech solutions in one app, so that you can stay in control no matter where you go.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-down" data-aos-duration="4000">

                    <h3>Free Online OPD consultation via App</h3>
                    <p>Now with your Salaam Application, get free online OPD consultation from a panel of qualified doctors.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>Discount centers</h3>
                    <p>With your Salaam Takaful Health Card, get discounts on lab test, OPD consultation and pharmacies all across Pakistan.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>400+ Panel Hospital Pan Pakistan</h3>
                    <p>Salaam Takaful Limited allows customers to access cashless hospitalization on over 400+ medical institutions, making healthcare facilities available for everyone.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>Accessible at non-panel hospitals</h3>
                    <p>In case of emergency admissions, our team of qualified doctors are just a call away.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>Fastest Claim Settlement Process</h3>
                    <p>With our team of qualified professional, your claim is processed in a quick and efficient manner, saving you from any hassle</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>Shariah based solution</h3>
                    <p>Our shariah compliance function is a key element of company’s structure, carrying the responsibility of ensuring that all products and services offered by Salaam Takaful Limited are fully compliant with the principles of shariah law.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>24/7 Dedicated Medical Hotline numbers</h3>
                    <p>Salaam Takaful Limited provides a 24/7 hotline for effective and immediate communication and consultation to all its customers.</p>
                </div>
            </div>

            <Fade up delay={500}>
                <div className='row claimprocedure personalAccident'>
                    <div className='col-sm-12'>
                        <Fade left delay={700}><h2 className='claimss'>How To Claim</h2></Fade>
                    </div>
                    <div className='col-sm-12 subheading'>
                        <Fade left delay={700}><h2>FOR PLANNED ADMISSIONS</h2></Fade>

                        <Fade left delay={900}> 
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                pagination={{ clickable: true, }} modules={[Pagination]}
                                breakpoints={{ 0: { slidesPerView: 1, spaceBetween: 10, }, 575: { slidesPerView: 2, spaceBetween: 30, }, 720: { slidesPerView: 3, spaceBetween: 30, }, 1280: { slidesPerView: 4, spaceBetween: 30, }, "@1.50": { slidesPerView: 4, spaceBetween: 30, }, }}
                                className="mySwiper">
                            {Forplanned.map((data) => {
                                return (
                                    <SwiperSlide key={data.key} >
                                        <div className='steps emergency newws planned'>
                                            <h3>{data.heading}</h3>
                                            {data?.link ?
                                                <Link to={data?.link} target='_blank'><p>{data.text}</p></Link> :
                                                <p>{data.text}</p>
                                            }
                                        </div>
                                    </SwiperSlide>

                                )
                            })}

                        </Swiper></Fade>
                    </div>

                    <div className='col-sm-12 padtop subheading'>
                        <Fade left delay={1000}><h2 >EMERGENCY ADMISSIONS</h2></Fade>

                        <Fade left delay={1200}>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                pagination={{ clickable: true, }}
                                modules={[Pagination]}
                                breakpoints={{ 0: { slidesPerView: 1, spaceBetween: 10, }, 575: { slidesPerView: 2, spaceBetween: 30, }, 720: { slidesPerView: 3, spaceBetween: 30, }, 1280: { slidesPerView: 4, spaceBetween: 30, }, "@1.50": { slidesPerView: 4, spaceBetween: 30, }, }}
                                className="mySwiper"
                            >
                                {ForEmergency.map((data) => {
                                    return (
                                        <SwiperSlide key={data.key} >
                                            <div className='steps emergency newws'>
                                                <h3>{data.heading}</h3>
                                                <p>{data.text}</p>
                                            </div>
                                        </SwiperSlide>

                                    )
                                })}

                            </Swiper></Fade>
                    </div>
                </div>
            </Fade>

        </Fragment>
    )
}

export default Whychosesehat


