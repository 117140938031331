import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import Fade from "react-reveal/Fade";
import { postComplainForm } from "../../Redux/actions/postComplainForm";
import { useDispatch } from "react-redux";

function Complainform() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  /* eslint-disable no-template-curly-in-string */

  const Phone = `${process.env.REACT_APP_URL_ASSETS}Assets/img/phones.png`;

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async (values) => {
    setSubmitting(true);
    await dispatch(postComplainForm(values));
    message.success("Form submitted successfully.");
    form.resetFields();
    setSubmitting(false);

    // window.Email.send({
    //   Secure: true,
    //   Port: 2525,
    //   SecureToken:
    //     "8A5DD00A9E4328FBD4817DB0682333D96F7438811AD9ACB882596D989CE7A41CBF9F9FEFD573D57844D0D384E1834586",
    //   Host: "smtp.elasticemail.com",
    //   To: "no-reply@salaamtakaful.com",
    //   Username: "no-reply@salaamtakaful.com",
    //   Password: "AFEE3F107628C8BE79BD350E8BCC8A105299",
    //   From: "no-reply@salaamtakaful.com",
    //   Subject: "New Complaint Submission",
    //   Body: `
    //     <h2>New Complaint Submitted</h2>
    //     <p><strong>Name:</strong> ${values.name}</p>
    //     <p><strong>Email:</strong> ${values.email}</p>
    //     <p><strong>Phone:</strong> ${values.phone}</p>
    //     <p><strong>Complaint:</strong> ${values.complain}</p>
    //   `,
    // }).then(
    //   (messages) => {
    //     console.log("Mail sent successfully:", messages);
    //     message.success("Form submitted successfully.");
    //     form.resetFields();
    //     setIsSuccessMessageVisible(true);
    //     setSubmitting(false);
    //   },
    //   (error) => {
    //     console.error("Failed to send email:", error);
    //     message.error("Failed to submit the form. Please try again.");
    //     setSubmitting(false);
    //   }
    // );
  };
  useEffect(() => {
    if (!window.Email) {
      console.error("SMTP.js is not loaded.");
      return;
    }
    console.log("SMTP.js loaded successfully");
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (isSuccessMessageVisible) {
        setIsSuccessMessageVisible(false);
      }
    }, 3000);
  }, [isSuccessMessageVisible]);

  return (
    <Fragment>
      <div className="sub complainforms">
        <div className="row">
          <div className="col-sm-6">
            <Fade up delay={300}>
              {" "}
            </Fade>
          </div>

          <div className="col-sm-6 formstyle complainFormstyle">
            <Fade down delay={300}>
              <h2>COMPLAINT BOX</h2>
            </Fade>

            <Form
              form={form}
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Fade down delay={500}>
                {" "}
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              {/* <Fade down delay={900}>
                <Form.Item name="phone" rules={[{ type: "number", min: 0 }]}>
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Fade> */}

              <Fade down delay={900}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      pattern: /^03\d{2}-\d{7}$/,
                      message:
                        "Enter a valid contact number 03XX-XXXXXXX format",
                    },
                  ]}
                  normalize={(value) => {
                    if (!value) {
                      return value;
                    }
                    const onlyNums = value.replace(/[^\d]/g, "");
                    if (onlyNums.length <= 4) {
                      return onlyNums;
                    } else if (onlyNums.length <= 11) {
                      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                    }
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }}
                >
                  <Input placeholder="Contact 03XX-XXXXXXX" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item name="complain">
                  <Input.TextArea
                    placeholder="Complaint"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>

              <Fade down delay={1300}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    className="btnSubmit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Fade>
            </Form>

            {/* Display success message at the bottom */}
            {isSuccessMessageVisible && (
              <Fade down delay={300}>
                {" "}
                <div style={{ marginTop: "10px" }}>
                  <p className="message">Form submitted successfully.</p>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Complainform;
