import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postNewsLetterStart,
  postNewsLetterSuccess,
  postNewsLetterFailure,
} from "../reducers/postNewsLetterReducer";
import axios from "axios";

export const postNewsLetter = createAsyncThunk(
  "postNewsLetter",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postNewsLetterStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Web_NewsLetter/Subscribe`,
        values
      );

      const responseData = await response;

      thunkAPI.dispatch(postNewsLetterSuccess(values));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postNewsLetterFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postNewsLetterFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postNewsLetterFailure(error.response.data.status));
      }

      throw error;
    }
  }
);
