import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Cybertabs() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row singleprod">
            <Fade down delay={500}>
              <div className="col-sm-3 imgeboxes">
                <img
                  src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/CyberRiskTakaful.jpg`}
                />
                <h4>Cyber Risk Takaful</h4>
              </div>
            </Fade>

            <Fade up delay={500}>
              <div
                className="col-sm-12 mylist"
                style={{
                  backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/CyberRiskTakafulCover.jpg`})`,
                }}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <p>
                      <b>
                        Cyber Takaful covers your business' liability for a data
                        breach involving sensitive customer information
                      </b>
                      <br /> such as Social Security numbers, credit card
                      numbers, account numbers, driver's license numbers and
                      health records.
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fragment>
    );
}

export default Cybertabs