import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
};

const postNewsLetterSlice = createSlice({
  name: "postNewsLetter",
  initialState,
  reducers: {
    postNewsLetterStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postNewsLetterSuccess(state, action) {
      state.loading = false;
      toast.success("Form Submitted!");

      state.postMessage = action.payload.postMessage;
    },

    postNewsLetterFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      toast.error(action?.payload);

      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postNewsLetterStart,
  postNewsLetterSuccess,
  postNewsLetterFailure,
  clearError,
  clearMessage,
} = postNewsLetterSlice.actions;

export default postNewsLetterSlice.reducer;
