import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postError: null,
  data: undefined,
};

const postTravelTwoSlice = createSlice({
  name: "postTravelTwo",
  initialState,
  reducers: {
    postTravelTwoStart(state, payload) {
      state.loading = true;
      state.postError = null;
    },

    postTravelTwoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    postTravelTwoFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },

    clearMessage: (state) => {
      state.postError = null;
    },
  },
});

export const {
  postTravelTwoStart,
  postTravelTwoSuccess,
  postTravelTwoFailure,
  clearError,
  clearMessage,
} = postTravelTwoSlice.actions;

export default postTravelTwoSlice.reducer;
