import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function SalaamFireTab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamFireTakaful.jpg`} />
                        <h4>Salaam Fire Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamFireTakafulCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>
                                    <b>Fire/Property Takaful Policy designed to cover participant's properties.</b><br />
                                    (i.e. buildings, machinery, stock -raw material. machinery and other contents) whilst at participant-specified locations and provide 
                                    coverage to the participant/firm/organizations in the event of loss or damage caused by perils.
                                </p>
                            </div>

                            {/* <div className='col-sm-4'>
                                <h3>Coverages</h3>
                                <ul className='LiveStockList'>
                                    <li>
                                        <b>Comprehensive Dread Disease Expense Benefit</b><br />
                                        <p>
                                            Recovering from a chronic disease has a significant financial and emotional impact on any family. 
                                            Our Comprehensive Dread Disease Benefit help cover the costs associated with a member’s illness.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Comprehensive Hospitalization Expense Benefit</b><br />
                                        <p>
                                            Comprehensive Hospitalization cover refers to the coverage against any treatment where you need hospitalization for a minimum of one 
                                            (1) night, for specialized medical attention and care, before, during and after the treatment/surgery.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Maternity Expense Benefit</b><br />
                                        <p>
                                            Maternity coverage (optional) is one of the essential health benefits. Salaam Takaful Limited provides cover for pregnancy, labor, 
                                            delivery, and newborn baby care at an affordable contribution.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Out-patient Expense Benefit</b><br />
                                        <p>
                                            Salaam Takaful Limited provides (optional) Outpatient medical benefit that covers consultations and treatments provided by a specialist 
                                            or medical practitioner when an overnight stay in hospital is not necessary.
                                        </p>
                                    </li>
                                </ul>
                            </div> */}

                            <div className='col-sm-4 fireCoverage'>
                                <h3>Coverage</h3>
                                <p>Fire Takaful's policy covers your shop's building and contents/stocks against an array of risks.</p>
                                <ul className='LiveStockList'>
                                    <li>Cash in safe/counter/drawer</li>
                                    <li>Loss of rent</li>
                                    <li>Personal accident</li>
                                    <li>Medical Expense</li>
                                    <li>Explosion</li>
                                    <li>Riots and strikes damage</li>
                                    <li>Malicious damage</li>
                                    <li>Impact by vehicles</li>
                                    <li>Aircraft or articles dropped therefrom</li>
                                    <li>Earthquake, fire & shock</li>
                                    <li>Atmospheric disturbance</li>
                                    <li>Burglary and housebreaking</li>
                                    <li>Night work</li>
                                    <li>Electrical clause 'B'</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default SalaamFireTab