import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Contactform from "./Contactform";
import ContactMap from "./ContactMap";

function ContactBanner() {
  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner contact">
            <Fade up delay={500}>
              <h2 className="oswald">Contact Us</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text mymaxheight complainbox contactform contactSection">
            <Contactform />
          </div>

          <div className="col-sm-12 heads text mymaxheight maps contaacctMap">
            <ContactMap />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactBanner;
