import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { annualperformance } from '../../Data/Data';
import Fade from 'react-reveal/Fade';



function SingleAwards() {
  const { key } = useParams();
  const abc = key
  console.log(key);

  return (
    <Fragment>
      <div className='container-fluid faderight'>
        <div className='row'>

          <div className='container governancemain motor'>
            <div className='row '>
              <div className='col-sm-12 heads govbanner annual'>
                <Fade up delay={500}><h2 className="oswald">Annual Performance</h2></Fade>
              </div>
            </div>

            <div className='row singleblog arrticleAndBlogs'>
              <div className='col-sm-12 singlenews'>
                {annualperformance.map((data) => {
                  return (
  
                    <div className='container-fluid news' key={data.id}>

                      <div className='container'>
                        <div className='row'>
                          {data.desc.map((item) => {
                            return (
                              <div>
                                {
                                  item.key === abc ? <>
                                    <div className='col-sm-12 heads' key={item.key}>
                                      <Fade left delay={500}><iframe width="100%" height="500" src={item.link}></iframe>
                                      </Fade>
                                      <Fade left delay={500}><h2 className="oswald">{item.heading}</h2></Fade>
                                    </div>
                                  </> : null
                                }

                              </div>
                            );
                          })}
                        </div>
                      </div>

                    </div>

                  );
                })}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleAwards;