import React, { Fragment } from "react";
import ProxyBanner from "../../Components/Proxyform/ProxyBanner";
import { Helmet } from "react-helmet";

function Proxy() {
  const metaData = {
    title: 'Proxy Forms | Proxy Voting Forms',
    description: 'Download proxy forms for Salaam Takaful to designate a representative for voting at our meetings. Ensure your voice is heard by appointing a proxy to act on your behalf'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <ProxyBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Proxy;