import React from "react";
import { Fragment } from "react";
import PrivacyBanner from "../Components/PrivacyPolicy/PrivacyBanner";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const metaData = {
    title: 'Privacy Policy | Data Protection and Privacy',
    description: 'Read Salaam Takaful’s Privacy Policy to understand how we protect your personal data. Our policy outlines how we collect, use, and safeguard your information in accordance with privacy laws'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <PrivacyBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default PrivacyPolicy;
