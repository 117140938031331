import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Bankertabs() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row singleprod">
            <Fade down delay={500}>
              <div className="col-sm-3 imgeboxes">
                <img
                  src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamBankersBlanketTakaful.jpg`}
                />
                <h4>Bankers Blanket Takaful</h4>
              </div>
            </Fade>

            <Fade up delay={500}>
              <div
                className="col-sm-12 mylist"
                style={{
                  backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamBankersBlanketTakafulCover.jpg`})`,
                }}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <p>
                      This policy is designed to provide package cover for the
                      banks and financial Institutions it covers indemnity to
                      participant’s employees, property on premises, property in
                      transit, forged cheques, damage to office and contents,
                      and counterfeited currency.
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fragment>
    );
}

export default Bankertabs