import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getCalculateTrackerSlice = createSlice({
  name: "getCalculateTracker",
  initialState,
  reducers: {
    getCalculateTrackerStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getCalculateTrackerSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getCalculateTrackerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getCalculateTrackerStart,
  getCalculateTrackerSuccess,
  getCalculateTrackerFailure,
  clearError,
  clearMessage,
} = getCalculateTrackerSlice.actions;

export default getCalculateTrackerSlice.reducer;
