import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";


function WhyChoseUsMotors() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>
      <div className='row whychosesalaam'>
        <div className='col-sm-12 chose'>
          <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
        </div>

        <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
          <h3>Salaam Application</h3>
          <p>To bring more ease and convenience to our participants, we are offering some of the world's best hi-tech solutions in one app, so that you can stay in control no matter where you go.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-down" data-aos-duration="4000">

          <h3>Shariah Complaint Solution</h3>
          <p>Our shariah compliance function is a key element of company’s structure, carrying the responsibility of ensuring that all products and services offered by Salaam Takaful Limited are fully compliant with the principles of shariah law.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
          <h3>Fastest Claim Settlement Process</h3>
          <p>With our highly active team of qualified professionals, your claim is processed in a quick and efficient manner, saving you from any hassle.</p>
        </div>
      </div>


      <Fade up delay={500}>
        <div className='row claimprocedure personalAccident'>
          <div className='col-sm-12'>
            <Fade left delay={700}><h2>Claim Procedure</h2></Fade>
            <ul>
              <Fade up delay={800}><li><img src={Check} alt='Check' /> In case of the claim the company should be informed immediately.</li></Fade>
              <Fade up delay={900}> <li><img src={Check} alt='Check' />  Notice from the police is mandatory in case of theft.</li></Fade>
              <Fade up delay={1000}> <li><img src={Check} alt='Check' /> The company should be informed within 48 hours, in case the participant original and taxation papers are lost.</li></Fade>
              <Fade up delay={1100}><li><img src={Check} alt='Check' />  Registration of claim</li></Fade>
              <Fade up delay={1200}><li><img src={Check} alt='Check' /> Survey appointment and referred to workshop </li></Fade>
              <Fade up delay={1300}>  <li><img src={Check} alt='Check' /> Approval & settlement of claim </li></Fade>
            </ul>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default WhyChoseUsMotors