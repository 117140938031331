import { Button, Form, Input } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import TravelFormOne from "./TravelFormOne";
import { postTravelOne } from "../../../Redux/actions/postTravelOne";
import { useDispatch, useSelector } from "react-redux";
import { postTravelTwo } from "../../../Redux/actions/postTravelTwo";
import TravelFormTwo from "./TravelFormTwo";
import TravelFormThree from "./TravelFormThree";
import TravelFormFour from "./TravelFormFour";
import { postTravelThree } from "../../../Redux/actions/postTravelThree";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postTravelOneReducer";
import { clearMessage as ClearFormThreeError } from "../../../Redux/reducers/postTravelThreeReducer";
import { clearMessage as ClearErrorFour } from "../../../Redux/reducers/postTravelFourReducer";
import { clearMessage as ClearErrorFive } from "../../../Redux/reducers/postTravelFiveReducer";
import { clearMessage as ClearErrorSix } from "../../../Redux/reducers/postTravelSixReducer";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { postTravelFour } from "../../../Redux/actions/postTravelFour";
import TravelFormFive from "./TravelFormFive";
import TravelFormSix from "./TravelFormSix";
import { postTravelFive } from "../../../Redux/actions/postTravelFive";
import { postTravelSix } from "../../../Redux/actions/postTravelSix";
import { postTravelAddon } from "../../../Redux/actions/postTravelAddon";

const TravelMultiForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [productTypeId, setProductTypeId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [dOB, setDOB] = useState(null);
  const [productId, setProductId] = useState(null);
  const [checkedCoverage, setCheckedCoverage] = useState([]);
  const [planId, setPlanId] = useState(null);

  const formOneData = useSelector((state) => state.postTravelOne.data);
  const formOneError = useSelector((state) => state.postTravelOne.postError);
  const formFourError = useSelector((state) => state.postTravelFour.postError);
  const formFiveError = useSelector((state) => state.postTravelFive.postError);
  const formSixError = useSelector((state) => state.postTravelSix.postError);
  const postTravelThreeError = useSelector(
    (state) => state.postTravelThree.postError
  );
  const formTwoData = useSelector((state) => state.postTravelTwo.data);
  const [currentStep, setCurrentStep] = useState(1);
  const [beneficiaries, setBeneficiaries] = useState([
    { Name: "", CNIC: "", Passport: "", Gender: "", DOB: "", Relation: "" },
  ]);
  let AddonList;
  const handleAddBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      { Name: "", CNIC: "", Passport: "", Gender: "", DOB: "", Relation: "" },
    ]);
  };

  const handleRemoveBeneficiary = (indexToRemove) => {
    const updatedBeneficiaries = beneficiaries.filter(
      (_, index) => index !== indexToRemove
    );
    setBeneficiaries(updatedBeneficiaries);
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    console.log("Step 1 values:", values);
    // setProductTypeId(values?.Product_Type);
    values.Product_Type = productTypeId;
    setProductId(values?.Product_ID);
    setTypeId(values?.Type);
    const resultAction = await dispatch(postTravelOne({ values }));
    if (postTravelOne.fulfilled.match(resultAction)) {
      // toast.success("Form Submit Successfully");
      form.resetFields();
      handleNext();
    }
  };

  const onFinishStep2 = async (value) => {
    const values = {
      CNIC: value.CNIC,
      CNIC_Expiry_Date: value.CNIC_Expiry_Date?.format("DD-MMM-YYYY"),
      City: value.City,
      Address: value.Address,
      Gender: value.Gender,
      Occupation: value.Occupation,
      Passport_Number: value.Passport_Number,
      DOB: value.DOB?.format("DD-MMM-YYYY"),
      Unique_ID: formOneData[0]?.Unique_id,
      Unique_Detail_ID: formOneData[0]?.Unique_Detail_id,
      // FamilyList: formTwoData,
    };
    console.log(values, "<=============values");
    const resultAction = await dispatch(postTravelTwo({ values }));
    if (postTravelTwo.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep3 = async (value) => {
    const familyList = beneficiaries.map((beneficiary, index) => ({
      Name: value[`Name-${index}`],
      CNIC: value[`CNIC-${index}`],
      Passport_Number: value[`Passport-${index}`],
      Gender: value[`Gender-${index}`],
      DOB: value[`DOB-${index}`]?.format("DD-MMM-YYYY"),
      Relation: value[`Relation-${index}`],
    }));

    const values = { ...formTwoData, FamilyList: familyList };
    setDOB(value.DOB?.format("DD-MMM-YYYY"));

    const resultAction = await dispatch(postTravelThree({ values }));
    if (postTravelThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };
  let addonList = checkedCoverage.map((code) => ({ Coverage_Code: code }));
  const onFinishStep4 = async (values) => {
    values.To_Date = values.To_Date.format("DD-MMM-YYYY");
    values.From_Date = values.From_Date.format("DD-MMM-YYYY");
    values.Unique_ID = formOneData && formOneData[0]?.Unique_id;
    values.Unique_Detail_ID = formOneData && formOneData[0]?.Unique_Detail_id;
    const resultAction = await dispatch(postTravelFour({ values }));

    const data = {
      Unique_ID: formOneData && formOneData[0]?.Unique_id,
      Unique_Detail_ID: formOneData && formOneData[0]?.Unique_Detail_id,
      AddonList: addonList,
    };
    await dispatch(postTravelAddon({ data }));

    if (postTravelFour.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep5 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_id;
    values.Unique_Detail_ID = formOneData && formOneData[0]?.Unique_Detail_id;
    const resultAction = await dispatch(postTravelFive({ values }));
    if (postTravelFive.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep6 = async (values) => {
    if (!values.Affiliate_Code) {
      toast.success("Form Submitted Successfully");
      setCurrentStep(1);
    }
    values.Unique_ID = formOneData && formOneData[0]?.Unique_id;
    values.Unique_Detail_ID = formOneData && formOneData[0]?.Unique_Detail_id;
    const resultAction = await dispatch(postTravelSix({ values }));
    if (postTravelSix.fulfilled.match(resultAction)) {
      toast.success("Form Submitted Successfully");
      setCurrentStep(1);
    }
  };

  const onFinishStep7 = (values) => {
    console.log("Values:", values);
    setCurrentStep(1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFive = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedSix = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedSeven = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  let token;
  useEffect(() => {
    token = Cookies.get("token");
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (formOneError) {
      toast.error(formOneError);
      console.log(formOneError);
      dispatch(clearMessage());
    }
    if (postTravelThreeError) {
      toast.error(postTravelThreeError);
      dispatch(ClearFormThreeError());
    }
    if (formFourError) {
      toast.error(formFourError);
      dispatch(ClearErrorFour());
    }
    if (formFiveError) {
      toast.error(formFiveError);
      dispatch(ClearErrorFive());
    }
    if (formSixError) {
      toast.error(formSixError);
      dispatch(ClearErrorSix());
    }
  }, [
    dispatch,
    formOneError,
    postTravelThreeError,
    formFiveError,
    formSixError,
  ]);

  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div
          className="row formTakafulll"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/TravelFormOne.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Form 1 */}

          {currentStep === 1 && (
            <TravelFormOne
              setProductTypeId={setProductTypeId}
              onFinishStep1={onFinishStep1}
              onFinishFailed={onFinishFailed}
              form={form}
            />
          )}

          {/* Form 2 */}

          {currentStep === 2 && (
            <TravelFormThree
              onFinishFailedThree={onFinishFailedTwo}
              onFinishStep3={onFinishStep2}
            />
          )}

          {/* Form 3 */}

          {currentStep === 3 && (
            <TravelFormTwo
              typeId={typeId}
              handleRemoveBeneficiary={handleRemoveBeneficiary}
              handleAddBeneficiary={handleAddBeneficiary}
              beneficiaries={beneficiaries}
              onFinishFailedTwo={onFinishFailedThree}
              onFinishStep2={onFinishStep3}
            />
          )}

          {/* Form 4 */}

          {currentStep === 4 && (
            <>
              <TravelFormFour
                productId={productId}
                productTypeId={productTypeId}
                onFinishStep4={onFinishStep4}
                onFinishFailedFour={onFinishFailedFour}
                dOB={dOB}
                checkedCoverage={checkedCoverage}
                setCheckedCoverage={setCheckedCoverage}
                Type={typeId}
                setPlanId={setPlanId}
                planId={planId}
              />
            </>
          )}

          {/* Form 5 */}
          {/* 
          {currentStep === 5 && (
            <TravelFormCoverage planId={planId} productId={productId} />
          )} */}
          {currentStep === 5 && (
            <TravelFormFive
              onFinishStep5={onFinishStep5}
              onFinishFailedFive={onFinishFailedFive}
            />
          )}
          {/* Form 6 */}

          {currentStep === 6 && (
            <TravelFormSix
              onFinishStep6={onFinishStep6}
              onFinishFailedSix={onFinishFailedSix}
              productId={productId}
              productTypeId={productTypeId}
              onFinishStep4={onFinishStep4}
              onFinishFailedFour={onFinishFailedFour}
              dOB={dOB}
              checkedCoverage={checkedCoverage}
              setCheckedCoverage={setCheckedCoverage}
              Type={typeId}
              planId={planId}
            />
          )}

          {/* Form 7 */}

          {/* {currentStep === 7 && (
            <div
              className="col-sm-8 mForm"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/blueCar.png)`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="centreWidthFOrm">
                <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
                <h5 className="cnhbsh5">Payment</h5>

                <div className="paymentImages">
                  <div className="creditMaster">
                    <img src="https://banner2.cleanpng.com/20180627/sku/kisspng-debit-card-visa-credit-card-mastercard-payment-haircut-tool-5b33bbb449b7d0.886199351530117044302.jpg" />
                  </div>
                  <div className="creditMaster">
                    <img src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-logok-15.png" />
                  </div>
                </div>
                <Form
                  name="sehatFormSeven"
                  onFinish={onFinishStep7}
                  onFinishFailed={onFinishFailedSeven}
                  autoComplete="off"
                  layout="vertical"
                >
                  <div className="row multiFormPayment">
                    <div className="col-sm-6">
                      <Form.Item
                        label="Card Holder Name"
                        name="cardHolderName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter card holder name",
                          },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                    </div>
                    <div className="col-sm-6">
                      <Form.Item
                        label="Card Number"
                        name="cardNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter card number",
                          },
                          {
                            pattern: /^[0-9]{0,16}$/,
                            message:
                              "Please enter a valid card number (maximum 16 digits)",
                          },
                        ]}
                      >
                        <Input placeholder="Card Number" />
                      </Form.Item>
                    </div>

                    <div className="col-sm-6">
                      <Form.Item
                        label="CVV"
                        name="cardCVV"
                        rules={[
                          {
                            required: true,
                            message: "Please enter CVV",
                          },
                          {
                            pattern: /^[0-9]{0,4}$/,
                            message:
                              "Please enter a valid CVV (maximum 4 digits)",
                          },
                        ]}
                      >
                        <Input placeholder="Example: 4567" />
                      </Form.Item>
                    </div>

                    <div className="col-sm-6">
                      <Form.Item
                        label="Expiration Date"
                        name="expirationDate"
                        rules={[
                          {
                            required: true,
                            message: "Please enter card expiry date (MM/YY)",
                          },
                          {
                            pattern: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                            message:
                              "Please enter a valid expiry date in MM/YY format",
                          },
                        ]}
                      >
                        <Input placeholder="MM/YY" />
                      </Form.Item>
                    </div>
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ background: "#0FB583", marginTop: "30px" }}
                    >
                      Pay Now
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )} */}

          <div className="col-sm-4 mForm traaveel">
            {/* <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TravelMultiForm;
