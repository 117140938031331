import { Form, Input } from 'antd'
import React from 'react'

const EVerifyTravelForm = () => {
    return (
        <div className="row">
            <div className="col-sm-6 labelSSS">
                <Form.Item
                    name='Policy'
                    label="Policy #"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter Policy Number!"
                        }
                    ]}
                >
                    <Input placeholder='Policy #' />
                </Form.Item>

            </div>
            <div className="col-sm-6 labelSSS">
                <Form.Item
                    name="CNIC"
                    label='CNIC Number'
                    rules={[
                        {
                            required: true,
                            pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                            message:
                                "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                        },
                    ]}
                    normalize={(value) => {
                        if (!value) {
                            return value;
                        }

                        const onlyNums = value.replace(/[^\d]/g, "");
                        if (onlyNums.length <= 5) {
                            return onlyNums.slice(0, 5);
                        }
                        if (onlyNums.length <= 12) {
                            return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                        }
                        return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                            5,
                            12
                        )}-${onlyNums.slice(12, 13)}`;
                    }}
                >
                    <Input placeholder="CNIC #" />
                </Form.Item>
            </div>

        </div>
    )
}

export default EVerifyTravelForm