import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Col,
  Checkbox,
  Row,
  message,
} from "antd";
import Fade from "react-reveal/Fade";
import { getBlowingReport } from "../../Redux/actions/getBlowingReports";
import { useDispatch, useSelector } from "react-redux";
import { postWhistleBlowingForm } from "../../Redux/actions/postWhistleBlowingForm";
import { clearError } from "../../Redux/reducers/getBlowingReportReducer";

function WhistleForm() {
  const [form] = Form.useForm();
  const [selectedReports, setSelectedReports] = useState([]);

  const dispatch = useDispatch();
  const BlowingReport = useSelector((state) => state.getBlowingReport?.data);
  const BlowingReportResponse = useSelector(
    (state) => state.postWhistleBlowingForm
  );

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    dispatch(getBlowingReport());
  }, [dispatch]);

  const onFinish = async (data) => {
    const values = {
      Name: data?.name,
      EmailAddress: data?.email,
      MobileNo: data?.phone,
      Details: data?.issue,
      Type: "Whistle Blowing Form",
      Reports: selectedReports,
    };
    await dispatch(postWhistleBlowingForm(values));
  };

  useEffect(() => {
    if (!BlowingReportResponse?.postError) {
      form.resetFields();
      dispatch(clearError());
    }
  }, [BlowingReportResponse, dispatch]);

  const handleCheckboxChange = (value) => {
    setSelectedReports((prev) => {
      // Check if the value is already selected
      const isSelected = prev.some(
        (report) => report.Web_BlowingForm_ReportId === value
      );

      if (isSelected) {
        // Remove if it exists
        return prev.filter(
          (report) => report.Web_BlowingForm_ReportId !== value
        );
      } else {
        // Add if it doesn't exist
        return [...prev, { Web_BlowingForm_ReportId: value }];
      }
    });
  };
  return (
    <Fragment>
      <div className="sub complainforms whistleforms">
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className="row">
            <div className="col-sm-6 leftform formstyle">
              <Fade down delay={500}>
                {" "}
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              <Fade down delay={900}>
                <Form.Item name="phone" rules={[{ type: "number", min: 0 }]}>
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item name="issue">
                  <Input.TextArea
                    placeholder="Please Let Us Know Your Issue"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>
            </div>

            <div className="col-sm-6 formstyle rightform">
              <Fade down delay={500}>
                <h4>What do you want to report? * (select all that apply)</h4>

                <Form.Item name="checkbox-group">
                  <Checkbox.Group>
                    <Row>
                      {BlowingReport?.map((item, index) => {
                        return (
                          <Col span={8} key={index}>
                            <Checkbox
                              value={item?.Web_BlowingForm_ReportId}
                              onChange={() =>
                                handleCheckboxChange(
                                  item?.Web_BlowingForm_ReportId
                                )
                              }
                              style={{ lineHeight: "32px" }}
                            >
                              {item?.Description}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Fade>
            </div>
          </div>

          <div className="col-sm-12 whistleSubmit">
            <Fade down delay={1300}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={BlowingReportResponse?.Loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Fade>
          </div>
        </Form>

        {/* Display success message at the bottom */}
        {BlowingReportResponse?.Loading && (
          <Fade down delay={300}>
            {" "}
            <div style={{ marginTop: "10px" }}>
              <p className="message">Form submitted successfully.</p>
            </div>
          </Fade>
        )}
      </div>
    </Fragment>
  );
}

export default WhistleForm;
