import React, { Fragment } from "react";
import Encahsedbanner from "../../Components/Encahsed/Encahsedbanner";
import { Helmet } from "react-helmet";

function Encahsed() {
  const metaData = {
    title: 'Un-encashed Benefits / Cheque | Unclaimed Benefits Information ',
    description: 'Check for un-encashed benefits or unclaimed cheques from Salaam Takaful. Our page provides guidance on how to track and claim your unclaimed funds or benefits'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Encahsedbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Encahsed;