import React, { Fragment } from 'react'
import SalaamEngineering from '../../Components/CorporateProductList/SalamEngineering'
import { Helmet } from 'react-helmet'

function SalaamMiscellaneous() {
  const metaData = {
    title: 'Salaam Engineering Takaful | Islamic Insurance for Engineering Projects',
    description: 'Safeguard your engineering projects with Salaam Engineering Takaful. Our Shariah-compliant insurance offers coverage for construction, machinery, and related risks'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight'>
        <div className='row arrticleAndBlogs'>
          <SalaamEngineering />
        </div>
      </div>
    </Fragment>
  )
}

export default SalaamMiscellaneous