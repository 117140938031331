import React, { Fragment } from 'react'
import SalamCrops from '../../Components/IndividualProductList/SalamCrop'
import { Helmet } from 'react-helmet'

function SalamCrop() {
    const metaData = {
        title: 'Salaam Crop Takaful | Islamic Agricultural Insurance for Farmers',
        description: 'Protect your crops with Salaam Crop Takaful. Our Shariah-compliant agricultural insurance offers coverage against natural disasters, disease, and other risks to ensure your farming success'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamCrops />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamCrop