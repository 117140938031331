import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPAPlanStart,
  getPAPlanSuccess,
  getPAPlanFailure,
} from "../reducers/getPAPlanReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getPAPlan = createAsyncThunk("getPAPlan", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getPAPlanStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/PA/GetPlan`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response;

    thunkAPI.dispatch(getPAPlanSuccess(responseData?.data?.result));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(getPAPlanFailure(error.response.data.Message));
    if (error.response && error.response.status === 401) {
      Cookies.remove("token");

      thunkAPI.rejectWithValue({ unauthorized: true });
      await thunkAPI.dispatch(getToken());
    }
    throw error;
  }
});
