import React, { Fragment } from 'react'
import SalamMedicals from '../../Components/IndividualProductList/SalamMedical'

function SalamMedical() {
    return (
        <Fragment>
            <div className='container faderight about'>
                <div className='row arrticleAndBlogs'>
                    <SalamMedicals />

                </div>
            </div>

        </Fragment>

    )
}

export default SalamMedical