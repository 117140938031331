
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
// import PDFIMAGE from "../../Assets/img/pdf.png"
import { Policiespdf } from "../../Data/Data";
import Fade from 'react-reveal/Fade';
import Accordion from 'react-bootstrap/Accordion';


function Policiespdfs() {
    
    // const PDFIMAGE = `${process.env.REACT_APP_URL_ASSETS}Assets/img/pdf.png`

  return (
   <Fragment>
    <div className='container acordianreport'>
                <div className='row'>
                    <Fade left delay={500}>
                        <div className='col-sm-12 reportsaccords'>

                            <Accordion defaultActiveKey="1">
                                {Policiespdf.map((data) => (
                                    <Accordion.Item eventKey={data.key}>
                                        <Accordion.Header>{data.heading}</Accordion.Header>
                                        <Accordion.Body>

                                            <div className='row'>
                                                {data.pdf.map((item) => (
                                                    <Fade up delay={500}>
                                                        <div className='col-sm-4 pdfs' key={item.key} data-aos="fade-up" data-aos-duration="4000">
                                                            <Link to={item.link} target="_blank">
                                                                <img src={item.image} alt='pdf' />
                                                                <h3> {item.title}</h3>
                                                            </Link>
                                                        </div>
                                                    </Fade>
                                                ))}

                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Fade>

                </div>
            </div>
   </Fragment>
  )
}

export default Policiespdfs