import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorCity } from "../../../Redux/actions/getMotorCity";
import { getTravelCountry } from "../../../Redux/actions/getTravelCountry";
import { getTravelReason } from "../../../Redux/actions/getTravelReason";
import { getTravelTripType } from "../../../Redux/actions/getTravelTripType";
import { getTravelPlan } from "../../../Redux/actions/getTravelPlan";
import { getTravelPlanDetail } from "../../../Redux/actions/getTravelPlanDetail";
import moment from "moment";
import { getTravelTotalContribution } from "../../../Redux/actions/getTravelTotalContribution";

const TravelFormFour = ({
  onFinishFailedFour,
  onFinishStep4,
  productTypeId,
  productId,
  dOB,
  Type,
  setCheckedCoverage,
  checkedCoverage,
  setPlanId,
  planId,
}) => {
  const [NofDay, setNoofDay] = useState();
  const [dateRange, setDateRange] = useState();
  const [dateRange1, setDateRange1] = useState();
  const [tenure, setTenure] = useState(0);
  const dispatch = useDispatch();
  const cityData = useSelector((state) => state.getMotorCity.data);
  const countryData = useSelector((state) => state.getTravelCountry.data);
  const reasonData = useSelector((state) => state.getTravelReason.data);
  const tripTypeData = useSelector((state) => state.getTravelTripType.data);
  const planData = useSelector((state) => state.getTravelPlan.data);
  const planDetailData = useSelector((state) => state.getTravelPlanDetail.data);
  const contributionData = useSelector(
    (state) => state.getTravelTotalContribution.data
  );

  const handleDateChange = (e, dates) => {
    setDateRange(dates);
  };
  const calculateDays = async () => {
    if (dateRange && dateRange1) {
      const startDate = moment(dateRange);
      const endDate = moment(dateRange1);
      const daysDiff = endDate.diff(startDate, "days");
      await setTenure(daysDiff);
      return daysDiff;
    }
    return;
  };

  const handleDateChange1 = (e, dates) => {
    setDateRange1(dates);
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };
  const disabledDate1 = (current) => {
    return current && current < moment(dateRange).endOf("day");
  };
  const handleCheckboxChange = (coverageCode, checked) => {
    if (checked) {
      setCheckedCoverage([...checkedCoverage, coverageCode]);
    } else {
      setCheckedCoverage(
        checkedCoverage.filter((code) => code !== coverageCode)
      );
    }
  };
  const handlePlanChange = (value) => {
    setPlanId(value);
  };

  const calculateAmount = () => {
    let amount = 0;
    for (const item of planDetailData) {
      if (checkedCoverage.includes(item.PRODUCT_COVERAGE_CODE)) {
        if (item.COVERAGE_LIMIT == "Included") {
          amount += 0;
        } else {
          amount += parseInt(item.COVERAGE_LIMIT.replace(/,/g, ""));
        }
      }
    }
    return amount;
  };

  useEffect(() => {
    dispatch(getTravelReason());
    dispatch(getTravelTripType());
    dispatch(getTravelPlan({ productId }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMotorCity());

    dispatch(getTravelCountry());
  }, [dispatch]);

  useEffect(() => {
    if (planId) {
      dispatch(
        getTravelTotalContribution({ productId, planId, tenure, dOB, Type })
      );
      dispatch(getTravelPlanDetail({ productId, planId }));
    }
  }, [planId]);
  const [form] = Form.useForm();
  useEffect(() => {
    calculateDays();

    form.setFieldsValue({ Tenure: tenure });
  }, [tenure, dateRange1, form]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{ 
        backgroundColor: "rgb(23 105 172 / 69%)"
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Travel Information</h5>
        <Form
          name="travelFormFour"
          onFinish={onFinishStep4}
          layout="vertical"
          onFinishFailed={onFinishFailedFour}
          autoComplete="off"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              {productTypeId ? (
                <Form.Item
                  name="Departure_From"
                  label="Departure From"
                  rules={[
                    {
                      required: true,
                      message: "Please select a destination From",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Destination From"
                    showSearch
                    filterOption={(inputValue, option) =>
                      (option.children || "")
                        .toString()
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {productTypeId === "D"
                      ? cityData?.map((item, i) => (
                          <Select.Option key={i} value={item.city_code}>
                            {item.city_name}
                          </Select.Option>
                        ))
                      : countryData?.map((item, i) => (
                          <Select.Option key={i} value={item.country_code}>
                            {item.country_name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              ) : null}
            </div>
            <div className="col-sm-6 labelSSS">
              {productTypeId ? (
                <Form.Item
                  name="Destination"
                  label="Destination"
                  rules={[
                    {
                      required: true,
                      message: "Please select a destination",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Destination"
                    showSearch
                    filterOption={(inputValue, option) =>
                      (option.children || "")
                        .toString()
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {productTypeId === "D"
                      ? cityData?.map((item, i) => (
                          <Select.Option key={i} value={item.city_code}>
                            {item.city_name}
                          </Select.Option>
                        ))
                      : countryData?.map((item, i) => (
                          <Select.Option key={i} value={item.country_code}>
                            {item.country_name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              ) : null}
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Travel_Reason"
                label="Travel Reason"
                rules={[
                  {
                    required: true,
                    message: "Please select reason!"
                  },
                ]}
              >
                <Select
                  placeholder="Select Travel Reason"
                  showSearch
                  filterOption={(inputValue, option) =>
                    (option.children || "")
                      .toString()
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {reasonData?.map((item, i) => (
                    <Select.Option key={i} value={item?.TRAVEL_REASON}>
                      {item?.TRAVEL_REASON}{" "}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Trip_Type"
                label="Trip Type"
                rules={[
                  {
                    required: true,
                    message: "Please select trip type!"
                  },
                ]}
              >
                <Select placeholder="Trip Type">
                  {tripTypeData?.map((item, i) => (
                    <Select.Option key={i} value={item?.ID}>
                      {item?.Type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="From_Date"
                label="From"
                rules={[
                  {
                    required: true,
                    message: "Select Departure Date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="From"
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="To_Date"
                label="To"
                rules={[
                  {
                    required: true,
                    message: "Select Arrival Date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="To"
                  onChange={handleDateChange1}
                  disabledDate={disabledDate1}
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Tenure"
                label="Tenure"
                rules={[
                  {
                    required: false,
                    message: "Tenure is required!"
                  },
                ]}
              >
                <Input readOnly placeholder="Tenure" />
              </Form.Item>
            </div>

            

            <div className="col-sm-6 labelSSS">
              <Form.Item 
                name="Plan_ID" 
                label="Plan Type"
                rules={[
                  {
                    required: true,
                    message: "Plan is required!"
                  }
                ]} 
              >
                <Select
                  placeholder="Select Plan"
                  onChange={handlePlanChange}
                  showSearch
                  filterOption={(inputValue, option) =>
                    (option.children || "")
                      .toString()
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {planData?.map((item, i) => (
                    <Select.Option key={i} value={item?.Plan_ID}>
                      {item?.Plan_NAME + `(${item?.Plan_desc})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <table className="coverageTable">
                <thead>
                  <tr>
                    <th>Coverage</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {planDetailData?.map((item) => (
                    <tr>
                      <td>{item?.COVERAGE_BENEFITS}</td>
                      {item.IS_MANDATORY === "N" ? (
                        <td>
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(
                                item.PRODUCT_COVERAGE_CODE,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                      ) : (
                        <td>{item.COVERAGE_LIMIT}</td>
                      )}
                    </tr>
                  ))}
                  {contributionData.map((item) => (
                    <tr>
                      <td><b>Total Amount</b></td>
                      <td> {parseInt(item.AMOUNT) + calculateAmount()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            <div className="col-sm-12">
              {planDetailData?.length > 1 && (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ background: "#1C75BC" }}
                  >
                    Next
                  </Button>
                </Form.Item>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default TravelFormFour;
