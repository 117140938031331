import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import PrivacyAccor from './PrivacyAccor';

function PrivacyText() {
  return (
   <Fragment>
    <div className='container privacy'>
        <div className='row'>
        <Fade down delay={500}>
            <div className='col-sm-12 finanlines'>
                <h2>Introduction</h2>
            </div>
            <div className='col-sm-12 intor'>
                <p>Salaam Takaful Limited (the "Company") is committed to protecting the privacy of your personal information. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website, use our products or services, or interact with us in any other way.</p>
            </div>

            <PrivacyAccor/>
        </Fade>


        </div>
    </div>
   </Fragment>
  )
}

export default PrivacyText