import React, { Fragment } from "react";
import Salamcyber from "../../Components/FinancialProductList/Salamcyber";
import { Helmet } from "react-helmet";

function SalamCyber() {
  const metaData = {
    title: 'Salaam Cyber Risk Takaful | Islamic Insurance for Digital Threats',
    description: 'Shield your business from digital risks with Salaam Cyber Risk Takaful. Our Shariah-compliant insurance offers comprehensive coverage against cyber attacks and data breaches'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row arrticleAndBlogs">
          <Salamcyber />
        </div>
      </div>
    </Fragment>
  );
}

export default SalamCyber;
