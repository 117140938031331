import React, { Fragment } from "react";
import Shariahbanner from "../Components/Shariah/Shariahbanner";
import { Helmet } from "react-helmet";

function ShariahAdvisor() {
  const metaData = {
    title: 'Shariah | Shariah Compliance at Salaam Takaful',
    description: 'Explore how Salaam Takaful adheres to Shariah principles in all our offerings. Our Shariah compliance page provides insights into our commitment to ethical practices and the role of our Shariah advisory board.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row">
          <Shariahbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default ShariahAdvisor;