import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Hospitalpdf from './Hospitalpdf';


function HospitalBanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>

                <div className='col-sm-12 heads govbanner hospital'>
                        <Fade up delay={500}><h2 className="oswald">Panel Hospitals</h2></Fade>
                    </div>

                    <div className='col-sm-12 heads text mymaxheight'>
                        <Hospitalpdf/>
                    </div>

                
                </div>
            </div>
        </Fragment>
    )
}

export default HospitalBanner