import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getHomeUnitSlice = createSlice({
  name: "getHomeUnit",
  initialState,
  reducers: {
    getHomeUnitStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getHomeUnitSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getHomeUnitFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getHomeUnitStart,
  getHomeUnitSuccess,
  getHomeUnitFailure,
  clearError,
  clearMessage,
} = getHomeUnitSlice.actions;

export default getHomeUnitSlice.reducer;
