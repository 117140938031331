import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorTimeSlotSlice = createSlice({
  name: "getMotorTimeSlot",
  initialState,
  reducers: {
    getMotorTimeSlotStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorTimeSlotSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorTimeSlotFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorTimeSlotStart,
  getMotorTimeSlotSuccess,
  getMotorTimeSlotFailure,
  clearError,
  clearMessage,
} = getMotorTimeSlotSlice.actions;

export default getMotorTimeSlotSlice.reducer;
