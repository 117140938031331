import React, { Fragment } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Desktopnav() {
  const Individual = `${process.env.REACT_APP_URL_ASSETS}Assets/pdf/Individual.pdf`
  const Term = `${process.env.REACT_APP_URL_ASSETS}Assets/pdf/Term.pdf`
  const Waqf = `${process.env.REACT_APP_URL_ASSETS}Assets/pdf/Waqf.pdf`

  
  return (
    <Fragment>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="The Company" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link
                  to="/about-us"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  About Us{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/whoweare"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Who We Are{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/company-details"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Company Details
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/governance"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Governance
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/shariah"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Shariah Compliance{" "}
                </Link>{" "}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Products" id="basic-nav-dropdown">
              {/* <Link
                to="/product"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                {" "}
                Products{" "}
              </Link> */}
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/Individual-Product"
                  onClick={() => {
                    const element = document.getElementById("individual");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Individual
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/Corporate-Product"
                  onClick={() => {
                    const element = document.getElementById("corporate");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Corporates
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/Financial-Product"
                  onClick={() => {
                    const element = document.getElementById("financial");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Financial Institutions
                </Link>{" "}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Media" id="basic-nav-dropdown">
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/news-and-announcements"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  News & Announcements
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/articles-and-blogs"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Articles & Blogs
                </Link>{" "}
              </NavDropdown.Item>

              {/* <NavDropdown.Item>
                <Link
                  to="/awards-&-recognition"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Awards and Recognition
                </Link>{" "}
              </NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Investor Relations" id="basic-nav-dropdown">
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/financial-reports"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Financial Reports{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/financial-highlights"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Financial Highlights{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/annual-performance"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Annual Performance Review
                </Link>
              </NavDropdown.Item>
              {/* <NavDropdown.Item>
                <Link>Investors Relations </Link>{" "}
              </NavDropdown.Item> */}
              <NavDropdown.Item>
                <Link
                  to="/nogm"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Notice of General Meeting{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/proxy-forms"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Proxy Forms{" "}
                </Link>{" "}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link>
              <Link
                to="/surplus-distribution"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Surplus Distribution{" "}
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link
                to="/esg"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Sustainablity
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link
                to="/life-at-salaam"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Life @ Salaam
              </Link>
            </Nav.Link>

            <NavDropdown title="Downloads" id="basic-nav-dropdown">
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/proposal-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Proposal Forms
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/claim-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Claim Forms{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/complaint-resolution-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Complaint Resolution{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/un-encahsed-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Un-encashed Benefits/Cheque
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/policies-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Policies
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/hospital-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Panel Hospitals
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/discount-form"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Discount Center{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/marketing-collateral-forms"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Marketing Collateral{" "}
                </Link>{" "}
              </NavDropdown.Item>
              {/* <NavDropdown.Item>
                {" "}
                <Link
                  to="/proxy-forms"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Proxy Forms{" "}
                </Link>{" "}
              </NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="E-Services" id="basic-nav-dropdown">
              <NavDropdown.Item >
                {" "}
                <Link
                  to="/e-verify"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Certificate Verification
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item >
                {" "}
                <Link
                  to="/claim"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Claim Intimation
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <Link
                  to="/compain-box"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Complaint Box{" "}
                </Link>{" "}
              </NavDropdown.Item>
              {/* <NavDropdown.Item>
                {" "}
                <Link
                  to="/whistle-blowing"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Whistle Blowing Form{" "}
                </Link>{" "}
              </NavDropdown.Item> */}
            </NavDropdown>

            <Nav.Link>
              <Link
                to="/contactus"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Contact us{" "}
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
}
