import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import CollateralForm from './CollateralForm';


function Mbanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>

                    <div className='col-sm-12 heads govbanner marketing'>
                        <Fade up delay={500}><h2 className="oswald">Marketing Collateral</h2></Fade>
                    </div>

                    <div className='col-sm-12 heads text mymaxheight'>
                       <CollateralForm/>
                    </div>

                
                </div>
            </div>
        </Fragment>
    )
}

export default Mbanner