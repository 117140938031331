import React, { Fragment } from 'react'
import Esgtext from './Esgtext';
import Esgtabs from './Esgtabs';

function EsgBnanner() {
    return (
        <Fragment>
            <div className='container tabss motor bgwhite' >
                <div className='row arrticleAndBlogs'>
                    <div className='col-sm-12 heads text mymaxheight eeesssg'>
                        <Esgtext />
                    </div>
                    <div className='col-sm-12 heads text mymaxheight bot'>
                        <Esgtabs />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EsgBnanner