import React, { Fragment } from "react";
import ContactBanner from "../Components/ContactUs/ContactBanner";
import { Helmet } from "react-helmet";

function Contactus() {
  const metaData = {
    title: 'Contact Salaam Takaful | Get in Touch with Us',
    description: 'Contact Salaam Takaful for inquiries, support, or feedback. Reach out to our team through phone, email, or visit our office.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <ContactBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Contactus;