import React, { Fragment } from "react";
import CareerBanner from "../Components/Career/CareerBanner";
import { Helmet } from "react-helmet";

function Career() {
  const metaData = {
    title: 'Careers at Salaam Takaful | Join Our Team',
    description: 'Explore career opportunities at Salaam Takaful. Learn about our work culture, values, and how you can grow with us.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <CareerBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Career;