import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getTravelOccupationSlice = createSlice({
  name: "getTravelOccupation",
  initialState,
  reducers: {
    getTravelOccupationStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getTravelOccupationSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getTravelOccupationFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getTravelOccupationStart,
  getTravelOccupationSuccess,
  getTravelOccupationFailure,
  clearError,
  clearMessage,
} = getTravelOccupationSlice.actions;

export default getTravelOccupationSlice.reducer;
