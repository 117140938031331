import React, { Fragment } from 'react'
import SalamPlasticCard from '../../Components/FinancialProductList/SalamPlasticCard'
import { Helmet } from 'react-helmet'

function SalamPlastic() {
    const metaData = {
        title: 'Salaam Plastic Card Takaful | Islamic Secure Card Insurance',
        description: 'Secure your plastic card transactions with Salaam Plastic Card Takaful. Our Shariah-compliant insurance provides protection against fraud, theft, and misuse of credit and debit cards'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight '>
                <div className='row arrticleAndBlogs'>
                    <SalamPlasticCard />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamPlastic