import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import PrivacyText from './PrivacyText';


function PrivacyBanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row'>

                <div className='col-sm-12 heads govbanner ourpolicy'>
                        <Fade up delay={500}><h2 className="oswald">Privacy Policy</h2></Fade>
                    </div>

                    <div className='col-sm-12 heads text mymaxheight'>
                       <PrivacyText/>
                    </div>

                
                </div>
            </div>
        </Fragment>
    )
}

export default PrivacyBanner