import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";

function Safetabs() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row singleprod">
          <Fade down delay={500}>
            <div className="col-sm-3 imgeboxes">
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SafeDepositeTakaful.jpg`}
              />
              <h4>Salaam Safe Deposit Box Takaful</h4>
            </div>
          </Fade>

          <Fade up delay={500}>
            <div
              className="col-sm-12 mylist"
              style={{
                backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SafeDepositeTakafulCover.jpg`})`,
              }}
            >
              <div className="row">
                <div className="col-sm-4">
                  <p>
                    Provides coverage for customers' items of intrinsic value
                    contained in safe deposit boxes against risk such as: House
                    Breaking, Armed Hold up any other Forcible violent means.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default Safetabs;
