import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
  postData: {},
};

const postHomeOneSlice = createSlice({
  name: "postHomeOne",
  initialState,
  reducers: {
    postHomeOneStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHomeOneSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postHomeOneDataSuccess(state, action) {
      state.postData = action.payload;
    },
    postHomeOneFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHomeOneStart,
  postHomeOneSuccess,
  postHomeOneDataSuccess,
  postHomeOneFailure,
  clearError,
  clearMessage,
} = postHomeOneSlice.actions;

export default postHomeOneSlice.reducer;
