import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { getMotorTimeSlot } from "../../../Redux/actions/getMotorTimeSlot";
import { useDispatch, useSelector } from "react-redux";
import { getMotorBillingInfo } from "../../../Redux/actions/getMotorBillingInfo";
import { postMotorDiscount } from "../../../Redux/actions/postMotorDiscount";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postMotorDiscountReducer";
import { getHealthBillingInfo } from "../../../Redux/actions/getHealthBillingInfo";

const HealthFormSix = ({
  onFinishStep6,
  onFinishFailedSix,
  formOneData,
  form,
  handlePrev
}) => {
  const dispatch = useDispatch();
  const [discountAmount, setDiscountAmount] = useState("");

  const { data } = useSelector((state) => state.getHealthBillingInfo);
  const { postError, postMessage } = useSelector(
    (state) => state.postMotorDiscount
  );
  console.log(data, "<=====data");
  const timeSlotData = useSelector((state) => state.getMotorTimeSlot.data);

  const handleInputChange = (event) => {
    setDiscountAmount(event.target.value);
  };
  useEffect(() => {
    dispatch(getMotorTimeSlot());
  }, [dispatch]);

  useEffect(() => {
    const Unique_ID = formOneData && formOneData[0]?.Unique_id;

    dispatch(getHealthBillingInfo({ Unique_ID }));
  }, []);
  useEffect(() => {
    if (postError) {
      toast.error(postError);
      dispatch(clearMessage());
    }
    if (postMessage) {
      toast.success(postMessage);
      const Unique_ID = formOneData && formOneData[0];

      dispatch(getHealthBillingInfo({ Unique_ID }));
      dispatch(clearMessage());
    }
  }, [postError, postMessage]);

  useEffect(() => {
    form.setFieldsValue({
      Gross_Contribution: data && data[0]?.Gross_Contribution,
      Policy_Amount: data && data[0]?.Policy_Amount,
      Delivery_Charges: data && data[0]?.Delivery_Charges,
      Discount_Amount: data && data[0]?.Discount_Amount,
      Total_Payable: data && data[0]?.Total_Payable,
    });
  }, [form, data]);
  //   useEffect(() => {
  //     let timeoutId;

  //     const fetchData = async () => {
  //       if (discountAmount.trim() !== "") {
  //         try {
  //           const values = {
  //             Dicount_Code: discountAmount,
  //             UNIQUE_ID: formOneData && formOneData[0],
  //           };
  //           dispatch(postMotorDiscount({ values }));
  //         } catch (error) {
  //           toast.error(error);
  //         }
  //       }
  //     };

  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(fetchData, 1000); // Adjust the debounce delay as needed

  //     return () => clearTimeout(timeoutId);
  //   }, [discountAmount]);

  return (
    <div
      className="col-sm-8 mForm"
      // style={{background: '#0FB583'}}
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Health Takaful</h3>
        <h5 className="cnhbsh5">Contribution & Surveyor</h5>
        <Form
          name="motorFormOne"
          onFinish={onFinishStep6}
          onFinishFailed={onFinishFailedSix}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row travelBillingForm nowwNew">
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Gross Contribution" name="Gross_Contribution">
                <Input placeholder="Gross Contribution" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Policy Amount" name="Policy_Amount">
                <Input placeholder="Policy_Amount" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Delivery Charges" name="Delivery_Charges">
                <Input placeholder="Dellviery Charges" readOnly />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Discount Amount" name="Discount_Amount">
                <Input
                  onChange={handleInputChange}
                  placeholder="Discount Amount"
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Total Amount" name="Total_Payable">
                <Input placeholder="Total Amount" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Affiliate Code" name="Affiliate_Code">
                <Input placeholder="Affliate Code" />
              </Form.Item>
            </div>
          <div className="col-sm-6">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
<div className="col-sm-6">

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Submit
            </Button>
          </Form.Item>
          </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HealthFormSix;
