import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { postCorporateFinancial } from "../../Redux/actions/postCorporateFinancial";
import { toast } from "react-toastify";
import { clearMessage } from "../../Redux/reducers/postCorporateFinancialReducer";

const CorporateAndFinancialForm = ({ title }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.postCorporateFinancial.error);
  const onFinishStep1 = async (values) => {
    values.Product_Value = title;
    const resultAction = await dispatch(postCorporateFinancial({ values }));
    if (postCorporateFinancial.fulfilled.match(resultAction)) {
      toast.success("Form Submitted Successfully");
      form.resetFields();
    }
  };
  const onFinishFailed = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [error]);
  return (
    <div className="container-fluid motorFomrOne">
      <div
        className="row formTakafulll"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/SehatFormOne.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="col-sm-8 mForm"
          style={{
            // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            backgroundColor: "rgb(15 181 131 / 69%)",
          }}
        >
          <div className="centreWidthFOrm">
            <h3 className="cnhbsh3">Salaam {title} takaful</h3>
            <h5 className="cnhbsh5">Basic Information</h5>
            <Form
              name="sehatFormOne"
              onFinish={onFinishStep1}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              autoComplete="off"
              form={form}
            >
              <div className="row">
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "Email is not valid!",
                      },
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Phone"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                        pattern: /^03\d{2}-\d{7}$/,
                        message:
                          "Phone number must be in this format 03XX-XXXXXXX",
                      },
                    ]}
                    normalize={(value) => {
                      if (!value) {
                        return value;
                      }
                      const onlyNums = value.replace(/[^\d]/g, "");
                      if (onlyNums.length <= 4) {
                        return onlyNums;
                      } else if (onlyNums.length <= 11) {
                        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(
                          4,
                          11
                        )}`;
                      }
                      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                    }}
                  >
                    <Input placeholder="Contact 03XX-XXXXXXX" />
                  </Form.Item>
                </div>
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="City"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "City is required!",
                      },
                    ]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </div>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-sm-4 mForm">
            {/* <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div> */}
          </div>
        </div>
        <div className="col-sm-4 mForm">
          {/* <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default CorporateAndFinancialForm;
