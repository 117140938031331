import React, { useEffect } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLiveIn } from "../../../Redux/actions/getLiveIn";
import { getHomeUnit } from "../../../Redux/actions/getHomeUnit";
import { getHomeOwnerShip } from "../../../Redux/actions/getHomeOwnerShip";
import { getHomeCity } from "../../../Redux/actions/getHomeCity";
import { getHomeOccupation } from "../../../Redux/actions/getHomeOccupation";

const HomeFormFive = ({ onFinishStep5, onFinishFailedFive }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const formthreeData = useSelector((state) => state.postHomeFormThree.data);

  const livesinData = useSelector((state) => state.getLiveIn.data);
  const homeUnitData = useSelector((state) => state.getHomeUnit.data);
  const homeOwnerData = useSelector((state) => state.getHomeOwner.data);
  const homeCityData = useSelector((state) => state.getHomeCity.data);
  const homeOccupationData = useSelector(
    (state) => state.getHomeOccupation.data
  );
  console.log(formthreeData, ",,,,,,,,,,,,,,,,,,,,formthreedata");

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  useEffect(() => {
    dispatch(getHomeCity());
    dispatch(getHomeOccupation());
  }, [dispatch]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5"> Checkout Form</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep5}
          onFinishFailed={onFinishFailedFive}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                name="Dicount_Code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Dicount Code" />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="Affiliate_Code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Affliate Code" />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormFive;
