import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Accordion from 'react-bootstrap/Accordion';

function PrivacyAccor() {
  return (
   <Fragment>
        <Fade down delay={500}>


            <div className='container boxes'>
                <div className='row statment'>

                    <Fade down delay={500}>
                        <div className='col-sm-12'>
                         <Accordion defaultActiveKey="0">

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is Personal Information?</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>Personal information is any information that can be used to identify you, such as your name, address, phone number, email address, or date of birth. We may also collect other information about you, such as your income, employment history, and medical history.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How we collect Personal Information?</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>We collect personal information from you in a variety of ways, including:</p>
                                            <ul>
                                                <li>When you visit our website, we may collect your IP address, browser type, and other information about your computer</li>
                                                <li>When you use our products or services, we may collect information about your use of those products or services, such as the products or services you have purchased, the claims you have made, and the payments you have made</li>
                                                <li>When you interact with us, we may collect information about your interaction, such as the questions you ask us and the feedback you provide</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How we use Personal Information?</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>We use your personal information for a variety of purposes, including:</p>
                                            <ul>
                                                <li>To provide you with the products and services you have requested</li>
                                                <li>To process your payments</li>
                                                <li>To communicate with you about your account or your takaful policy</li>
                                                <li>To improve our products and services</li>
                                                <li>To comply with applicable laws and regulations</li>
                                                <li>To prevent fraud and abuse</li>
                                                <li>When you use our products or services, we may collect information about your use of those products or services, such as the products or services you have purchased, the claims you have made, and the payments you have made</li>
                                                <li>When you interact with us, we may collect information about your interaction, such as the questions you ask us and the feedback you provide</li>    
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Who we share Personal Information with?</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>We may share your personal information with the following third parties:</p>
                                            <ul>
                                                <li>Our affiliates, including our parent company, subsidiaries, and other related entities</li>
                                                <li>Our service providers, who help us to provide our products and services, such as our claims processing company, our IT support provider, and our marketing agency</li>
                                                <li>Government agencies, if required by law</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How we protect Personal Information?</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These steps include:</p>
                                            <ul>
                                                <li>Using secure servers and encryption technology</li>
                                                <li>Requiring employees to sign confidentiality agreements</li>
                                                <li>Monitoring our security systems</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Your right</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>You have certain rights with respect to your personal information, including the right to:</p>
                                            <ul>
                                                <li>Access your personal information</li>
                                                <li>Request that we delete your personal information</li>
                                                <li>Object to the processing of your personal information</li>
                                                <li>Port your personal information to another company</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Changes to this Privacy Policy</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Contact us</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:marketing@salaamtakaful.com">marketing@salaamtakaful.com</a> </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                            </Accordion>
                        </div>
                    </Fade>



                </div>
            </div>
        </Fade>

   </Fragment>
  )
}

export default PrivacyAccor