import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHealthFourStart,
  postHealthFourSuccess,
  postHealthFourFailure,
} from "../reducers/postHealthFourReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postHealthFour = createAsyncThunk(
  "postHealthFour",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHealthFourStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Health/SaveHealthForthPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );
      console.log(values, "<=========values");
      const responseData = await response;

      thunkAPI.dispatch(postHealthFourSuccess(values));

      return responseData;
    } catch (error) {
     if (error.response.data.Message) {
       thunkAPI.dispatch(postHealthFourFailure(error.response.data.Message));
     } else {
       thunkAPI.dispatch(postHealthFourFailure(error.response.data.status));
     }

     if (error.response && error.response.status === 401) {
       Cookies.remove("token");

       thunkAPI.rejectWithValue({ unauthorized: true });
       await thunkAPI.dispatch(getToken());
     }
      throw error;
    }
  }
);
