import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { corporates } from "../../Data/Data";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { getToken } from "../../Redux/actions/getToken";

function ProductCorporates() {
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }
    }, [dispatch]);

    return (
        <Fragment>

            <div className="container individual productss" id="corporate">
                <div className="row">
                    <div className="col-sm-4 side">
                        <h3>
                            <Flip top delay={500}>
                                {" "}
                                Corporate{" "}
                            </Flip>
                        </h3>
                        <Fade left delay={1000}>
                            <p>
                                Salaam Takaful provides Shariah-compliant insurance solutions
                                designed to manage and mitigate business risks
                            </p>
                        </Fade>
                    </div>

                    <div className="col-sm-8 menus">
                        <Fade right delay={500}>
                            <Swiper
                                slidesPerView={3.5}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                modules={[Pagination]}
                                breakpoints={{
                                    "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                                    "@0.75": { slidesPerView: 2, spaceBetween: 30 },
                                    "@1.00": { slidesPerView: 3, spaceBetween: 30 },
                                    "@1.50": { slidesPerView: 3.5, spaceBetween: 30 },
                                }}
                                className="mySwiper"
                            >
                                {corporates.map((data) => {
                                    return (
                                        <SwiperSlide key={data.key}>
                                            <Link
                                                to={data.link}
                                                onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        left: 0,
                                                        behavior: "smooth",
                                                    });
                                                }}
                                            >
                                                <div className="menusildes">
                                                    <img src={data.feature} alt="asd" />
                                                    <h3>{data.name}</h3>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </Fade>
                    </div>
                </div>
            </div>


        </Fragment>
    );
}

export default ProductCorporates;
