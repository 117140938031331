import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postTravelOneSlice = createSlice({
  name: "postTravelOne",
  initialState,
  reducers: {
    postTravelOneStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postTravelOneSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postTravelOneFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      console.log(action.payload);
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postTravelOneStart,
  postTravelOneSuccess,
  postTravelOneFailure,
  clearError,
  clearMessage,
} = postTravelOneSlice.actions;

export default postTravelOneSlice.reducer;
