import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { advisorData } from "../../Data/Data";
import ReactCardFlip from "react-card-flip";
import { useState } from "react";

function Advisor() {
  const [isFlipped, setIsFlipped] = useState(
    Array(advisorData.length).fill(false)
  );
  const handleClick = (index) => {
    setIsFlipped((prevState) => {
      const newFlipped = [...prevState];
      newFlipped[index] = !newFlipped[index];
      return newFlipped;
    });
  };
  return (
    <Fragment>
      <div className="container governanceData shariahData govdirectors ">
        <div className="row">
          {advisorData.map((data, index) => (
            <div className="col-sm-4" style={{ margin: "0 auto" }} key={index}>
              <Fade down delay={500}>
                <ReactCardFlip
                  isFlipped={isFlipped[index]}
                  flipDirection={data.animation}
                  containerStyle={{ height: "100%" }}
                >
                  <div>
                    <button onClick={() => handleClick(index)}>
                      <img src={data.image} alt="directors" />
                      <h4 className="dname">{data.name}</h4>
                      <p className="designation">{data.designation}</p>
                    </button>
                  </div>

                  <div>
                    <button
                      onClick={() => handleClick(index)}
                      className="backbox"
                    >
                      <p data-aos="fade-right" data-aos-duration="4000">
                        {data.detail}
                      </p>
                      <p data-aos="fade-right" data-aos-duration="4000">
                        {data.detail1}
                      </p>
                      <p data-aos="fade-right" data-aos-duration="4000">
                        {data.detail2}
                      </p>
                    </button>
                  </div>
                </ReactCardFlip>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Advisor;
