import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { News } from "../../Data/Data";
import { Link } from "react-router-dom";
import { annualperformance } from "../../Data/Data";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

// function convertToSlug(text) {
//   return text.trim().toLowerCase().replace(/\s+/g, "-");
// }

function AwardsBanner() {
  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner annual">
            <Fade up delay={500}>
              <h2 className="oswald">Annual Performance Review</h2>
            </Fade>
          </div>

          {/*annual tabs */}
          <div className="container myannualtabs ">
            <div className="row">
              <div className="col-sm-12 annualtabs displayClass">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills" className="">
                        {annualperformance.map((item, index) => {
                          return (
                            <Nav.Item>
                              <Fade down delay={500}>
                                <Nav.Link eventKey={item.id}>
                                  <h4>{item.cat}</h4>
                                </Nav.Link>
                              </Fade>
                            </Nav.Item>
                          );
                        })}
                      </Nav>
                    </Col>

                    <Col sm={12}>
                      <Tab.Content>
                        {annualperformance.map((item) => {
                          return (
                            <Tab.Pane
                              eventKey={item.id}
                              className="tab-pane-active"
                            >
                              <div className="container acordianreport">
                                <div className="row">
                                  {item.desc.map((data, index) => {
                                    return (
                                      <div className="col-sm-4">
                                        <Fade up delay={500}>
                                          <Link
                                            to={`/annual-performance/${data.key}`}
                                            onClick={() => {
                                              window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                              });
                                            }}
                                          >
                                            <div className="mycontents">
                                              <div className="col-sm-12">
                                                <img
                                                  src={data.image}
                                                  alt="as"
                                                />
                                                <h3>{data.heading}</h3>
                                              </div>
                                            </div>
                                          </Link>
                                        </Fade>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Tab.Pane>
                          );
                        })}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AwardsBanner;
