import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function Livestocktab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/livestock.jpg`} />
                        <h4>Livestock Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/livestockCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>Livestock is the farmers' investment into the future and means of earning, which can be wiped away with a single widespread disease, flooding & robbery along with other everyday risks. We, at Salaam Takaful Limited, protect the farmers in case of any unfortunate and unforeseen event (Death) relating to their livestock.</p>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Coverages</h3>
                                <ul className='LiveStockList'>
                                    <li>Fire</li>
                                    <li>Flood </li>
                                    <li>Risk of Calving </li>
                                    <li>Snake Bite </li>
                                    <li>Tornado and Cyclone</li>
                                    <li>Lightning and Electrocution</li>
                                    <li>Diseases</li>
                                    <li>Theft</li>
                                    <li>Loss of udders/ Teats due to any disease</li>
                                </ul>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Extended Coverage</h3>
                                <ul className='LiveStockList'>
                                    <li>Virtual Veterinary Consultation for Livestock</li>
                                    <li>Virtual Doctor Consultation for Farmers </li>
                                    <li>Heat Sensors for Livestock for Early Illness Diagnosis </li>
                                    <li>Ultrasound Facility for Livestock </li>
                                    <li>Geotagging</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default Livestocktab