import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTravelRelation } from "../../../Redux/actions/getTravelRelation";

const TravelFormTwo = ({
  onFinishStep2,
  onFinishFailedTwo,
  beneficiaries,
  handleAddBeneficiary,
  handleRemoveBeneficiary,
  typeId,
}) => {
  const dispatch = useDispatch();
  const relationData = useSelector((state) => state.getTravelRelation.data);

  useEffect(() => {
    dispatch(getTravelRelation());
  }, []);

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  console.log(typeId, "<=====typeId");
  return (
    <div
      className="col-sm-8 mForm"
      style={{ backgroundColor: "rgb(23 105 172 / 69%)" }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Family Details</h5>
        <Form
          name="travelFormTwo"
          onFinish={onFinishStep2}
          onFinishFailed={onFinishFailedTwo}
          autoComplete="off"
          layout="vertical"
        >
          {beneficiaries.map((beneficiary, index) => (
            <div className="row">
              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`Name-${index}`}
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required!"
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`CNIC-${index}`}
                  label="CNIC Number"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                      message: "CNIC number must be in this format XXXXX-XXXXXXX-X",
                    },
                  ]}
                  normalize={(value) => {
                    if (!value) {
                      return value;
                    }

                    const onlyNums = value.replace(/[^\d]/g, "");
                    if (onlyNums.length <= 5) {
                      return onlyNums.slice(0, 5);
                    }
                    if (onlyNums.length <= 12) {
                      return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                    }
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                      5,
                      12
                    )}-${onlyNums.slice(12, 13)}`;
                  }}
                >
                  <Input placeholder="CNIC #" />
                </Form.Item>
              </div>

              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`Passport-${index}`}
                  label="Passport Number"
                  rules={[
                    {
                      required: true,
                      pattern: /^[A-Z]{2}[0-9]{7}$/, // Adjusted pattern for Pakistani Passport format
                      message: "Please enter a valid Pakistani Passport number (2 letter followed by 7 digits)",
                    },
                  ]}
                  normalize={(value) => {
                    if (!value) {
                      return value;
                    }

                    const onlyAlphaNumeric = value.replace(/[^A-Z0-9]/gi, ""); // Restrict to alphanumeric
                    if (onlyAlphaNumeric.length <= 2) {
                      return onlyAlphaNumeric.toUpperCase(); // Assuming the first character is a letter
                    }
                    if (onlyAlphaNumeric.length <= 9) {
                      return (
                        onlyAlphaNumeric.toUpperCase().slice(0, 2) +
                        onlyAlphaNumeric.slice(2, 9)
                      );
                    }
                    return (
                      onlyAlphaNumeric.toUpperCase().slice(0, 2) +
                      onlyAlphaNumeric.slice(2, 9)
                    );
                  }}
                >
                  <Input placeholder="Passport #" />
                </Form.Item>
              </div>

              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`Gender-${index}`}
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select Gender!"
                    },
                  ]}
                >
                  <Select placeholder="Gender">
                    {genderData.map((item, i) => (
                      <Select.Option value={item.value}>
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`DOB-${index}`}
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Date Of Birth is required!"
                    },
                  ]}
                >
                  <DatePicker placeholder="DOB" disabledDate={(current) => current && current > new Date()} />
                </Form.Item>
              </div>

              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name={`Relation-${index}`}
                  label="Relation"
                  rules={[
                    {
                      required: true,
                      message: "Please select relation!"
                    },
                  ]}
                >
                  <Select
                    placeholder="Relation"
                    showSearch
                    filterOption={(inputValue, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {relationData?.map((item, i) => (
                      <Select.Option key={i} value={item.ID}>
                        {item.Relation}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              {typeId !== "I" && (
                <div className="col-sm-4">
                  <Form.Item>
                    {index === beneficiaries.length - 1 && (
                      <Button onClick={handleAddBeneficiary}>
                        Add Another Beneficiary
                      </Button>
                    )}
                  </Form.Item>
                </div>
              )}

              <div className="col-sm-4"> </div>

              <div className="col-sm-4">
                <Form.Item>
                  {index !== 0 && (
                    <Button
                      onClick={() => {
                        handleRemoveBeneficiary(index);
                      }}
                    >
                      Remove Beneficiary
                    </Button>
                  )}
                </Form.Item>
              </div>
            </div>
          ))}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#0FB583" }}
            >
              Get Takaful
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default TravelFormTwo;
