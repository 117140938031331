import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getHealthRelation } from "../../../Redux/actions/getHealthRelation";

const HealthFormThree = ({
  onFinishStep3,
  onFinishFailedThree,
  beneficiaries,
  handleAddBeneficiary,
  handleRemoveBeneficiary,
  typeId,
  handlePrev,
  form,
}) => {
  const dispatch = useDispatch();
  const relationData = useSelector((state) => state.getHealthRelation.data);

  useEffect(() => {
    dispatch(getHealthRelation());
  }, []);

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  console.log(typeId, "<=====typeId");
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/blueCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Family Details</h5>
        <Form
          name="travelFormTwo"
          onFinish={onFinishStep3}
          onFinishFailed={onFinishFailedThree}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          {beneficiaries.map((beneficiary, index) => (
            <>
              <div className="row">
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name={`Name-${index}`}
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name={`CNIC-${index}`}
                    label="CNIC Number"
                    rules={[
                      {
                        required: true,
                        pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                        message:
                          "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                      },
                    ]}
                    normalize={(value) => {
                      if (!value) {
                        return value;
                      }

                      const onlyNums = value.replace(/[^\d]/g, "");
                      if (onlyNums.length <= 5) {
                        return onlyNums.slice(0, 5);
                      }
                      if (onlyNums.length <= 12) {
                        return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                          5,
                          12
                        )}`;
                      }
                      return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                        5,
                        12
                      )}-${onlyNums.slice(12, 13)}`;
                    }}
                  >
                    <Input placeholder="CNIC #" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name={`Gender-${index}`}
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select Gender!",
                      },
                    ]}
                  >
                    <Select placeholder="Gender">
                      {genderData.map((item, i) => (
                        <Select.Option value={item.value}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name={`DOB-${index}`}
                    label="DOB"
                    rules={[
                      {
                        required: true,
                        message: "Date Of Birth is required!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="DOB"
                      disabledDate={(current) =>
                        current && current > new Date()
                      }
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    nname={`Relation-${index}`}
                    label="Relation"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Relation"
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    >
                      {relationData?.map((item, i) => (
                        <Select.Option key={i} value={item.ID}>
                          {item.Relation}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Item>
                    {index === beneficiaries.length - 1 && (
                      <Button onClick={handleAddBeneficiary}>
                        Add Another Beneficiary
                      </Button>
                    )}
                  </Form.Item>
                </div>

                <div className="col-sm-4"> </div>

                <div className="col-sm-4">
                  <Form.Item>
                    {index !== 0 && (
                      <Button
                        onClick={() => {
                          handleRemoveBeneficiary(index);
                        }}
                      >
                        Remove Beneficiary
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </div>
            </>
          ))}
          <div className="row">
            <div className="col-sm-6">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#0FB583" }}
                >
                  Get Takaful
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HealthFormThree;
