import React from "react";
import { Fragment } from "react";

function Vision() {
  const Visions = `${process.env.REACT_APP_URL_ASSETS}Assets/img/vision.png`
  return (
    <Fragment>
      <div className="row whoweare mission missionVision">
        <div className="col-sm-6 noPPPad">
          <img
            src={Visions}
            alt=""
          />
        </div>

        <div className="col-sm-6 visionContent">
          <div className="vision">
            <h2 className="uniq">
              Vision
            </h2>
            <ul>
              <li data-aos="fade-right" data-aos-duration="4000">
                To spread Takaful benefits beyond borders, beyond Time.{" "}
              </li>
            </ul>
          </div>

          <div className="vision">
            <h2 className="uniq ddoenH">
              Mission
            </h2>
            <ul>
              <li data-aos="fade-right" data-aos-duration="4000">
                To deliver Takaful as a viable alternative to conventional
                insurance
              </li>
              <li data-aos="fade-right" data-aos-duration="4000">
                To become the ‘top-of-the-mind’ Takaful brand for our
                participants in terms of competitiveness, service standards and
                business ethics
              </li>
              <li data-aos="fade-right" data-aos-duration="4000">
                To give value for money to our shareholders and make Salaam
                Takaful Limited their prized asset
              </li>
              <li data-aos="fade-right" data-aos-duration="4000">
                To become an ideal organization for our employees that
                encourages them to achieve self-actualization and growth
              </li>
              <li data-aos="fade-right" data-aos-duration="4000">
                To contribute positively and proactively for the welfare of our
                society at large as well as for the preservation of our
                environment
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Vision;
