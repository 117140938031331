import React, { Fragment } from "react";
import SalaamFires from "../../Components/CorporateProductList/SalamFire";
import { Helmet } from "react-helmet";

function SalaamFire() {
  const metaData = {
    title: "Salaam Fire Takaful | Comprehensive Islamic Fire Insurance",
    description: "Protect your property from fire risks with Salaam Fire Takaful. Our Shariah-compliant insurance offers extensive coverage for fire damage, ensuring your business and assets are safeguarded",
  };
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row arrticleAndBlogs">
          <SalaamFires />
        </div>
      </div>
    </Fragment>
  );
}

export default SalaamFire;
