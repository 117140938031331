import React, { Fragment } from "react";
import SalaamHealth from "../../Components/CorporateProductList/SalamHealth";
import { Helmet } from "react-helmet";

function SalamHelth() {
  const metaData = {
    title:
      "Salaam Group Health Takaful | Islamic Employee Health Insurance Solutions",
    description:
      "Enhance employee welfare with Salaam Group Health Takaful. Our Shariah-compliant group health insurance provides comprehensive coverage for your workforce, promoting a healthy workplace",
  };
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row arrticleAndBlogs">
          <SalaamHealth />
        </div>
      </div>
    </Fragment>
  );
}

export default SalamHelth;
