import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function SalamHealthTab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamGroupHealthTakaful.jpg`} />
                        <h4>Salaam Group Health Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}Assets/img/singleproduct/SalaamGroupHealthTakafulCover.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <p>In today's competitive landscape, top talent seeks more than just a job—they seek purpose and a supportive environment. 
                                    Employees who feel valued and protected are the cornerstone of a thriving workplace culture. 
                                    By investing in Salaam Group Health Takaful, you're not only meeting their expectations but also fostering a healthier, more engaged workforce. 
                                    Offering strong medical, ancillary, and wellness benefits reduces absenteeism, enhances productivity, and reflects your organization's commitment 
                                    to doing the right thing. Keep your team resilient and focused by ensuring they have access to the healthcare they deserve.</p>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Coverages</h3>
                                <ul className='LiveStockList'>
                                    <li>
                                        <b>Comprehensive Dread Disease Expense Benefit</b><br />
                                        <p>
                                            Recovering from a chronic disease has a significant financial and emotional impact on any family. 
                                            Our Comprehensive Dread Disease Benefit help cover the costs associated with a member’s illness.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Comprehensive Hospitalization Expense Benefit</b><br />
                                        <p>
                                            Comprehensive Hospitalization cover refers to the coverage against any treatment where you need hospitalization for a minimum of one 
                                            (1) night, for specialized medical attention and care, before, during and after the treatment/surgery.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Maternity Expense Benefit</b><br />
                                        <p>
                                            Maternity coverage (optional) is one of the essential health benefits. Salaam Takaful Limited provides cover for pregnancy, labor, 
                                            delivery, and newborn baby care at an affordable contribution.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Out-patient Expense Benefit</b><br />
                                        <p>
                                            Salaam Takaful Limited provides (optional) Outpatient medical benefit that covers consultations and treatments provided by a specialist 
                                            or medical practitioner when an overnight stay in hospital is not necessary.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div className='col-sm-4'>
                                <h3>Extended Coverage</h3>
                                <ul className='LiveStockList'>
                                    <li>Daily Room & Board charges</li>
                                    <li>Operation theatre and ICU/CCU charges</li>
                                    <li>Physician, Surgeon and Anaesthetist’s fee</li>
                                    <li>Diagnostic tests during hospitalization</li>
                                    <li>Kidney Dialysis and organ Transplant</li>
                                    <li>Prescribed Medicines used during hospitalization</li>
                                    <li>Blood & Oxygen Supplies</li>
                                    <li>Specialized Investigations (MRI, C.T. Scan, Thallium Scan, Endoscopy, Angioplasty, etc.)</li>
                                    <li>Normal Delivery/C-Section/Multiple Deliveries/Complicated Delivery</li>
                                    <li>Prescribed Medicines used during hospitalization</li>
                                    <li>Pre Natal (9 months) *</li>
                                    <li>Well Baby charges</li>
                                    <li>Conservative treatments</li>
                                    <li>Day Care Surgical Procedures</li>
                                    <li>Ventilators and Allied Services</li>
                                    <li>Biopsy charges</li>
                                    <li>Surgical supplies</li>
                                    <li>Accidental Emergency ER treatment</li>
                                    <li>Ambulance charges for emergencies</li>
                                    <li>24 hours pain management (injectables)</li>
                                    <li>Pre & Post Hospitalization expenses (30 Days)</li>
                                    <li>Post Natal (30 days)</li>
                                    <li>NICU charges</li>
                                    <li>Newborn Babies Vaccinations</li>
                                    <li>Mother stays during the Baby’s Hospitalization</li>
                                    <li>Circumcision</li>
                                    </ul>

                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default SalamHealthTab