import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function WhychosePersoonalaccident() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>
      <div className='row whychosesalaam'>
        <div className='col-sm-12 chose'>
          <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
        </div>

        <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="8000">
          <h3>Salaam Application</h3>
          <p>To bring more ease and convenience to our participants, we are offering some of the world's best hi-tech solutions in one app, so that you can stay in control no matter where you go.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-down" data-aos-duration="8000">

          <h3>400+ Panel Hospital Pan Pakistan</h3>
          <p>Salaam Takaful Limited allows customers to access cashless hospitalization at over 400+ medical institutions, making healthcare facilities available for everyone.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="8000">
          <h3>Accessible at non-panel hospitals</h3>
          <p>In case of emergency admissions, our team of qualified doctors are just a call away.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="8000">
          <h3>Fastest Claim Settlement Process</h3>
          <p>With our team of qualified professional, your claim is processed in a quick and efficient manner, saving you from any hassle.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="8000">
          <h3>Shariah Complaint Solution</h3>
          <p>Our shariah compliance function is a key element of company’s structure, carrying the responsibility of ensuring that all products and services offered by Salaam Takaful are fully compliant with the principles of shariah law.</p>
        </div>
      </div>


      <Fade up delay={500}>
        <div className='row claimprocedure personalAccident'>
          <div className='col-sm-12'>
            <Fade left delay={700}><h2>Coverages</h2></Fade>
            <ul>
              <Fade up delay={800}><li><img src={Check} alt='Check' /> Permanent disability</li></Fade>
              <Fade up delay={900}> <li><img src={Check} alt='Check' /> Temporary disability</li></Fade>
              <Fade up delay={1000}> <li><img src={Check} alt='Check' /> Medical expense (Extended cover)</li></Fade>
              <Fade up delay={1100}><li><img src={Check} alt='Check' />  Death</li></Fade>
              <Fade up delay={1200}><li><img src={Check} alt='Check' /> Burial & repatriation </li></Fade>
            </ul>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default WhychosePersoonalaccident

