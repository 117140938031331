import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postCorporateFinancialStart,
  postCorporateFinancialSuccess,
  postCorporateFinancialDataSuccess,
  postCorporateFinancialFailure,
} from "../reducers/postCorporateFinancialReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postCorporateFinancial = createAsyncThunk(
  "postCorporateFinancial",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postCorporateFinancialStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Corporate_And_Financial/Save_Request`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(
        postCorporateFinancialSuccess(responseData?.data?.result)
      );
      thunkAPI.dispatch(postCorporateFinancialDataSuccess(values));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        postCorporateFinancialFailure(error.response.data.Message)
      );

      if (error.response.data.Message) {
        thunkAPI.dispatch(
          postCorporateFinancialFailure(error.response.data.Message)
        );
      } else {
        thunkAPI.dispatch(
          postCorporateFinancialFailure(error.response.data.status)
        );
      }
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
