import React from "react";
import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay } from "swiper";
import { LifeAtSalaamGrowthData } from "../../Data/Data";
import Fade from "react-reveal/Fade";
SwiperCore.use([Autoplay]);

const LifeAtSalaamGrowth = () => {
  return (
    <div className="container growth lifeAtSalaam">
      <div className="row lifeCards">
        <h2>Growth partner wellbeing initiatives</h2>
        {LifeAtSalaamGrowthData?.map((item, key) => (
          <div className="col-sm-4">
            <Fade>
              <Card
                hoverable
                cover={
                  <Swiper
                    autoplay={{ 
                      delay: 1000,
                      disableOnInteraction: false 
                    }}                    
                    loop={true}
                    pagination={false}
                    spaceBetween={10}
                    slidesPerView={1}
                    speed={item?.speed}
                  >
                    {item?.images?.map((data) => (
                      <SwiperSlide>
                        <img
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                          }}
                          alt="example1"
                          src={data}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                }
              >
                <h3 className="InHeadd">{item.heading}</h3>
                {item?.data?.map((elem) => (
                  <Meta title={elem?.heading} description={elem?.description} />
                ))}
              </Card>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LifeAtSalaamGrowth;
