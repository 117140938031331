import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorCity } from "../../../Redux/actions/getMotorCity";
import { getMotorCC } from "../../../Redux/actions/getMotorCC";
import { getMotorColor } from "../../../Redux/actions/getMotorColor";

const ComercialForm = ({
  onFinishFailedCommercial,
  onFinishStepCommercial,
}) => {
  const { data } = useSelector((state) => state.getMotorCity);
  const ccData = useSelector((state) => state.getMotorCC.data);
  const colorData = useSelector((state) => state.getMotorColor.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMotorCity());
    dispatch(getMotorCC());
    dispatch(getMotorColor());
  }, [dispatch]);

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/blueCar.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
        <h5 className="cnhbsh5">Commercial Vehicle</h5>
        <Form
          name="motorFormThree"
          onFinish={onFinishStepCommercial}
          onFinishFailed={onFinishFailedCommercial}
          autoComplete="off"
        >
          <Form.Item
            name="CompanyName"
            rules={[
              {
                required: true,
                message: "Please enter a Company Name",
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>

          <div className="row">
            <div className="col-sm-6">
              <Form.Item
                name="CName"
                className="widthHAlf"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item
                className="widthHAlf"
                name="CEmail"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="CContactNo"
            rules={[
              {
                required: true,
                pattern: /^03\d{2}-\d{7}$/,
                message:
                  "Please enter a valid contact number in the format 03XX-XXXXXXX using only numbers",
              },
            ]}
            normalize={(value) => {
              if (!value) {
                return value;
              }
              const onlyNums = value.replace(/[^\d]/g, "");
              if (onlyNums.length <= 4) {
                return onlyNums;
              } else if (onlyNums.length <= 11) {
                return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
              }
              return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
            }}
          >
            <Input placeholder="Contact 03XX-XXXXXXX" />
          </Form.Item>

          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                className="widthHAlf"
                name="CRemarts"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#0FB583" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ComercialForm;
