import React, { Fragment } from 'react'
import Reportsbanner from '../../Components/FinancialReports/Reportsbanner'
import { Helmet } from 'react-helmet'

function FinancialReports() {
  const metaData = {
    title: 'Financial Reports | Comprehensive Financial Reports',
    description: 'Access detailed financial reports from Salaam Takaful. Our reports provide in-depth insights into our financial performance, including annual reports, balance sheets, and income statements'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight '>
        <div className='row'>
          <Reportsbanner />
        </div>
      </div>
    </Fragment>
  )
}

export default FinancialReports