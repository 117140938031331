import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postTravelFourSlice = createSlice({
  name: "postTravelFour",
  initialState,
  reducers: {
    postTravelFourStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postTravelFourSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },
    postTravelFourFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postTravelFourStart,
  postTravelFourSuccess,
  postTravelFourFailure,
  clearError,
  clearMessage,
} = postTravelFourSlice.actions;

export default postTravelFourSlice.reducer;
