import React, { Fragment } from 'react'
import SalamPersonals from '../../Components/IndividualProductList/SalamPersonal'
import { Helmet } from 'react-helmet'

function SalamPersonal() {
    const metaData = {
        title: 'Salaam Personal Accident Takaful | Islamic Coverage for Accidents',
        description: 'Get financial protection with Salaam Personal Accident Takaful. Our Shariah-compliant plan offers coverage for accidental injuries and death, ensuring you and your family are secure'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight '>
                <div className='row arrticleAndBlogs'>
                    <SalamPersonals />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamPersonal