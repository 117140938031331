import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postTravelFourStart,
  postTravelFourSuccess,
  postTravelFourFailure,
} from "../reducers/postTravelFourReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postTravelFour = createAsyncThunk(
  "postTravelFour",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTravelFourStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Travel/SaveTravelForthPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postTravelFourSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTravelFourFailure(error.response.data.Message));
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
