import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getTravelProductTypeSlice = createSlice({
  name: "getTravelProductType",
  initialState,
  reducers: {
    getTravelProductTypeStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getTravelProductTypeSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getTravelProductTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getTravelProductTypeStart,
  getTravelProductTypeSuccess,
  getTravelProductTypeFailure,
  clearError,
  clearMessage,
} = getTravelProductTypeSlice.actions;

export default getTravelProductTypeSlice.reducer;
