import React from 'react'
import { Fragment } from 'react'
import SingleNews from '../Components/News/SingleNews'
import { Helmet } from 'react-helmet'

function SingleNewsPage() {
  const metaData = {
    title: 'News And Announcements | Latest Updates and News',
    description: 'Stay updated with the latest news and announcements from Salaam Takaful. Our page provides the most recent updates, company news, and important announcements to keep you informed'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight'>
        <div className='row'>
          <SingleNews />
        </div>

      </div>
    </Fragment>
  )
}

export default SingleNewsPage