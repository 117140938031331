import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHomeOneStart,
  postHomeOneSuccess,
  postHomeOneDataSuccess,
  postHomeOneFailure,
} from "../reducers/postHomeOneReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postHomeOne = createAsyncThunk(
  "postHomeOne",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHomeOneStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Home/SaveHomeFirstPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postHomeOneSuccess(responseData?.data?.result));
      thunkAPI.dispatch(postHomeOneDataSuccess(values));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postHomeOneFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postHomeOneFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postHomeOneFailure(error.response.data.status));
      }
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
