import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTravelPlanStart,
  getTravelPlanSuccess,
  getTravelPlanFailure,
} from "../reducers/getTravelPlanReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getTravelPlan = createAsyncThunk(
  "getTravelPlan",
  async ({ productId }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getTravelPlanStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Travel/getTravelPlan?Product_ID=${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getTravelPlanSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getTravelPlanFailure(error.response.data.Message));
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
