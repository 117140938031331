import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
};

const postComplainFormSlice = createSlice({
  name: "postComplainForm",
  initialState,
  reducers: {
    postComplainFormStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postComplainFormSuccess(state, action) {
      state.loading = false;
      state.postMessage = action.payload.postMessage;
      toast.success("Form Submitted!");
    },

    postComplainFormFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
      toast.error(action?.payload);
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postComplainFormStart,
  postComplainFormSuccess,
  postComplainFormFailure,
  clearError,
  clearMessage,
} = postComplainFormSlice.actions;

export default postComplainFormSlice.reducer;
