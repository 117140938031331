import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Marinetable from "./Marinetable";
import CorProdNav from "./CorProdNav";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const Salammarine = () => {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner MarineTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Marine Takaful</h2>
              <p
                data-aos="fade-right"
                data-aos-duration="4000"
                className="cemter"
              >
                Cargo interests are exposed to numerous financial risks in the
                course of their business of imports, exports as well and
                movement of goods within the country. The transportation of
                cargo is invariably carried in conjunction with coverage as no
                one can afford to take the risk of leaving their cargo
                uncovered. Our Salaam Marine Takaful cover protects your goods
                against the various risks involved during transit, for all types
                of cargo transported by the various modes of conveyance such as
                sea, air, land and parcel post from warehouse to warehouse.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Marine Cargo"} />
          </div>
          <div className="col-sm-12 heads text">
            <Marinetable />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Salammarine;
