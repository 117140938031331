import React from "react";
import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay } from "swiper";
import { LifeAtSalaamAmenitiesData } from "../../Data/Data";
import Fade from "react-reveal/Fade";

SwiperCore.use([Autoplay]);

const LifeAtSalaamAmenities = () => {
  return (
    <div className="container lifeAtSalaam">
      <div className="row lifeCards">
        <h2>Amenities</h2>
        {LifeAtSalaamAmenitiesData?.map((item, key) => (
          <div className="col-sm-4">
            <Fade>
              <Card
                hoverable
                cover={
                  <Swiper
                    autoplay={{ 
                      delay: 1000,
                      disableOnInteraction: false 
                    }}
                    loop={true}
                    pagination={false}
                    spaceBetween={10}
                    slidesPerView={1}
                    speed={item?.speed}
                  >
                    {item?.images?.map((data) => (
                      <SwiperSlide>
                        <img
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                          }}
                          alt="example1"
                          src={data}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                }
              >
                <Meta title={item?.heading} description={item?.description} />
              </Card>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LifeAtSalaamAmenities;
