import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLiveInStart,
  getLiveInSuccess,
  getLiveInFailure,
} from "../reducers/getLiveInReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getLiveIn = createAsyncThunk("getLiveIn", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getLiveInStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/Home/GetLiveIn`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response;

    thunkAPI.dispatch(getLiveInSuccess(responseData?.data?.result));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(getLiveInFailure(error.response.data.Message));

    throw error;
  }
});
