import React, { Fragment } from 'react'
import SalamProfessionals from '../../Components/FinancialProductList/SalamProfessional'
import { Helmet } from 'react-helmet'

function SalamProfessional() {
    const metaData = {
        title: 'Salaam Professional Indemnity Takaful | Coverage for Professionals',
        description: 'Safeguard your professional practice with Salaam Professional Indemnity Takaful. Our Shariah-compliant insurance provides coverage against claims of negligence or errors in your services'
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight'>
                <div className='row arrticleAndBlogs'>
                    <SalamProfessionals />
                </div>
            </div>
        </Fragment>
    )
}

export default SalamProfessional