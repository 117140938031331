import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postMotorDiscountSlice = createSlice({
  name: "postMotorDiscount",
  initialState,
  reducers: {
    postMotorDiscountStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postMotorDiscountSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = "Discount Applied Successfully";
    },
    postMotorDiscountFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postMotorDiscountStart,
  postMotorDiscountSuccess,
  postMotorDiscountFailure,
  clearError,
  clearMessage,
} = postMotorDiscountSlice.actions;

export default postMotorDiscountSlice.reducer;
