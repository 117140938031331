import React, { Fragment } from "react";
import PoliciesBanner from "../../Components/Policiespdf/PoliciesBanner";
import { Helmet } from "react-helmet";

function Policies() {
  const metaData = {
    title: 'Policies | Takaful Insurance Policies',
    description: 'Explore our range of Islamic insurance policies at Salaam Takaful. Find detailed information on coverage options, terms, and conditions to choose the right policy for your needs'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <PoliciesBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Policies;