import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHomeFormThreeStart,
  postHomeFormThreeSuccess,
  postHomeFormThreeFailure,
} from "../reducers/postHomeFormThreeReducer";

export const postHomeFormThree = createAsyncThunk(
  "postHomeFormThree",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHomeFormThreeStart());
      console.log(values, "<=======values");
      thunkAPI.dispatch(postHomeFormThreeSuccess(values));
    } catch (error) {
      thunkAPI.dispatch(postHomeFormThreeFailure(error));

      throw error;
    }
  }
);
