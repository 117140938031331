import React, { Fragment } from "react";
import SalaamMiscellaneouss from "../../Components/CorporateProductList/SalaamMiscellaneous";
import { Helmet } from "react-helmet";

function SalaamMiscellaneous() {
  const metaData = {
    title: "Salaam Miscellaneous Takaful | Specialized Islamic Insurance Solutions",
    description: "Discover Salaam Miscellaneous Takaful for tailored insurance solutions. Our Shariah-compliant coverage caters to unique and specialized needs, providing comprehensive protection",
  };
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <SalaamMiscellaneouss />
        </div>
      </div>
    </Fragment>
  );
}

export default SalaamMiscellaneous;