import React, { Fragment } from 'react'
import Sidemenu from '../Components/Sidebar/Sidemenu'

function SideBar() {
  return (
    <Fragment>
      <div className='container faderight menuside'>
        <div className='row'>
          <Sidemenu />
        </div>
      </div>
    </Fragment>
  )
}

export default SideBar