import React, { Fragment } from 'react'
import HighlightsBanner from '../../Components/FinancialHighlights/HighlightsBanner'
import { Helmet } from 'react-helmet'

function FinancialHighlight() {
  const metaData = {
    title: 'Financial Highlights | Key Financial Highlights ',
    description: 'Review the key financial highlights of Salaam Takaful. Our summary provides an overview of significant financial metrics, growth achievements, and performance indicators'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight '>
        <div className='row'>
          <HighlightsBanner />
        </div>
      </div>

    </Fragment>

  )
}

export default FinancialHighlight