import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Whotext from "./Whotext";
import Vision from "./Vision";
import Mission from "./Mission";
import ReTakaful from "./ReTakaful";

function Whobanner() {
  return (
    <Fragment>
      <div className="container tabss motor whoweare">
        <div className="row arrticleAndBlogs">
          <div className='col-sm-12 heads govbanner weare'>
            <Fade up delay={500}><h2 className="oswald">Who We Are</h2></Fade>
          </div>
          <div className='col-sm-12 heads adds firstpart'>
            <h2 data-aos="fade-right" data-aos-duration="4000"> </h2>
            <Whotext />
          </div>
          <div className='col-sm-12 heads adds '>
            <Vision />
          </div>
          <div className='col-sm-12 heads text retaakaful'>
            <ReTakaful />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Whobanner;
