import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

function Surplustext() {
    return (
        <Fragment>
            <div className='sub surplus'>
                <div className='row ArticlesAndBlogs'>
                    <Fade left delay={500}>
                        <div className='col-sm-9 writeus'>
                            <h2>Giving The Surplus Back to The Policyholders</h2>
                            <p>Takaful and conventional insurance at first glance may look similar, in that they both share the objective of protection against financial loss, but a closer look will reveal stark differences between the two. Surplus sharing among the participants is something that creates quite an evident distinguishment between conventional insurance and takaful.</p>
                            <p>This is the utmost unique benefit of Takaful which not only protects policyholders against unforeseen circumstances, but also qualifies them for a share in the achieved surplus based on the performance of Participants’ Takaful Fund. With the increasing demand of risk-managing and interest free solutions, many takaful operators across the globe have started offering the feature of surplus distribution. However, backed by its unprecedented growth and emergence as the leader in its respective field, Salaam Takaful Limited has created history by being the first and still the only general takaful operator in Pakistan to not only initiate this process of surplus sharing with its policyholders, but also announcing distribution for the second year consecutively. This initiative by the organization has gone down in history as the first instance of its nature, quite fervently appreciated by policyholders.</p>
                            <p>Surplus distribution doesn’t only involve the participants by enabling them to receive something back in monetary terms, but also upholds the belief in Shariah-compliant solutions and resonates the fact that Takaful indeed is a better option when it comes to loss protection solutions.</p>
                        </div>
                    </Fade>

                    <Fade left delay={500}>
                        <div className='col-sm-3 writeus'>
                            <Link to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/SurplusDistribution.pdf`} target='_blank'>
                                <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/thumbnail/SurplusThumbnail.jpg`} alt='pdf' />
                                <p className='ssurrpluussss'>List of Surplus Distribution</p>
                            </Link>
                        </div>
                    </Fade>


                </div>
            </div>
        </Fragment>
    )
}

export default Surplustext