import React from 'react'
import { Fragment } from 'react'
import AwardsBanner from '../Components/Awards/AwardsBanner'
import { Helmet } from 'react-helmet'

function Awards() {
  const metaData = {
    title: 'Annual Performance Review | Yearly Performance Analysis',
    description: 'Read our Annual Performance Review to understand Salaam Takaful’s yearly achievements and performance. This detailed report includes key metrics, strategic developments, and growth analysis'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className='container-fluid faderight '>
        <div className='row'>
            <AwardsBanner/>
        </div>
      </div>

    </Fragment> 
  )
}

export default Awards