import { combineReducers } from "redux";
import getToken from "./reducers/getTokenReducer";
import getMotorProduct from "./reducers/getMotorProductReducer";
import postMotorOne from "./reducers/postMotorOneReducer";
import getMotorMake from "./reducers/getMotorMakeReducer";
import getMotorVehicle from "./reducers/getMotorVehicleReducer";
import getMotorYear from "./reducers/getMotorYearReducer";
import postFormTwo from "./reducers/postFormTwoReducer";
import getMotorCity from "./reducers/getMotorCityReducer";
import getMotorCC from "./reducers/getMotorCCReducer";
import getMotorColor from "./reducers/getMotorColorReducer";
import postFormThree from "./reducers/postFormThreeReducer";
import getTravelType from "./reducers/getTravelTypeReducer";
import getTravelProductType from "./reducers/getTravelProductTypeReducer";
import getTravelProduct from "./reducers/getTravelProductReducer";
import postTravelOne from "./reducers/postTravelOneReducer";
import postTravelTwo from "./reducers/postTravelTwoReducer";
import postTravelThree from "./reducers/postTravelThreeReducer";
import getTravelRelation from "./reducers/getTravelRelationReducer";
import getTravelOccupation from "./reducers/getTravelOccupationReducer";
import getTravelCountry from "./reducers/getTravelCountryReducer";
import getTravelReason from "./reducers/getTravelReasonReducer";
import getTravelTripType from "./reducers/getTravelTripTypeReducer";
import getTravelPlan from "./reducers/getTravelPlanReducer";
import postTravelFour from "./reducers/postTravelFourReducer";
import postTravelSix from "./reducers/postTravelSixReducer";
import postTravelFive from "./reducers/postTravelFiveReducer";
import getTravelPlanDetail from "./reducers/gettravelPlanDetailReducer";
import getTravelTotalContribution from "./reducers/getTravelTotalContributionReducer";
import postTravelAddon from "./reducers/postTravelAddonReducer";
import getTravelBillingInfo from "./reducers/getTravelBillingInfoReducer";
import getMotorTimeSlot from "./reducers/getMotorTimeSlotReducer";
import postMotorFour from "./reducers/postMotorFourReducer";
import getMotorBillingInfo from "./reducers/getMotorBillingInfoReducer";
import postMotorDiscount from "./reducers/postMotorDiscountReducer";
import getHealthProduct from "./reducers/getHealthProductReducer";
import postHealthOne from "./reducers/postHealthOneReducer";
import postHealthTwo from "./reducers/postHealthTwoReducer";
import getHealthOccupation from "./reducers/getHealthOccupationReducer";
import getHealthRelation from "./reducers/getHealthRelationReducer";
import postHealthFamilyDetail from "./reducers/postHealthFamilyDetailReducer";
import getHealthMedical from "./reducers/getHealthMedicalReducer";
import postHealthThree from "./reducers/postHealthThreeReducer";
import getHealthPlan from "./reducers/getHealthPlanReducer";
import postHealthFour from "./reducers/postHealthFourReducer";
import postMotorCommercial from "./reducers/postMotorCommercialReducer";
import getCalculateTracker from "./reducers/getCalculateTrackerReducer";
import postNewsLetter from "./reducers/postNewsLetterReducer";
import postComplainForm from "./reducers/postComplainFormReducer";
import postContactForm from "./reducers/postContactFormReducer";
import getBlowingReport from "./reducers/getBlowingReportReducer";
import postWhistleBlowingForm from "./reducers/postWhistleBlowingFormReducer";
import getHealthBillingInfo from "./reducers/getHealthBillingInfoReducer";
import postHealthFive from "./reducers/postHealthFiveReducer";
import postHomeOne from "./reducers/postHomeOneReducer";
import postHomeFormTwo from "./reducers/postHomeFormTwoReducer";
import getLiveIn from "./reducers/getLiveInReducer";
import getHomeUnit from "./reducers/getHomeUnitReducer";
import getHomeOwner from "./reducers/getHomeOwnerReducer";
import getHomeCity from "./reducers/getHomeCityReducer";
import getHomePlan from "./reducers/getHomePlanReducer";
import postHomeFormThree from "./reducers/postHomeFormThreeReducer";
import getHomeOccupation from "./reducers/getHomeOccupationReducer";
import postHomeFormFour from "./reducers/postHomeFormFourReducer";
import postHomeFormFive from "./reducers/postHomeFormFiveReducer";
import getPAPlan from "./reducers/getPAPlanReducer";
import postCorporateFinancial from "./reducers/postCorporateFinancialReducer";
import getCorporateFinancialProducts from "./reducers/getCorporateFinancialProductsReducer";

const rootReducer = combineReducers({
  getToken: getToken,
  getMotorProduct: getMotorProduct,
  getMotorMake: getMotorMake,
  getMotorVehicle: getMotorVehicle,
  getMotorYear: getMotorYear,
  getMotorCity: getMotorCity,
  getMotorCC: getMotorCC,
  getCalculateTracker: getCalculateTracker,
  getMotorColor: getMotorColor,
  getMotorTimeSlot: getMotorTimeSlot,
  getMotorBillingInfo: getMotorBillingInfo,
  postMotorDiscount: postMotorDiscount,
  postMotorOne: postMotorOne,
  postMotorCommercial: postMotorCommercial,
  postFormTwo: postFormTwo,
  postFormThree: postFormThree,
  postMotorFour: postMotorFour,
  getTravelType: getTravelType,
  getTravelProductType: getTravelProductType,
  getTravelProduct: getTravelProduct,
  getTravelRelation: getTravelRelation,
  getTravelOccupation: getTravelOccupation,
  getTravelCountry: getTravelCountry,
  getTravelReason: getTravelReason,
  getTravelPlan: getTravelPlan,
  getTravelTripType: getTravelTripType,
  getTravelPlanDetail: getTravelPlanDetail,
  getTravelTotalContribution: getTravelTotalContribution,
  getTravelBillingInfo: getTravelBillingInfo,
  getHealthBillingInfo: getHealthBillingInfo,
  postTravelOne: postTravelOne,
  postTravelTwo: postTravelTwo,
  postTravelThree: postTravelThree,
  postTravelFour: postTravelFour,
  postTravelAddon: postTravelAddon,
  postTravelFive: postTravelFive,
  postTravelSix: postTravelSix,
  postHealthFive: postHealthFive,
  getHealthProduct: getHealthProduct,
  getHealthOccupation: getHealthOccupation,
  getHealthMedical: getHealthMedical,
  getHealthRelation: getHealthRelation,
  getHealthPlan: getHealthPlan,
  postHealthOne: postHealthOne,
  postHealthTwo: postHealthTwo,
  postHealthThree: postHealthThree,
  postHealthFour: postHealthFour,
  postHealthFamilyDetail: postHealthFamilyDetail,
  postNewsLetter: postNewsLetter,
  postComplainForm: postComplainForm,
  //post corporate and financial
  postCorporateFinancial: postCorporateFinancial,

  //GET corporate and financial APIS
  getCorporateFinancialProducts: getCorporateFinancialProducts,
  //GET pa APIS
  getPAPlan: getPAPlan,

  //get home product apis
  getLiveIn: getLiveIn,
  getHomeUnit: getHomeUnit,
  getHomeOccupation: getHomeOccupation,
  getHomeOwner: getHomeOwner,
  getHomeCity: getHomeCity,
  getHomePlan: getHomePlan,
  //----------------------------------//
  //post home product forms
  postHomeOne: postHomeOne,
  postHomeFormTwo: postHomeFormTwo,
  postHomeFormThree: postHomeFormThree,
  postHomeFormFour: postHomeFormFour,
  postHomeFormFive: postHomeFormFive,
  //---------------------------------//
  postContactForm: postContactForm,
  getBlowingReport: getBlowingReport,

  postWhistleBlowingForm: postWhistleBlowingForm,
});

export default rootReducer;
