import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTokenStart,
  getTokenSuccess,
  getTokenFailure,
} from "../reducers/getTokenReducer";
import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";

export const getToken = createAsyncThunk("getToken", async (_, thunkAPI) => {
  try {
    let data2 = qs.stringify({
      clientId: "SalaamTakaful",
      clientPassword: "S@laam786",
      clientShortCode: "Salaam Takaful",
      grant_type: "password",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}auth/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer u-Sjizaag4n5cYX2IcWEC9Udf7PcVPAIaRWecBCqED9Qra0q7a6WS7Bih5p53P6v_fenlsd8x4FJehsRJAEBfXDU4IrSaWeZLtiPhPDnRaccpKMxdPF9apf5qAsT18zrulSEduHdEe-12dt5SwNh0niMuZ-gC4ITk4Oe4ZjqVkgxF91nrqItqPGF-mUKLn6xGWQQZzS5hMi32kpZ5slwrZlskt8dZhZ7lr2PAWjqTS_KERmnmu2FSgUKrC5vNVIorL5xvBJo3xiFXvDqg4OY6qvU-uoKV6rAs3XmWkaefr10n56ypnAfsTHWrOKzK1k0mnZ9C00CZmVVT26X9b603cqvAip7hET5nBl32bgELRY",
      },
      data: data2,
    };

    const response = await axios.request(config); // Await the response here

    thunkAPI.dispatch(getTokenStart()); // Dispatch the start action

    // Dispatch the success action with the actual data returned by the request
    thunkAPI.dispatch(getTokenSuccess(response.data));
    Cookies.set("token", response.data.token);

    return true;
  } catch (error) {
    thunkAPI.dispatch(getTokenFailure(error)); // Dispatch the failure action
    return false;
  }
});
