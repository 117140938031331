import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import ComplaintForm from './ComplaintForm';

function Bannerreso() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>
                    <div className='col-sm-12 heads govbanner resolution'>
                        <Fade up delay={500}><h2 className="oswald">Complaint Resolution</h2></Fade>
                    </div>
                    <div className='col-sm-12 heads text mymaxheight'>
                        <ComplaintForm/>
                    </div>                
                </div>
            </div>
        </Fragment>
    )
}

export default Bannerreso