import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

function CTA() {
    const Write = `${process.env.REACT_APP_URL_ASSETS}Assets/img/write.png`
    const People = `${process.env.REACT_APP_URL_ASSETS}Assets/img/peepsimg.png`

    return (
        <Fragment>
            <div className='sub cta'>
                <div className='row noMarginn'>
                    <Fade left delay={500}>
                        <div className='col-sm-4 writeus'>
                            <div className='row noMarginn'>
                                <div className='col-sm-3 write'>
                                    <img src={Write} alt='write' />
                                    <p>Write us</p>
                                </div>
                                <div className='col-sm-9 address'>
                                    <p>6th floor, Business Centre,Plot No 19-1-A, Block -6 P.E.C.H.S., Shahrah-e-Faisal Karachi, Pakistan</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade up delay={500}>
                        <div className='col-sm-4 cardcta'>
                            <h3>Designated Person for Participants Grievances:</h3>
                            {/* <h2>Mr. Faheem Darss</h2> */}
                            <h2>Mr. Farhan Sheikh</h2>
                            <p>
                                <Link to="mailto:farhan.sheikh@salaamtakaful.com">farhan.sheikh@salaamtakaful.com</Link>
                                <Link to="tel:111-875-111">Landline Number: 111-875-111 (Ext. 282)</Link>
                                {/* <Link to="tel:021-343-73195">Fax Number: 021-343-73195</Link>
                                <Link to="tel:0301-8236633">Cell Number: 0301-8236633</Link> */}
                            </p>
                        </div>
                    </Fade>

                    <Fade right delay={500}>
                        <div className='col-sm-4 ctaimgae'>
                            <img src={People} alt='peepsimage' />
                        </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default CTA