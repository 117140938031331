import React, { Fragment } from "react";
import Mbanner from "../../Components/Mcollateral/Mbanner";
import { Helmet } from "react-helmet";

function Mcollateral() {
  const metaData = {
    title: 'Marketing Collateral | Takaful Marketing Materials ',
    description: 'Access marketing collateral for Salaam Takaful. Download brochures, flyers, and other promotional materials to learn more about our offerings and services'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Mbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Mcollateral;
