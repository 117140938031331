import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTravelPlanDetailStart,
  getTravelPlanDetailSuccess,
  getTravelPlanDetailFailure,
} from "../reducers/gettravelPlanDetailReducer.js";
import axios from "axios";
import Cookies from "js-cookie";

export const getTravelPlanDetail = createAsyncThunk(
  "getTravelPlanDetail",
  async ({ productId, planId }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getTravelPlanDetailStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Travel/GettravelPlanDetail?Product_ID=${productId}&Plan_ID=${planId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getTravelPlanDetailSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getTravelPlanDetailFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
