import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postTravelOneStart,
  postTravelOneSuccess,
  postTravelOneFailure,
} from "../reducers/postTravelOneReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postTravelOne = createAsyncThunk(
  "postTravelOne",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTravelOneStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Travel/SavetravelFirstPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postTravelOneSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTravelOneFailure(error.response.data.status));
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
