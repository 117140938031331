import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: undefined,
};

const getTokenSlice = createSlice({
  name: "getToken",
  initialState,
  reducers: {
    getTokenStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getTokenSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },
    getTokenFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getTokenStart,
  getTokenSuccess,
  getTokenFailure,
  clearError,
  clearMessage,
} = getTokenSlice.actions;

export default getTokenSlice.reducer;
