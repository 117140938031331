import React, { Fragment } from "react";
import Surplusbanner from "../Components/Surplus/Surplusbanner";
import { Helmet } from "react-helmet";

function Surplus() {
  const metaData = {
    title: 'Surplus Distribution | Salaam Takaful Policyholder Benefits',
    description: 'Understand how Salaam Takaful distributes surplus to policyholders in compliance with Shariah principles. Learn about our profit-sharing model.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <Surplusbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Surplus;
