import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Surplustext from './Surplustext';

function Surplusbanner() {
    return (
        <Fragment>
            <div className='col-sm-12 heads govbanner surplus'>
                <Fade up delay={500}><h2 className="oswald">Surplus Distribution</h2></Fade>
            </div>
            <div className='container tabss motor surplusDistributionnn' >
                <div className='row'>
                    <div className='col-sm-12 heads text mymaxheight'>
                        <Surplustext />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Surplusbanner