import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getMotorProductSlice = createSlice({
  name: "getMotorProduct",
  initialState,
  reducers: {
    getMotorProductStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getMotorProductSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getMotorProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getMotorProductStart,
  getMotorProductSuccess,
  getMotorProductFailure,
  clearError,
  clearMessage,
} = getMotorProductSlice.actions;

export default getMotorProductSlice.reducer;
