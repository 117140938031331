import React from 'react'
import { Fragment } from 'react'
import "swiper/css";


function WhychoseTravel() {
    return (
        <Fragment>
            <div className='row whychosesalaam'>
                <div className='col-sm-12 chose'>
                    <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
                </div>

                <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                    <h3>Salaam Application</h3>
                    <p>To bring more ease and convenience to our participants, we are offering some of the world's best hi-tech solutions in one app, so that you can stay in control no matter where you go.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-down" data-aos-duration="4000">

                    <h3>Direct Claim Settlement Abroad</h3>
                    <p>Salaam Travel Takaful cover allows your claim to be settled directly when you are abroad so that you can travel with a peace of mind.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>Medical Emergencies</h3>
                    <p>In case of emergency admissions, our team of qualified doctors are just a call away.</p>
                </div>

                <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="4000">
                    <h3>24/7 Facilitation anywhere in the world.</h3>
                    <p>With your Salaam Travel Takaful cover, get 24/7 medical assistance anywhere in the world through our assistance company.</p>
                </div>

            </div>


        </Fragment>
    )
}

export default WhychoseTravel


