import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import ReportsPdf from './ReportsPdf';

function Reportsbanner() {
    return (
        <Fragment>
            <div className='container governancemain motor' >
                <div className='row arrticleAndBlogs'>
                    <div className='col-sm-12 heads govbanner report'>
                        <Fade up delay={500}><h2 className="oswald">Financial Reports</h2></Fade>
                    </div>
                    <div className='col-sm-12 heads text mymaxheight'>
                       <ReportsPdf/>
                    </div>                
                </div>
            </div>
        </Fragment>
    )
}

export default Reportsbanner