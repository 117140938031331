import React from 'react'
import { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'

function Esgtext() {
    return (
        <Fragment>
            <div className='sub shariahcom esg'>

                <h2 data-aos="fade-right" data-aos-duration="4000">Sustainability</h2>
                <p data-aos="fade-right" data-aos-duration="4000">Salaam Takaful Limited, being the largest dedicated general takaful operator and first Islamic insurtech in Pakistan, is committed to serve not only its policyholders but also the world we operate in. That’s the reason we have been one of the most vocal supporters of ESG and its true practitioners in Pakistan. Our main goal under our ESG Policy is “Financial Inclusion”, which is mentioned in 7 of the UN SDGs. All our efforts and products are aligned to these 7 goals.
                </p>
                <div className="row sssusteaninbiity">
                    <Fade up delay={500}>
                        <div className='col-sm-4 pdfs' data-aos="fade-up" data-aos-duration="4000">
                            <Link to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/SalaamTakafulSustainabilityReport2023.pdf`} target="_blank">
                                <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/thumbnail/CoverSustainabilityReport2023.jpg`} alt='CoverSustainabilityReport2023' />
                                <p>Salaam Takaful Sustainability Report 2023</p>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default Esgtext