import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

function FinancialProductBanner() {
  return (
    <Fragment>
      <div className="container ">
        <div className="row">
          <div className="col-sm-12 heads">
            <Fade left delay={500}>
              <h2 className="oswald"> Financial Products</h2>
            </Fade>
            <Flip top delay={1000}>
              {/* <h3>Product Sub Heading</h3> */}
            </Flip>
          </div>

          <div className="col-sm-12 heads text">
            <p
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-easing="ease-out-cubic"
            >
              Salaam Takaful Limited, the largest general takaful operator and
              the first Islamic insurtech in Pakistan,
            </p>
            <p
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-easing="ease-out-cubic"
            >
              is dedicated to providing high-quality and technology-driven
              general takaful products. With its establishment in June 2006 and
              headquarters based in Karachi, Salaam Takaful has quickly expanded
              its presence to major urban centers across the country.
            </p>
            <p
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-easing="ease-out-cubic"
            >
              Operating in accordance with the principles of Shariah law, Salaam
              Takaful offers a comprehensive range of general takaful products
              including Property, Marine, Motor, Health, Agri, Livestock,
              Travel, Medical Malpractice, and Loss of Employment.
            </p>

            <p
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-easing="ease-out-cubic"
            >
              The company is managed by experienced professionals and overseen
              by an independent Shariah board, ensuring that policyholders
              receive reliable and trustworthy coverage. With a strong focus on
              customer satisfaction,
            </p>

            <p
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-easing="ease-out-cubic"
            >
              Salaam Takaful's dedicated team processes claims efficiently and
              strives to deliver excellent services from day one. As a reputable
              takaful company, Salaam Takaful Limited has become a preferred
              choice for individuals and businesses seeking comprehensive and
              ethical insurance solutions in Pakistan.{" "}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FinancialProductBanner;
