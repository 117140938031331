import React, { Fragment } from "react";
import LifeBanner from "../Components/LifeAtSalaam/LifeAtSalaamBanner";
import { Helmet } from "react-helmet";

function LifeAtSalaam() {
  const metaData = {
    title: 'Careers at Salaam Takaful | Join Our Team',
    description: 'Explore career opportunities at Salaam Takaful. Learn about our work culture, values, and how you can grow with us.'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <LifeBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default LifeAtSalaam;
