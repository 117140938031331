import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
};

const postContactFormSlice = createSlice({
  name: "postContactForm",
  initialState,
  reducers: {
    postContactFormStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postContactFormSuccess(state, action) {
      state.loading = false;
      state.postMessage = action.payload.postMessage;
      toast.success("Form Submitted!");
    },

    postContactFormFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      toast.error(action?.payload);

      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postContactFormStart,
  postContactFormSuccess,
  postContactFormFailure,
  clearError,
  clearMessage,
} = postContactFormSlice.actions;

export default postContactFormSlice.reducer;
