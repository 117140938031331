import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Careertabs from './Careertabs';
import Careerjobs from './Careerjobs';
import LifeAtSalaamText from './LifeAtSalaamText';




function CareerBanner() {
    return (
      <Fragment>
        <div className="container governancemain motor">
          <div className="row">
            <div className="col-sm-12 heads govbanner">
              <Fade up delay={500}>
                <h2 className="oswald">Careers</h2>
              </Fade>
            </div>

            <div className="col-sm-12 heads text mymaxheight interntabs ">
              <Fade up delay={500}>
                <h2>Current Vacancies</h2>
              </Fade>
              <Careerjobs />
            </div>

            <div className="col-sm-12 heads govbanner1">
            <Fade up delay={500}>
                <h2 className="oswald">Life @ Salaam</h2>
              </Fade>
            </div>
            <div className="col-sm-12 careerText">
<LifeAtSalaamText/>


            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default CareerBanner