import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
document.addEventListener("DOMContentLoaded", function () {
  var corrections = {
    Hello: "Salaam",
    "السلام علیکم": "سلام",
    Healthy: "Sehat",
    Solutions: "Solutions",
    "سلام موٹر تکافل": "سلام موٹر تکافل",
    "Salaam Engine Corner": "Salaam Motor Takaful"
  };

  function correctTranslations(el) {
    for (var key in corrections) {
      if (el.innerText.includes(key)) {
        el.innerText = el.innerText.replace(key, corrections[key]);
      }
    }
  }

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.type === "childList") {
        mutation.addedNodes.forEach(function (node) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            correctTranslations(node);
          }
        });
      }
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });

  // Initial correction for already loaded content
  correctTranslations(document.body);
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
