import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postTravelTwoStart,
  postTravelTwoSuccess,
  postTravelTwoFailure,
} from "../reducers/postTravelTwoReducer";

export const postTravelTwo = createAsyncThunk(
  "postTravelTwo",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTravelTwoStart());

      const responseData = await values;

      thunkAPI.dispatch(postTravelTwoSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTravelTwoFailure(error));

      throw error;
    }
  }
);
