import React from "react";
import { Fragment } from "react";
import FAQBanner from "../Components/FAQs/FAQBanner";
import FAQDescription from "../Components/FAQs/FAQDescription";
import { Helmet } from "react-helmet";

function FAQ() {
  const metaData = {
    title: 'Help & FAQs | Frequently Asked Questions',
    description: 'Find answers to frequently asked questions and get help with Salaam Takaful services. Our Help & FAQs section provides guidance on common inquiries and issues'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row arrticleAndBlogs">
          <FAQBanner />
          <FAQDescription />
        </div>
      </div>
    </Fragment>
  );
}

export default FAQ;
