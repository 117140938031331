import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postMotorOneStart,
  postMotorOneSuccess,
  postMotorOneFailure,
} from "../reducers/postMotorOneReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postMotorOne = createAsyncThunk(
  "postMotorOne",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postMotorOneStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Motor/SaveMotorPolicyFirstPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postMotorOneSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=========responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postMotorOneFailure(error.response.data));

      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }

      throw error;
    }
  }
);
