import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { getMotorTimeSlot } from "../../../Redux/actions/getMotorTimeSlot";
import { useDispatch, useSelector } from "react-redux";
import { getMotorBillingInfo } from "../../../Redux/actions/getMotorBillingInfo";
import { postMotorDiscount } from "../../../Redux/actions/postMotorDiscount";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postMotorDiscountReducer";

const FormFour = ({ onFinishStep4, onFinishFailed4, formOneData }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [discountAmount, setDiscountAmount] = useState("");

  const { data } = useSelector((state) => state.getMotorBillingInfo);
  const { postError, postMessage } = useSelector(
    (state) => state.postMotorDiscount
  );
  console.log(data, "<=====data");
  const timeSlotData = useSelector((state) => state.getMotorTimeSlot.data);

  const [form] = Form.useForm();
  const handleInputChange = (event) => {
    setDiscountAmount(event.target.value);
  };
  useEffect(() => {
    dispatch(getMotorTimeSlot());
  }, [dispatch]);

  useEffect(() => {
    const Unique_ID = formOneData && formOneData[0];

    dispatch(getMotorBillingInfo({ Unique_ID }));
  }, []);
  useEffect(() => {
    if (postError) {
      toast.error(postError);
      dispatch(clearMessage());
    }
    if (postMessage) {
      toast.success(postMessage);
      const Unique_ID = formOneData && formOneData[0];

      dispatch(getMotorBillingInfo({ Unique_ID }));
      dispatch(clearMessage());
    }
  }, [postError, postMessage]);

  useEffect(() => {
    form.setFieldsValue({
      Gross_Contribution: data && data[0]?.Gross_Contribution,
      Taxes: data && data[0]?.Taxes,
      Stamp_Duty: data && data[0]?.Stamp_Duty,
      Discount_Amount: data && data[0]?.Discount_Amount,
      Net_Contribution: data && data[0]?.Net_Contribution,
      Total_Payable: data && data[0]?.Total_Payable,
    });
  }, [form, data]);
  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      if (discountAmount.trim() !== "") {
        try {
          const values = {
            Dicount_Code: discountAmount,
            UNIQUE_ID: formOneData && formOneData[0],
          };
          dispatch(postMotorDiscount({ values }));
        } catch (error) {
          toast.error(error);
        }
      }
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(fetchData, 1000); // Adjust the debounce delay as needed

    return () => clearTimeout(timeoutId);
  }, [discountAmount]);

  return (
    <div
      className="col-sm-8 mForm"
      // style={{background: '#0FB583'}}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
        <h5 className="cnhbsh5">Contribution & Surveyor</h5>
        <Form
          name="motorFormOne"
          onFinish={onFinishStep4}
          onFinishFailed={onFinishFailed4}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row travelBillingForm">
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Gross Contribution" name="Gross_Contribution">
                <Input placeholder="Gross Contribution" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Taxes" name="Taxes">
                <Input placeholder="Taxes" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Stamp Duty" name="Stamp_Duty">
                <Input placeholder="Stamp Duty" readOnly />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Discount Amount" name="Discount_Amount">
                <Input
                  onChange={handleInputChange}
                  placeholder="Discount Amount"
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Net Contribution" name="Net_Contribution">
                <Input placeholder="Net Contribution" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Total Amount" name="Total_Payable">
                <Input placeholder="Total Amount" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Day"
                label="Preferred day for survey"
                className="Day"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="Preferred day for survey" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                label="Preferred Time Slots for Survey"
                name="PrefferedTime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Preferred Time Slots for Survey">
                  {timeSlotData?.map((item, i) => (
                    <Option value={item.TimeSlot}>{item.TimeSlot}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FormFour;
