import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postHealthFiveSlice = createSlice({
  name: "postHealthFive",
  initialState,
  reducers: {
    postHealthFiveStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postHealthFiveSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
      console.log(action.payload, "<=====action.payload");
    },
    postHealthFiveFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postHealthFiveStart,
  postHealthFiveSuccess,
  postHealthFiveFailure,
  clearError,
  clearMessage,
} = postHealthFiveSlice.actions;

export default postHealthFiveSlice.reducer;
