import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Radio, Select } from "antd";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { getHealthMedical } from "../../../Redux/actions/getHealthMedical";

const HealthFormFour = ({
  onFinishStep4,
  onFinishFailedFour,
  formOnePostedData,
  selectedHMIDs,
  setSelectedHMIDs,
  form,
  handlePrev,
}) => {
  const { Option } = Select;

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getHealthMedical);
  const handleRadioChange = (e, HM_ID) => {
    const value = e.target.value;
    if (value === "Y") {
      setSelectedHMIDs([...selectedHMIDs, { HM_ID }]);
    } else {
      setSelectedHMIDs(selectedHMIDs.filter((id) => id !== HM_ID));
    }
  };
  console.log(selectedHMIDs, "<======selectedHMIDs");

  useEffect(() => {
    dispatch(getHealthMedical());
  }, []);

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(15 181 131 / 69%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Health Takaful</h3>
        <h5 className="cnhbsh5">Medical Questions</h5>
        <Form
          name="sehatFormFour"
          onFinish={onFinishStep4}
          onFinishFailed={onFinishFailedFour}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row healthSubFOrm">
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>{formOnePostedData?.Name}</Accordion.Header>
                <Accordion.Body>
                  {data?.map((item, i) => (
                    <div className="col-sm-12 helathaQuestgjvn labelSSS">
                      <Form.Item name="medCondition" label={item.English_Desc}>
                        <Input placeholder="Please Select Yes / NO" readOnly />
                        <div className="yesNoBtn">
                          <Radio.Group
                            name="Tracker_Required"
                            buttonStyle="solid"
                            onChange={(e) => handleRadioChange(e, item.HM_ID)}
                            defaultValue="N"
                          >
                            <Radio.Button value="Y">Yes</Radio.Button>
                            <Radio.Button value="N">No</Radio.Button>
                          </Radio.Group>
                        </div>
                      </Form.Item>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="col-sm-12 healthDesc">
              <p>
                I hereby certify that all answers to questions appearing on this
                form are true and complete to the best of my knowledge and
                belief. I am also aware that subject to the term of acceptance
                of my coverage, this declaration & authorization together with
                the Participant Membership Document (PMD) shall form the
                contract between participant and Takaful Service Provider.
              </p>
              <p>
                If any of this above-mentioned information declared by me is
                found incorrect, Salaam Takaful Limited has the right to reject
                my claim.
              </p>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HealthFormFour;
