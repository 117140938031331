import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

function Corporatebox() {
    return (
        <div className='container boxes companyDetails'>
            <div className='row greenbox ArticlesAndBlogs'>

                <Fade down delay={500}>
                    <div className='col-sm-3'>
                        <h4>Company Incorporation No.</h4>
                        <p>12775</p>
                    </div>
                </Fade>

                <Fade down delay={700}>
                    <div className='col-sm-3'>
                        <h4>Company Incorporate Date</h4>
                        <p>02 June, 2006</p>
                    </div>
                </Fade>


                <Fade down delay={900}>
                    <div className='col-sm-3'>
                        <h4>National Tax Number (NTN)</h4>
                        <p>2668683-0</p>
                    </div>
                </Fade>


                <Fade down delay={1100}>
                    <div className='col-sm-3'>
                        <h4>Certificate of Incorporation</h4>
                        <Link 
                            to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/CertificateofIncorporation.pdf`} 
                            // to='#'
                            target='_blank'
                        >Certificate.pdf <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/Download.png`} alt='asd' /></Link>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Corporatebox