import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHealthFamilyDetailStart,
  postHealthFamilyDetailSuccess,
  postHealthFamilyDetailFailure,
} from "../reducers/postHealthFamilyDetailReducer";

export const postHealthFamilyDetail = createAsyncThunk(
  "postHealthFamilyDetail",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHealthFamilyDetailStart());

      console.log(values, "<=========values");

      thunkAPI.dispatch(postHealthFamilyDetailSuccess(values));

      return values;
    } catch (error) {
      thunkAPI.dispatch(postHealthFamilyDetailFailure(error));

      throw error;
    }
  }
);
