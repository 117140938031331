import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getHealthMedicalStart,
  getHealthMedicalSuccess,
  getHealthMedicalFailure,
} from "../reducers/getHealthMedicalReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getHealthMedical = createAsyncThunk(
  "getHealthMedical",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getHealthMedicalStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Health/GetHealthMedical`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getHealthMedicalSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getHealthMedicalFailure(error.response.data.Message));

      throw error;
    }
  }
);
