import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postMotorDiscountStart,
  postMotorDiscountSuccess,
  postMotorDiscountFailure,
} from "../reducers/postMotorDiscountReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postMotorDiscount = createAsyncThunk(
  "postMotorDiscount",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postMotorDiscountStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Motor/ApplyMotorDiscountCode`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postMotorDiscountSuccess(responseData?.data?.result));
      console.log(
        responseData?.data?.result,
        "<=======responseData?.data?.result"
      );
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postMotorDiscountFailure(error.response.data.status));
      console.log(
        error.response.data.status,
        "<========error.response.data.status"
      );

      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }

      throw error;
    }
  }
);
