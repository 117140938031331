import React, { Fragment } from "react";
import Bannerreso from "../../Components/CompalintResolution/Bannerreso";
import { Helmet } from "react-helmet";

function CompalintResolution() {
  const metaData = {
    title: 'Complaint Resolution | Resolve Your Complaints',
    description: 'Find information on how to resolve complaints with Salaam Takaful. Our dedicated process ensures your concerns are addressed promptly and effectively'
  }
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <Bannerreso />
        </div>
      </div>
    </Fragment>
  );
}

export default CompalintResolution;