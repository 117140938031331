import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTravelOccupationStart,
  getTravelOccupationSuccess,
  getTravelOccupationFailure,
} from "../reducers/getTravelOccupationReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getTravelOccupation = createAsyncThunk(
  "getTravelOccupation",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getTravelOccupationStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Travel/GettravelOccupation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getTravelOccupationSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getTravelOccupationFailure(error.response.data.Message)
      );
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");

        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
