import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postMotorCommercialSlice = createSlice({
  name: "postMotorCommercial",
  initialState,
  reducers: {
    postMotorCommercialStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postMotorCommercialSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
      console.log(action.payload, "<=====action.payload");
    },
    postMotorCommercialFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postMotorCommercialStart,
  postMotorCommercialSuccess,
  postMotorCommercialFailure,
  clearError,
  clearMessage,
} = postMotorCommercialSlice.actions;

export default postMotorCommercialSlice.reducer;
