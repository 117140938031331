import React, { useEffect } from "react";

import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorProduct } from "../../../Redux/actions/getMotorProduct";

const MotorFormOne = ({
  onFinishFailed,
  onFinishStep1,
  product,
  setProduct,
  setProductId,
  form,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getMotorProduct);

  const handleProductChange = (value, option) => {
    // Find the selected product in the data array
    const selectedProduct = data.find((item) => item.Product_ID === value);

    // Update the product name in the state
    if (selectedProduct) {
      setProductId(selectedProduct.Product_ID);
      setProduct(selectedProduct.Product_Name);
    } else {
      setProduct("");
    }
  };

  useEffect(() => {
    dispatch(getMotorProduct());
  }, [dispatch]);

  return (
    <div
      className="col-sm-8 mForm"
      // style={{background: '#0FB583'}}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
        <h5 className="cnhbsh5">Personal Information</h5>
        <Form
          name="motorFormOne"
          onFinish={onFinishStep1}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!"
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Email"
                label='Email'
                rules={[
                  {
                    type: "email",
                    message: "Enter valid email address!",
                  },
                  {
                    required: true,
                    message: "Email is required!"
                  }
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Contact"
                label='Contact'
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message:
                      "Enter a valid contact number 03XX-XXXXXXX format",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Product_ID"
                label='Product'
                rules={[
                  {
                    required: true,
                    message: 'Product is required!'
                  },
                ]}
              >
                <Select
                  placeholder="Products"
                  onChange={handleProductChange}
                  value={product}
                >
                  {data.map((item, i) => (
                    <Option value={item.Product_ID}>{item.Product_Name}</Option>
                  ))}
                </Select>
              </Form.Item>

            </div>
          </div>




          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MotorFormOne;
